import React from "react";
import "./TopPanel.css";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {ROUTE} from "../../constants/routes";
import "../DropDownMenu/DropDownMenu.css";
import {logout, setMenuId, setSubmenuId} from "../../store/actions/actions";
import LanguageSelect from "../LanguageSelect/LanguageSelect";
import {LanguageConsumer} from "../LanguageProvider/LanguageProvider";

export const TopPanel = () => {
  const user = useSelector(store => store.base.user);
  const userStat = useSelector(store => store.base.userStat);
  const history = useHistory();
  const dispatch = useDispatch();

  return (
    <LanguageConsumer>
      {({dict}) => (
        <div className="top-panel">
          <div className="top-panel-item">
            <div>
              <div>{dict.TOP_PANEL_RANK || "Rank"}: {user?.userRank?.rank?.name}</div>
              {user && <div className="pay-rank">{dict.TOP_PANEL_PAY_RANK || "Pay rank"}: {user.userPayRank ? user.userPayRank.rank?.name : "IMP"}</div>}
            </div>
          </div>
          <div className="top-panel-item">
            <div>
              <div
                className="publishing-rights-top">{dict.TOP_PANEL_PR || "Publishing rights"}: {user?.userPack?.pack?.name}</div>
              {user && user.userPack?.pack?.id !== 1 &&
                <div
                  className="publishing-rights-bottom">{dict.TOP_PANEL_ACTIVE_UNTIL || "Active until"}: {user?.activeUntil}</div>}
            </div>
          </div>
          <div className="top-panel-item">
            <div>{dict.TOP_PANEL_BONUS_RUN || "Next bonusrun"}:</div>
            <div>{userStat && (userStat.nextBonusRun + " UTC")}</div>
          </div>
          <div className="top-panel-item">
            <div>{dict.TOP_PANEL_LEFT_CV || "Left CV"}:</div>
            <div>{userStat?.leftCV}</div>
          </div>
          <div className="top-panel-item">
            <div>{dict.TOP_PANEL_RIGHT_CV || "Right CV"}:</div>
            <div>{userStat?.rightCV}</div>
          </div>
          <div className="top-panel-item without-border">
            <div className="top-panel-user-info">
              <LanguageSelect/>
              <div className="icon-bell"/>
              <div className="icon-account" onClick={() => {
                dispatch(setMenuId(0));
                dispatch(setSubmenuId(1));
                localStorage.submenuId = 1;
                localStorage.menuId = 0;
                localStorage.route = ROUTE.DASHBOARD_ACCOUNT;
                history.push(ROUTE.DASHBOARD_ACCOUNT);
              }}/>
              <div className="menu-dropdown">
                <div className="top-panel-user-info-username">
                  {user?.username}
                </div>
                <div className="menu-dropdown-content">
                  <div className="menu-dropdown-item" onClick={() => {
                    dispatch(setMenuId(0));
                    dispatch(setSubmenuId(1));
                    localStorage.submenuId = 1;
                    localStorage.menuId = 0;
                    localStorage.route = ROUTE.DASHBOARD_ACCOUNT;
                    history.push(ROUTE.DASHBOARD_ACCOUNT);
                  }}>{dict.TOP_PANEL_MENU_MY_ACCOUNT || "My Account"}
                  </div>
                  <div className="menu-dropdown-item" onClick={() => {
                    dispatch(setSubmenuId(3));
                    dispatch(setMenuId(3));
                    localStorage.submenuId = 3;
                    localStorage.menuId = 3;
                    localStorage.route = ROUTE.DASHBOARD_WALLETS;
                    history.push(ROUTE.DASHBOARD_WALLETS);
                  }}>{dict.TOP_PANEL_MENU_PAYOUT || "Payout"}
                  </div>
                  <div className="menu-dropdown-item" onClick={() => {
                    dispatch(setSubmenuId(1));
                    dispatch(setMenuId(0));
                    localStorage.submenuId = 1;
                    localStorage.menuId = 0;
                    localStorage.route = ROUTE.DASHBOARD_ORDERS;
                    history.push(ROUTE.DASHBOARD_ORDERS);
                  }}>{dict.TOP_PANEL_MENU_ORDERS || "Orders"}
                  </div>
                  <div className="menu-dropdown-item" onClick={() => {
                    dispatch(setMenuId(0));
                    dispatch(setSubmenuId(1));
                    localStorage.submenuId = 1;
                    localStorage.menuId = 0;
                    localStorage.route = ROUTE.DASHBOARD_STATEMENTS;
                    history.push(ROUTE.DASHBOARD_STATEMENTS);
                  }}>{dict.TOP_PANEL_MENU_STATEMENTS || "Statements"}
                  </div>
                  <div className="menu-dropdown-item"
                       onClick={() => window.open("https://made4share.com")}>{dict.TOP_PANEL_MENU_EBOOK || "My E-book"}
                  </div>
                  <div className="menu-dropdown-item"
                       onClick={() => window.open("https://reseven.com")}>{dict.TOP_PANEL_MENU_EXCHANGE || "Exchange"}
                  </div>
                  <div className="menu-dropdown-item"
                       onClick={() => dispatch(logout())}>{dict.TOP_PANEL_MENU_LOGOUT || "Logout"}</div>
                </div>
              </div>

            </div>
            <div/>
          </div>
        </div>
      )}
    </LanguageConsumer>
  )
}