import React, {useCallback, useEffect, useState} from "react";
import {Table, TableBody, TableCell, TableHead, TablePagination, TableRow} from "@mui/material";
import {client} from "../../../../services/client";
import {notification} from "../../../../components/Notification";
import {isMobile} from "../../../../utils/checkDevice";
import {toEur} from "../../../../utils/tools";
import "../../Dashboard.css";
import {LanguageConsumer} from "../../../../components/LanguageProvider/LanguageProvider";

export const OrdersTable = () => {
  const [data, setData] = useState();
  const [currentPage, setCurrentPage] = useState(0)
  const [total, setTotal] = useState(1);

  const onChangePage = (_, newPage) => {
    setCurrentPage(newPage)
  }

  const getData = useCallback(async () => {
    try {
      const response = await client.getAllOrders({
        page: currentPage,
        size: 10,
      });
      setData(response.content);
      setTotal(response.totalElements)
    } catch (e) {
      notification.warning(e.message);
    }
  }, [currentPage]);

  useEffect(() => {
    getData();
  }, [currentPage]);

  const renderType = (e) => {
    if (e.type === "DASHBOARD_SIGNUP") {
      return "signup";
    } else if (e.type === "GEN_CV_EXCHANGE") {
      return "CV";
    } else {
      return e.type.toLowerCase();
    }
  }

  const renderStatus = (e) => {
    if (e.status === "GEN_CV_EXCHANGE") {
      return "paid";
    } else if (e.status === "FREE") {
      return "success";
    } else {
      return e.status.toLowerCase();
    }
  }

  const renderActions = (e) => {
    return <div className="table-actions">
      {e.invoice !== undefined && <div onClick={() => window.open(e.invoice, "_blank")}>invoice</div>}
    </div>
  }

  return (
    <LanguageConsumer>
      {({dict}) => (
        <>
          <Table className="table" size="small">
            <TableHead>
              <TableRow>
                <TableCell key={1}>{dict.TABLE_ORDERS_ID || "ID"}</TableCell>
                <TableCell key={2}>{dict.TABLE_ORDERS_PAID_BY || "Paid by"}</TableCell>
                <TableCell key={3}>{dict.TABLE_ORDERS_PAID_FOR || "Paid for"}</TableCell>
                <TableCell key={4}>{dict.TABLE_ORDERS_AMOUNT || "Amount"}</TableCell>
                <TableCell key={5}>{dict.TABLE_ORDERS_CRYPTO_AMOUNT || "Crypto"}</TableCell>
                <TableCell key={6}>{dict.TABLE_ORDERS_PAYMENT_METHOD || "Method"}</TableCell>
                <TableCell key={7}>{dict.TABLE_ORDERS_EPIN || "Coupon"}</TableCell>
                <TableCell key={8}>{dict.TABLE_ORDERS_PACK || "Pack"}</TableCell>
                <TableCell key={9}>{dict.TABLE_ORDERS_TYPE || "Type"}</TableCell>
                <TableCell key={10}>{dict.TABLE_ORDERS_STATUS || "Status"}</TableCell>
                <TableCell key={11}>{dict.TABLE_ORDERS_DATE || "Date"}</TableCell>
                <TableCell key={12}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data && data.map((e, index) => (
                <TableRow hover key={index}>
                  <TableCell>{e.id}</TableCell>
                  <TableCell>{e.paidBy}</TableCell>
                  <TableCell>{e.paidFor}</TableCell>
                  <TableCell>{toEur(e.amount)}</TableCell>
                  <TableCell>{e.cryptoAmount}</TableCell>
                  <TableCell>{e.status === "FREE" ? "" : e.paymentMethod}</TableCell>
                  <TableCell>{e.epinCode}</TableCell>
                  <TableCell>{e.pack ? e.pack.name : ""}</TableCell>
                  <TableCell>{renderType(e)}</TableCell>
                  <TableCell>{renderStatus(e)}</TableCell>
                  <TableCell>{e.updatedAt}</TableCell>
                  <TableCell>{renderActions(e)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={total}
            rowsPerPage={10}
            rowsPerPageOptions={[]}
            page={currentPage}
            backIconButtonProps={{
              'aria-label': 'previous page',
            }}
            nextIconButtonProps={{
              'aria-label': 'next page',
            }}
            onPageChange={onChangePage}
            className="table-pagination"
          />
        </>
      )}
    </LanguageConsumer>
  );
}