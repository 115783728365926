import React, {useEffect, useState} from "react";
import "./Resources.css";
import {client} from "../../../../../services/client";
import {notification} from "../../../../../components/Notification";
import {isMobile} from "../../../../../utils/checkDevice";

export const Resources = () => {
  const [data, setData] = useState();

  const TYPES = [
    {id: 1, value: "PRESENTATION", title: "Presentation"},
    {id: 2, value: "VIDEO", title: "Video"},
    {id: 3, value: "DOCUMENTS", title: "Documents"},
    {id: 4, value: "PRINT", title: "Print materials"},
  ];

  const sortData = (e) => {
    let d = {
      PRESENTATION: [],
      VIDEO: [],
      DOCUMENTS: [],
      PRINT: []
    };
    e.map((el) => {
      TYPES.map((t) => {
        if (t.value === el.type) {
          switch (t.value) {
            case "PRESENTATION":
              d.PRESENTATION.push(el);
              break;
            case "VIDEO":
              d.VIDEO.push(el);
              break;
            case "DOCUMENTS":
              d.DOCUMENTS.push(el);
              break;
            case "PRINT":
              d.PRINT.push(el);
              break;
          }
        }
      })
    });
    setData(d);
  }

  useEffect(async () => {
    await client.getResources()
      .then(sortData)
      .catch((error) => notification.warning(error.message))
  }, []);


  const renderBlock = (e, title) => {
    return <div className="resource-block">
      <div>{title}</div>
      {e.map((el, i) => {
          return <div className="resource-item" key={i} onClick={() => window.open(el.link, "_blank")}>
            <div>{el.title}</div>
            <div className={isMobile() ? "download-icon-white" : "download-icon"}/>
          </div>
        }
      )}
    </div>
  }

  return (
    <div className="resources-container dashboard-block">
      Resources

      <div className="resources-wrapper">
        {data && data.PRESENTATION.length > 0 && renderBlock(data.PRESENTATION, "Presentations")}
        {data && data.VIDEO.length > 0 && renderBlock(data.VIDEO, "Video links")}
        {data && data.DOCUMENTS.length > 0 && renderBlock(data.DOCUMENTS, "Documents")}
        {data && data.PRINT.length > 0 && renderBlock(data.PRINT, "Print material")}
      </div>
    </div>
  )
}