import React, {useEffect, useState} from "react";
import {BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip,} from 'chart.js';
import {Bar} from 'react-chartjs-2';
import {client} from "../../../../../services/client";
import {notification} from "../../../../../components/Notification";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const OrganisationChart = ({dict}) => {
  const [stat, setStat] = useState();

  useEffect(async () => {
    await client.getStatMonthly()
      .then((r) => {
        let _labels = [];
        let _cv = [];
        r.forEach((el) => {
          console.log(el.date)
          _labels.push(el.date);
          _cv.push(el.orgCvLeft + el.orgCvRight);
        });
        setStat({
          labels: _labels,
          cv: _cv,
        })
      })
      .catch((error) => notification.warning(error.message))
  }, []);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: dict.DASHBOARD_MAIN_ORG_CHART_LABEL  || 'Last 12-months',
      },
    },
  };

  const chartData = {
    labels: stat && stat.labels,
    datasets: [
      {
        label: 'CV',
        data: stat && stat.cv,
        backgroundColor: '#5048E5',
      },
    ],
  };

  return (
    <Bar options={options} data={chartData} />
  )
}