import React, {useCallback, useEffect} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {client} from "../../../services/client";
import {setUser} from "../../../store/actions/actions";

export const GuestLayout = ({children}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector(store => store.base.user);

  const fetchCurrentUser = useCallback(async () => {
    const isAuth = !!localStorage.accessToken && !!localStorage.refreshToken;
    if (user === null && isAuth) {
      const user = await client.getCurrentUser();
      dispatch(setUser(user));
    }
  }, [user, history]);

  useEffect(() => {
    fetchCurrentUser();
  }, []);

  return (
    <>
      <div className="container">
        {children}
      </div>
    </>
  )
}