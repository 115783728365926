import { jwt } from './jwt';
import { api } from './api';

import { API_URLS } from './apiUrls';

const BASIC_CODE = process.env.REACT_APP_BASIC_CODE;

export class Client {

  //Users
  auth2fa = ({ email, password }) =>
    api.post(
      API_URLS.OAUTH.TOKEN,
      `grant_type=password&username=${encodeURIComponent(email)}&password=${encodeURIComponent(password)}`,
      {
        headers: {
          Authorization: `Basic ${BASIC_CODE}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    );

  refreshToken = () =>
    api.post(
      API_URLS.OAUTH.TOKEN,
      `grant_type=refresh_token&refresh_token=${jwt.refreshToken}`,
      {
        headers: {
          Authorization: `Basic ${BASIC_CODE}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    );

  //Users
  getCurrentUser = () => api.get(API_URLS.USERS.CURRENT);
  signup = (data) => api.post(API_URLS.USERS.SIGNUP, data);
  requestResetPassword = (data) => api.post(API_URLS.USERS.REQUEST_RESET_PASSWORD, data);
  confirmResetPassword = (data) => api.post(API_URLS.USERS.CONFIRM_RESET_PASSWORD, data);
  requestChangeEmail = () => api.post(API_URLS.USERS.REQUEST_CHANGE_EMAIL);
  confirmChangeEmail = (data) => api.post(API_URLS.USERS.CONFIRM_CHANGE_EMAIL, data);
  requestChangeUsername = () => api.post(API_URLS.USERS.REQUEST_CHANGE_USERNAME);
  confirmChangeUsername = (data) => api.post(API_URLS.USERS.CONFIRM_CHANGE_USERNAME, data);
  updatePassword = (data) => api.put(API_URLS.USERS.UPDATE_PASSWORD, data);
  updateUser = (id, data) => api.put(API_URLS.USERS.UPDATE(id), data);
  getSponsorSubtree = (id) => api.get(API_URLS.USERS.SPONSOR_SUBTREE(id));
  getBinarySubtree = (id) => api.get(API_URLS.USERS.BINARY_SUBTREE(id));
  searchInBinarySubtree = (username) => api.get(API_URLS.USERS.SEARCH_IN_BINARY_SUBTREE(username));
  upInBinarySubtree = (id) => api.get(API_URLS.USERS.UP_IN_BINARY_SUBTREE(id));
  validateRef = (ref) => api.get(API_URLS.USERS.VALIDATE_REF(ref));
  validateEmail = (email) => api.get(API_URLS.USERS.VALIDATE_EMAIL(email));
  validateUsername = (username) => api.get(API_URLS.USERS.VALIDATE_USERNAME(username));
  getPersonallyInvitedUsers = (params) => api.get(API_URLS.USERS.PERSONALLY_INVITED, {params});
  getLatestTeamImps = (params) => api.get(API_URLS.USERS.LATEST_TEAM_IMPS, {params});
  getPartners = (params) => api.get(API_URLS.USERS.PARTNERS, {params});
  changeRefType = (data) => api.put(API_URLS.USERS.CHANGE_REF_TYPE, data);
  changeCountry = (data) => api.put(API_URLS.USERS.CHANGE_COUNTRY, data);
  resetWaitingStatus = () => api.post(API_URLS.USERS.RESET_WAITING_STATUS);

  //Users stat
  getCurrentUserStat = () => api.get(API_URLS.USERS_STAT.CURRENT);
  getCurrentUserNetworkStat = () => api.get(API_URLS.USERS_STAT.NETWORK);
  getMapStat = () => api.get(API_URLS.USERS_STAT.MAP_STAT);
  getStatMonthly = () => api.get(API_URLS.USERS_STAT.MONTHLY);

  //SSE
  getStream = () => api.setStream(API_URLS.SSE.STREAM);

  //Packs
  getPacks = () => api.get(API_URLS.PACKS.GET_ALL);
  getTokenPacks = () => api.get(API_URLS.PACKS.GET_TOKEN_PACKS);
  getUpgradePacks = () => api.get(API_URLS.PACKS.GET_UPGRADE);
  getPaidPacks = () => api.get(API_URLS.PACKS.GET_PAID);

  //Orders
  createOrder = (data) => api.post(API_URLS.ORDERS.CREATE, data);
  getOrder = (id) => api.get(API_URLS.ORDERS.GET(id));
  submitOrder = (externalId) => api.put(API_URLS.ORDERS.SUBMIT(externalId));
  getLastOrder = () => api.get(API_URLS.ORDERS.GET_LAST);
  getAllOrders = (params) => api.get(API_URLS.ORDERS.GET_ALL, {params})

  //Countries
  getAllCountries = (params) => api.get(API_URLS.COUNTRIES.GET_ALL, {params})

  //Wallets
  getWallet = () => api.get(API_URLS.WALLETS.GET);
  requestChangeWallet = () => api.post(API_URLS.WALLETS.REQUEST_CHANGE_WALLET);
  confirmChangeWallet = (data) => api.post(API_URLS.WALLETS.CONFIRM_CHANGE_WALLET, data);

  //Epins
  createEpin = (data) => api.post(API_URLS.EPIN.CREATE, data);
  sendEpin = (data) => api.post(API_URLS.EPIN.SEND, data);
  confirmEpin = (data) => api.post(API_URLS.EPIN.CONFIRM, data);
  getAllEpins = (params) => api.get(API_URLS.EPIN.GET_ALL, {params});
  getEpinStat = () => api.get(API_URLS.EPIN.GET_STAT);
  deleteEpin = (id) => api.delete(API_URLS.EPIN.DELETE(id));

  //Payouts
  createPayout = (data) => api.post(API_URLS.PAYOUT.CREATE, data);
  confirmPayout = (data) => api.post(API_URLS.PAYOUT.CONFIRM, data);
  cancelPayout = (id) => api.put(API_URLS.PAYOUT.CANCEL(id));
  getAllPayouts = (params) => api.get(API_URLS.PAYOUT.GET_ALL, {params});

  //Transactions
  getTransactionsByBalanceType = (params) => api.get(API_URLS.TRANSACTIONS.GET_ALL_BY_BALANCE_TYPE, {params})

  //Statements
  getLastBonusRun = () => api.get(API_URLS.STATEMENTS.GET_LAST_BONUS_RUN);
  getAllBonusRun = (params) => api.get(API_URLS.STATEMENTS.GET_ALL_BONUS_RUN, {params});
  getLastCvStat = () => api.get(API_URLS.STATEMENTS.GET_LAST_CV_STAT);

  //Translations
  getLocale = (localeKey) => api.get(API_URLS.TRANSLATIONS.GET_LOCALE(localeKey));

  //News
  getAllNews = (params) => api.get(API_URLS.NEWS.GET_ALL, {params});

  //Events
  getAllEvents = () => api.get(API_URLS.EVENTS.GET_ALL);

  //Education
  getEducationTimeNext = () => api.get(API_URLS.EDUCATION.GET_NEXT);
  activateEducationTime = (id) => api.put(API_URLS.EDUCATION.ACTIVATE(id));

  //Resources
  getResources = () => api.get(API_URLS.RESOURCES.GET);

  //Loyalty
  getAllLoyaltyReward = (params) => api.get(API_URLS.LOYALTY.GET_ALL, {params});

  //Cards
  getCards = () => api.get(API_URLS.CARDS.GET_ALL);

  //Settings
  getSettings = () => api.get(API_URLS.SETTINGS.GET_ALL);
}

export const client = new Client();
