import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {isMobile} from "../../../utils/checkDevice";
import {WalletBlocks} from "../../../components/Blocks/WalletBlocks";
import {StatMobileBlocks} from "../../../components/Blocks/StatMobileBlocks";
import "../Dashboard.css";
import {ROUTE} from "../../../constants/routes";
import {useHistory} from "react-router-dom";
import {notification} from "../../../components/Notification";
import {client} from "../../../services/client";
import {ModalChangeEmail} from "../../../components/Modals/ModalChangeEmail/ModalChangeEmail";
import {setModalStates} from "../../../store/actions/actions";
import {ModalChangeUsername} from "../../../components/Modals/ModalChangeUsername/ModalChangeUsername";
import {Autocomplete, Box, TextField} from "@mui/material";
import countries from "../../../constants/country";
import {makeStyles} from "@mui/styles";
import {LanguageConsumer} from "../../../components/LanguageProvider/LanguageProvider";


const useStyles = makeStyles(theme => ({
  inputRoot: {
    padding: "0px !important",
    border: "none !important",
    outline: "none !important",
    borderRadius: 1,
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none !important",
      outline: "none !important",
    },
  },
  popupIndicator: {},
  root: {
    border: "none !important",
    outline: "none !important",
  },
}));

export const Account = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const user = useSelector(store => store.base.user);
  const modalEmailState = useSelector(store => store.base.modalChangeEmail);
  const modalUsernameState = useSelector(store => store.base.modalChangeUsername);

  const [countryId, setCountryId] = useState();

  const [oldPassword, setOldPassword] = useState();
  const [newPassword, setNewPassword] = useState();


  useEffect(() => {
    if (user) {
      setCountryId(user.countryId);
    }
  }, []);

  const handleChangeCountry = async (e, v) => {
    setCountryId(v.id);

    try {
      await client.changeCountry({countryId: +v.id});
      notification.success("Country changed");
    } catch (error) {
      notification.warning(error.message);
    }
  }

  const updatePassword = async (e) => {
    e.preventDefault();
    try {
      await client.updatePassword({
        newPassword: newPassword,
        oldPassword: oldPassword
      });
      setOldPassword("");
      setNewPassword("");
      notification.success("Password changed");
    } catch (error) {
      notification.warning(error.message);
    }
  }

  const handleChangeNewPassword = (e) => {
    setNewPassword(e.target.value);
  }

  const handleChangeOldPassword = (e) => {
    setOldPassword(e.target.value);
  }

  const requestChangeEmail = async () => {
    try {
      await client.requestChangeEmail();
      dispatch(setModalStates({...modalEmailState, modalChangeEmail: true}));
    } catch (error) {
      notification.warning(error.message);
    }
  }

  const requestChangeUsername = async () => {
    try {
      await client.requestChangeUsername();
      dispatch(setModalStates({...modalUsernameState, modalChangeUsername: true}));
    } catch (error) {
      notification.warning(error.message);
    }
  }

  const handleBuyClick = () => {
    history.push(ROUTE.DASHBOARD_ACCOUNT_UPGRADE_PAYMENT + "?type=buy_pack")
  }

  const handleUpgradeClick = () => {
    history.push(ROUTE.DASHBOARD_ACCOUNT_UPGRADE_PAYMENT + "?type=upgrade")
  }

  const renderBuyButton = (dict) => {
    return <div className="buy-button-container">
      <button className="button-green button-large"
              onClick={handleBuyClick}>{dict.DASHBOARD_MAIN_BUTTON_BUY || "Buy"}
      </button>
    </div>
  }

  return (
    <LanguageConsumer>
      {({dict}) => (
        <>
          {isMobile() ? <StatMobileBlocks/> : <WalletBlocks/>}

          <div className="dashboard-blocks-container">
            <div className="dashboard-block dashboard-account-block1">
              <div className="dashboard-block-title">{dict.ACCOUNT_FORM_CAPTION || "Account information"}</div>

              <form>
                <div className="form-item">
                  <input
                    id="username"
                    type="text"
                    autoComplete="off"
                    value={user?.username}
                    placeholder=" "
                    readOnly
                  />
                  <label htmlFor="username">{dict.ACCOUNT_FORM_USERNAME || "Username"}</label>
                  {user && !user.usernameChanged &&
                    <div className="change-input-button"
                         onClick={requestChangeUsername}>{dict.ACCOUNT_FORM_UPDATE_BUTTON || "update"}</div>}
                  <div className="input-error"></div>
                </div>

                <div className="form-item">
                  <input
                    id="email"
                    type="email"
                    autoComplete="off"
                    value={user?.email}
                    placeholder=" "
                    readOnly
                  />
                  <label htmlFor="email">{dict.ACCOUNT_FORM_EMAIL || "Email"}</label>
                  {user && !user.emailChanged &&
                    <div className="change-input-button"
                         onClick={requestChangeEmail}>{dict.ACCOUNT_FORM_UPDATE_BUTTON || "update"}</div>}
                  <div className="input-error"></div>
                </div>
                <div className="form-item">
                  <input
                    id="createdAt"
                    type="text"
                    autoComplete="off"
                    value={user?.createdAt}
                    placeholder=" "
                    readOnly
                  />
                  <label htmlFor="createdAt">{dict.ACCOUNT_FORM_MEMBER_SINCE || "Member since"}</label>
                  <div className="input-error"></div>
                </div>

                <div className="form-item">
                  <span className="country-label">{dict.ACCOUNT_FORM_COUNTRY || "Country"}</span>
                  {countryId && <Autocomplete
                    id="country"
                    classes={classes}
                    options={countries}
                    fullWidth
                    autoHighlight
                    getOptionLabel={(option) => option.name}
                    defaultValue={countries.find(c => c.id === countryId)}
                    onChange={handleChangeCountry}
                    renderOption={(props, option) => (
                      <Box component="li" sx={{'& > img': {mr: 2, flexShrink: 0}}} {...props}
                           style={{color: "black", background: "white"}}>
                        <img
                          loading="lazy"
                          width="20"
                          src={`https://flagcdn.com/w20/${option.iso.toLowerCase()}.png`}
                          srcSet={`https://flagcdn.com/w40/${option.iso.toLowerCase()}.png 2x`}
                          alt=""
                        />
                        {option.name}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        size="small"
                        className="guest-input"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'new-password', // disable autocomplete and autofill. Works with new-password const
                        }}
                      />
                    )}
                  />}
                </div>

                <div className="form-item">
                  <input
                    id="referral"
                    type="text"
                    autoComplete="off"
                    value={user?.referral}
                    placeholder=" "
                    readOnly
                  />
                  <label htmlFor="referral">{dict.ACCOUNT_FORM_SPONSOR || "Your sponsor"}</label>
                  <div className="input-error"></div>
                </div>
              </form>
            </div>

            <div className="dashboard-block-container">
              {!isMobile() ? <div className="dashboard-block dashboard-account-block2">
                  <div>{dict.ACCOUNT_RENEW_CAPTION || "Renew and upgrade package or publish your e-book"}</div>
                  <div className="dashboard-renew-info-container">
                    <div className="dashboard-renew-info1">
                      <div>{dict.ACCOUNT_RENEW_PACK || "My publishing package"}:</div>
                      <div>{user?.userPack?.pack?.name}</div>
                    </div>
                    <div className="dashboard-renew-info2">
                      {user?.userPack?.pack?.id > 1 && <>
                        <div>{dict.ACCOUNT_RENEW_ACTIVE_UNTIL || "Active until"}:</div>
                        <div>{user?.activeUntil}</div>
                      </>
                      }
                    </div>
                  </div>
                  <div className="form-button-container">
                    {renderBuyButton(dict)}
                    <button className="button-blue button-large"
                            onClick={() => window.open("https://made4share.com")}>{dict.ACCOUNT_RENEW_BUTTON_PUBLISH || "Publish your E-book"}
                    </button>
                  </div>
                </div> :
                <div className="dashboard-block dashboard-account-block2">
                  <div className="dashboard-account-renew-item">
                    <div>
                      <div>{dict.ACCOUNT_RENEW_PACK || "My publishing package"}:</div>
                      <div>{user?.userPack?.pack?.name}</div>
                    </div>
                    <div>
                      {(user.userPack.pack.id > 1) &&
                        <button className="upgrade-button-small"
                                onClick={handleUpgradeClick}>{dict.DASHBOARD_MAIN_BUTTON_UPGRADE || "Upgrade"}
                        </button>
                      }
                    </div>
                  </div>
                  <div className="dashboard-account-renew-item">
                    <div>
                      <div>{dict.ACCOUNT_RENEW_PACK_MOBILE || "Renew and buy package"}:</div>
                    </div>
                    <div>
                      {(user.userPack.pack.id > 1) &&
                        <button className="buy-button-small"
                                onClick={handleBuyClick}>{dict.DASHBOARD_MAIN_BUTTON_BUY || "Buy"}
                        </button>
                      }
                    </div>
                  </div>

                  {user?.userPack?.pack?.id > 1 && <div className="dashboard-account-renew-item">
                    <div>{dict.ACCOUNT_RENEW_ACTIVE_UNTIL || "Active until"}: {user?.activeUntil}</div>
                    <div>
                      <button className="publish-button-small"
                              onClick={() => window.open("https://made4share.com")}>{dict.ACCOUNT_RENEW_BUTTON_PUBLISH_MOBILE || "Publish"}
                      </button>
                    </div>
                  </div>}
                </div>
              }
              <div className="dashboard-block dashboard-account-block3">
                <div className="dashboard-block-title">{dict.ACCOUNT_UPDATE_PASS_TITLE || "Update password"}</div>
                <form onSubmit={updatePassword}>
                  <div className="form-item">
                    <input
                      id="newPassword"
                      type="password"
                      autoComplete="off"
                      value={newPassword}
                      onChange={handleChangeNewPassword}
                      required
                      placeholder=" "
                    />
                    <label htmlFor="newPassword">{dict.ACCOUNT_UPDATE_PASS_FORM_NEW_PASS || "New password"}</label>
                    <div className="input-error"></div>
                  </div>
                  <div className="form-item">
                    <input
                      id="currentPassword"
                      type="password"
                      autoComplete="off"
                      value={oldPassword}
                      onChange={handleChangeOldPassword}
                      required
                      placeholder=" "
                    />
                    <label
                      htmlFor="currentPassword">{dict.ACCOUNT_UPDATE_PASS_FORM_CURR_PASS || "Current password"}</label>
                    <div className="input-error"></div>
                  </div>
                  <div className="form-button-container">
                    <button type="submit"
                            className="button-green button-large">{dict.ACCOUNT_UPDATE_FORM_SUBMIT || "Update"}</button>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <ModalChangeEmail/>
          <ModalChangeUsername/>

        </>
      )}
    </LanguageConsumer>
  )
}