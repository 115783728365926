import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {isMobile} from "../../../utils/checkDevice";
import {WalletBlocks} from "../../../components/Blocks/WalletBlocks";
import {WalletMobileBlocks} from "../../../components/Blocks/WalletMobileBlocks";
import {CashWalletTransactionsTable} from "./components/CashWalletTransactionsTable";
import {TradingWalletTransactionsTable} from "./components/TradingWalletTransactionsTable";
import {LmgtTransactionsTable} from "./components/LmgtTransactionsTable";
import {PayoutTransactionsTable} from "./components/PayoutTransactionsTable";
import {client} from "../../../services/client";
import {notification} from "../../../components/Notification";
import "../Dashboard.css";
import {ModalSecurityCode} from "../../../components/Modals/ModalSecurityCode/ModalSecurityCode";
import {setModalStates, setWallet} from "../../../store/actions/actions";
import {ModalChangeWallet} from "../../../components/Modals/ModalChangeWallet/ModalChangeWallet";
import {LanguageConsumer} from "../../../components/LanguageProvider/LanguageProvider";
import {MandatoryWalletTransactionsTable} from "./components/MandatoryWalletTransactionsTable";
import {LoyaltyRewardTable} from "./components/LoyaltyRewardTable";

export const Wallets = () => {
  const dispatch = useDispatch();
  const submenuId = useSelector(store => store.base.submenuId);
  const wallet = useSelector(store => store.base.wallet);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState();
  const [selectedWallet, setSelectedWallet] = useState();

  const modalStatesPayout = useSelector(store => store.base.modalSecurityCode);
  const modalStatesWallet = useSelector(store => store.base.modalSecurityCode);

  const [btcAddress, setBtcAddress] = useState();
  const [ethAddress, setEthAddress] = useState();
  const [usdtAddress, setUsdtAddress] = useState();

  const payoutMethods = [
    {id: 2, name: "BTC"},
    {id: 3, name: "ETH"},
    {id: 4, name: "USDT (ERC-20)"},
  ];

  const handleChangePaymentMethod = (e) => {
    console.log(e.target.value);
    setSelectedPaymentMethod(payoutMethods[e.target.value - 2]);
  }

  useEffect(() => {
    if (wallet) {
      setBtcAddress(wallet.btcAddress);
      setEthAddress(wallet.ethAddress);
      setUsdtAddress(wallet.usdtAddress);
    }
    setSelectedPaymentMethod(payoutMethods[0]);
  }, [wallet]);


  const requestChangeWallet = async (e) => {
    e.preventDefault();
    try {
      setSelectedWallet(e.target.offsetParent.firstChild.id.toUpperCase());
      await client.requestChangeWallet();
      dispatch(setModalStates({...modalStatesWallet, modalChangeWallet: true}));
    } catch (error) {
      notification.warning(error.message);
    }
  }


  const requestPayout = async (e) => {
    e.preventDefault();
    console.log(selectedPaymentMethod)
    const amount = +e.target.amount.value;
    try {
      await client.createPayout({
        amount: amount,
        payoutMethodId: selectedPaymentMethod.id
      });
      dispatch(setModalStates({...modalStatesPayout, modalSecurityCode: true}));
      dispatch(setWallet({
        ...wallet,
        pendingPayout: wallet.pendingPayout + amount,
        cashBalance: wallet.cashBalance - amount
      }));
    } catch (error) {
      notification.warning(error.message);
    }
  }

  return (
    <LanguageConsumer>
      {({dict}) => (
        <>
          {isMobile() ? <WalletMobileBlocks/> : <WalletBlocks/>}

          {submenuId && (+submenuId === 1 ?
              <div className="dashboard-block mobile-margin">
                <div
                  className="dashboard-table-title">{dict.WALLETS_CASH_TRANSACTIONS || "Cash wallet transactions"}</div>
                <CashWalletTransactionsTable/>
              </div>
              : +submenuId === 2 ?
                <div className="dashboard-block mobile-margin">
                  <div
                    className="dashboard-table-title">{dict.LOYALTY_REWARDS_TRANSACTIONS || "Weekly loyalty rewards transactions"}</div>
                  <LoyaltyRewardTable/>
                </div>
                : +submenuId === 3 ?
                  <>
                    <div className="dashboard-blocks-container">
                      <div className={isMobile() ? "dashboard-block" : "dashboard-block dashboard-wallet-block1"}>
                        <div className="dashboard-table-title">{dict.WALLETS_CREATE_PAYOUT || "Create pay out"}</div>
                        <form onSubmit={requestPayout}>
                          <div className="form-item">
                            <input
                              id="amount"
                              type="number"
                              required
                              autoComplete="off"
                              placeholder=" "
                              min={10}
                            />
                            <label htmlFor="amount">{dict.WALLETS_AMOUNT_WITHDRAW || "Amount to be withdrawn"}</label>
                            <div className="input-error"></div>
                          </div>
                          <div className="form-item">
                            <div className="input-label">{dict.WALLETS_PAYOUT_METHOD || "Payout method"}</div>
                            <select onChange={handleChangePaymentMethod} value={selectedPaymentMethod?.id}>
                              {payoutMethods && payoutMethods.map((c, i) => {
                                return <option value={c.id} key={c.id}
                                               id={c.id}>{c.name}</option>
                              })}
                            </select>
                          </div>
                          <div className="payout-form-separator"/>
                          <div className="form-button-container payout-form-button">
                            <button type="submit"
                                    className="button-blue button-large">{dict.WALLETS_REQUEST_PAYOUT || "Request payout"}</button>
                          </div>
                        </form>
                      </div>
                      <div className={isMobile() ? "dashboard-block" : "dashboard-block dashboard-wallet-block2"}>
                        <div
                          className="dashboard-block-title">{dict.WALLETS_YOUR_CRYPTO_WALLETS || "Your crypto wallets"}</div>
                        <form>
                          <div className="form-item">
                            <input
                              id="btc"
                              type="text"
                              autoComplete="off"
                              placeholder=" "
                              value={btcAddress}
                              readOnly
                            />
                            <label htmlFor="btc">{dict.WALLETS_BTC_WALLET || "BTC wallet"}</label>
                            <div className="change-input-button"
                                 onClick={requestChangeWallet}>{dict.WALLETS_UPDATE || "update"}</div>
                            <div className="input-error"></div>
                          </div>
                          <div className="form-item">
                            <input
                              id="eth"
                              type="text"
                              autoComplete="off"
                              placeholder=" "
                              value={ethAddress}
                              readOnly
                            />
                            <label htmlFor="username">{dict.WALLETS_ETH_WALLET || "ETH wallet"}</label>
                            <div className="change-input-button"
                                 onClick={requestChangeWallet}>{dict.WALLETS_UPDATE || "update"}</div>
                            <div className="input-error"></div>
                          </div>
                          <div className="form-item">
                            <input
                              id="usdt"
                              type="text"
                              autoComplete="off"
                              placeholder=" "
                              value={usdtAddress}
                              readOnly
                            />
                            <label htmlFor="usdt">{dict.WALLETS_USDT_WALLET || "USDT (ERC-20) wallet"}</label>
                            <div className="change-input-button"
                                 onClick={requestChangeWallet}>{dict.WALLETS_UPDATE || "update"}</div>
                            <div className="input-error"></div>
                          </div>
                          <div className="form-button-container">

                          </div>
                        </form>
                      </div>
                    </div>
                    <div className={isMobile() ? "mobile-margin" : "dashboard-block mobile-margin"}>
                      <div
                        className="dashboard-block-title">{dict.WALLETS_PAYOUT_TRANSACTIONS || "Pay out transactions"}</div>
                      <PayoutTransactionsTable/>
                    </div>
                  </>
                  : +submenuId === 4 ?
                    <div className={isMobile() ? "mobile-margin" : "dashboard-block mobile-margin"}>
                      <div
                        className="dashboard-block-title">{dict.WALLETS_LMGT_TRANSACTIONS || "LMGT transactions"}</div>
                      <LmgtTransactionsTable/>
                    </div>
                    : +submenuId === 5 &&
                    <div className={isMobile() ? "mobile-margin" : "dashboard-block mobile-margin"}>
                      <div
                        className="dashboard-block-title">{dict.WALLETS_MANDATORY_TRANSACTIONS || "Mandatory transactions"}</div>
                      <MandatoryWalletTransactionsTable/>
                    </div>

          )}

          <ModalSecurityCode type="payout"/>
          <ModalChangeWallet walletType={selectedWallet}/>

        </>
      )}
    </LanguageConsumer>
  )
}