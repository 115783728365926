import React, {useState} from "react";
import {Modal} from "@mui/material";
import {setModalStates, setUser} from "../../../store/actions/actions";
import {useDispatch, useSelector} from "react-redux";
import {notification} from "../../Notification";
import {client} from "../../../services/client";
import {ConfirmationCode} from "../../ConfrimationCode/ConfirmationCode";
import {LanguageConsumer} from "../../LanguageProvider/LanguageProvider";

export const ModalChangeEmail = () => {
  const dispatch = useDispatch();
  const modalStates = useSelector(store => store.base.modalStates);
  const user = useSelector(store => store.base.user);
  const [code, setCode] = useState();


  const handleChange = (value) => {
    setCode(value)
  }

  const handleCloseModal = () => {
    dispatch(setModalStates({...modalStates, modalChangeEmail: false}));
  }

  const sendCode = async (e) => {
    e.preventDefault();
    try {
      await client.confirmChangeEmail({
        email: e.target.email.value,
        confirmationCode: code,
      });
      dispatch(setUser({...user, email: e.target.email.value, emailChanged: true}))
      handleCloseModal();
      notification.success("Email changed")
    } catch (e) {
      notification.warning(e.message);
    }
  }

  const modal = (
    <LanguageConsumer>
      {({dict}) => (
        <div className="modal">
          <div className="modal-close-button" onClick={handleCloseModal}/>
          <div className="modal-content center">
            <div>{dict.MODAL_CHANGE_EMAIL_CAPTION || "Add your new email address and the security code sent to your current email address to confirm the modifications."}
            </div>

            <form onSubmit={sendCode}>
              <div className="form-item">
                <input
                  id="email"
                  type="email"
                  required
                  autoComplete="off"
                  placeholder=" "
                />
                <label htmlFor="email">{dict.MODAL_CHANGE_EMAIL_NEW_EMAIL|| "New email"}</label>
                <div className="input-error"></div>
              </div>
              <div className="form-item1">
                <ConfirmationCode
                  onChange={handleChange}
                  onComplete={sendCode}
                  numberOfFields={6}
                />
              </div>
              <div className="modal-button-container">
                <button className="button-green button-large" type="submit">{dict.MODAL_CHANGE_EMAIL_BUTTON || "Confirm"}</button>
              </div>
            </form>
          </div>
        </div>
      )}
    </LanguageConsumer>
  );

  return (
    <Modal
      open={modalStates.modalChangeEmail}
      onClose={handleCloseModal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      {modal}
    </Modal>
  )
}