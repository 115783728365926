import React, {useEffect, useState} from "react";
import {notification} from "../../../components/Notification";
import {client} from "../../../services/client";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {toEur} from "../../../utils/tools";
import QRCode from "qrcode.react";
import {ORDER_STATUS} from "../../../constants/orderStatuses";
import {setUser} from "../../../store/actions/actions";
import {USER_STATUS} from "../../../constants/userStatuses";
import {ROUTE} from "../../../constants/routes";
import {LanguageConsumer} from "../../../components/LanguageProvider/LanguageProvider";
import "../Auth.css"

export const SignupPayment = () => {
  const user = useSelector(store => store.base.user);
  const dispatch = useDispatch();
  const [packs, setPacks] = useState(null);
  const [tokenPacks, setTokenPacks] = useState(null);
  const [cards, setCards] = useState(null);
  const [selectedPack, setSelectedPack] = useState();
  const [selectedTokenPack, setSelectedTokenPack] = useState(null);
  const [selectedCard, setSelectedCard] = useState(null);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState();
  const [epin, setEpin] = useState();
  const [epinSelected, setEpinSelected] = useState(true);
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(false);
  const [totalSum, setTotalSum] = useState(0);
  const history = useHistory();
  const paymentMethods = [
    {id: 1, code: "EPIN", name: "E-pin"},
    {id: 2, code: "BTC", name: "Bitcoin"},
    // {id: 3, code: "ETH", name: "Ethereum"},
    // {id: 4, code: "USDT (ERC-20)", name: "USDT (ERC-20)"},
    // {id: 5, code: "USDT (BEP-20)", name: "USDT (BEP-20)"},
    // {id: 6, code: "BNB", name: "BNB"},
    {id: 7, code: "BANK", name: "Bank transfer"},
    // {id: 10, code: "BEEKASH", name: "VISA/MasterCard"},
  ];

  useEffect(async () => {
    await client.getPacks()
      .then((r) => {
        setPacks(r);
        setSelectedPack(r[1]);
        setSelectedPaymentMethod(paymentMethods[0]);
      })
      .catch((error) => notification.warning(error.message));

    await client.getTokenPacks()
      .then((r) => {
        r.unshift({
          id: 0,
          amount: 0,
          price: 0
        })
        setTokenPacks(r);
        setSelectedTokenPack(r[0]);
      })
      .catch((error) => notification.warning(error.message));

    await client.getCards()
      .then((r) => {
        r.unshift({
          id: 0,
          name: "No card",
          description: "No card",
          price: 0
        })
        setCards(r);
        setSelectedCard(r[0]);
      })
      .catch((error) => notification.warning(error.message));

  }, []);

  const handleChangePaymentMethod = (e) => {
    const id = parseInt(e.target.value);
    const pm = paymentMethods.filter(e => e.id === id)[0];
    if (e.target.value === 8) {
      window.open("https://rebatas.eu/", "_blank");
      setSelectedPaymentMethod(paymentMethods[0]);
    }
    setSelectedPaymentMethod(pm);
  }

  const handleChangeEpin = (e) => {
    setEpin(e.target.value);
  }

  useEffect(() => {
    if (selectedPaymentMethod) {
      //Epin?
      if (selectedPaymentMethod.id === 1) {
        setEpinSelected(true);
        setOrder(null);
      } else {
        setEpinSelected(false);
        if ((totalSum > 0) && selectedPaymentMethod.code !== "BANK") {
          createOrder();
        }
      }
      if (selectedPaymentMethod.code === "BANK") {
        setOrder(null);
      }
    }
  }, [selectedPaymentMethod]);

  useEffect(() => {
    if (selectedPack && selectedCard && selectedTokenPack) {
      let s = selectedPack.signupPrice + selectedTokenPack.price + selectedCard.price;
      if (selectedPack.id === 3 && selectedCard.id > 0) {
        s -= 100;
      }
      if (selectedPack.id === 1) {
        s -= selectedTokenPack.price;
        setSelectedTokenPack(tokenPacks[0]);
      }
      setTotalSum(s);

    }
  }, [selectedPack, selectedCard, selectedTokenPack])

  const handleChangePack = (e) => {
    setSelectedPack(packs[e.target.value - 1]);
  }

  const handleChangeCard = (e) => {
    setSelectedCard(cards[e.target.value]);
  }

  const handleChangeTokenPack = (e) => {
    if (selectedPack.id === 1) {
      notification.info("LRT tokens not available for Free membership. Please choose Basic or Premium.")
    } else {
      setSelectedTokenPack(tokenPacks[e.target.value]);
    }
  }

  useEffect(() => {
    //Free pack?
    if (totalSum === 0) {
      setOrder(null);
      setEpinSelected(false);
    } else {
      //Not epin?
      if (selectedPaymentMethod && selectedPaymentMethod.id !== 1 && selectedPaymentMethod.code !== "BANK") {
        createOrder();
      } else if (selectedPaymentMethod && selectedPaymentMethod.code !== "BANK") {
        setEpinSelected(true);
      }
    }
  }, [totalSum]);

  const createOrder = async () => {
    if (loading) {
      return;
    }
    if (selectedPaymentMethod.code === "BEEKASH") {
      return;
    }
    setLoading(true);
    try {
      const response = await client.createOrder({
        packId: selectedPack.id,
        type: "SIGNUP",
        paymentMethod: selectedPaymentMethod.code,
        epin: epin,
        tokenPackId: selectedTokenPack.id === 0 ? null : selectedTokenPack.id,
        cardId: selectedCard.id === 0 ? null : selectedCard.id
      });
      setOrder(response);

      switch (response.status) {
        case ORDER_STATUS.FREE: {
          dispatch(setUser({...user, status: USER_STATUS.WAITING_FOR_PAYMENT}));
          history.push(ROUTE.SIGNUP_WAITING);
          break;
        }
        case ORDER_STATUS.PENDING: {
          dispatch(setUser({...user, status: USER_STATUS.WAITING_FOR_PAYMENT}));
          history.push(ROUTE.SIGNUP_WAITING);
          break;
        }
        case ORDER_STATUS.PAID: {
          dispatch(setUser({...user, status: USER_STATUS.MEMBER}));
          history.push(ROUTE.DASHBOARD);
          break;
        }
      }

    } catch (error) {
      notification.warning(error.message);
    }
    setLoading(false);
  }

  const submitOrder = async (e) => {
    e.preventDefault();
    if (loading) {
      return;
    }
    if (selectedPaymentMethod.code === "BEEKASH") {
      return;
    }
    setLoading(true);
    if (selectedPaymentMethod.code === "EPIN" || selectedPaymentMethod.code === "BANK" || totalSum === 0) {
      createOrder();
    } else {
      try {
        const response = await client.submitOrder(order.externalId);
        dispatch(setUser({...user, status: USER_STATUS.WAITING_FOR_PAYMENT}));
        history.push(ROUTE.SIGNUP_WAITING);
      } catch (error) {
        notification.warning(error.message);
      }
    }
    setLoading(false);
  }

  const renderPaymentData = (dict) => {
    if (order) {
      if (order.paymentMethod === "EPIN" || order.paymentMethod === "BANK") {
        return <div/>;
      } else if (order.paymentMethod !== "BEEKASH") {
        return <div className="qr-code-container">
          <div>{dict.SIGNUP_PAYMENT_FORM_QR_TITLE1 || "Please scan this QR code and pay the amount of "} {order.cryptoAmount} {order.paymentMethod} {dict.SIGNUP_PAYMENT_FORM_QR_TITLE2 || "within 1 hour. Or copy below address and pay the amount:"}
          </div>
          <div>{order.cryptoAddress}</div>
          <div><QRCode value={order.cryptoAddress}/></div>
        </div>
      } else {
        return <div/>;
      }
    } else {
      return <div/>;
    }
  }

  const renderCards = () => {
    return cards.map((c, i) => {
      return <option value={c.id} key={c.id} id={c.id}>{c.description}, {toEur(c.price)}</option>
    });
  }

  const renderTokenPacks = () => {
    return tokenPacks.map((c, i) => {
      if (c.id === 0) {
        return <option value={c.id} key={c.id} id={c.id}>No tokens, {toEur(0)}</option>
      } else {
        return <option value={c.id} key={c.id} id={c.id}>{c.amount} LRTs, {toEur(c.price)}</option>
      }
    });
  }

  return (
    <LanguageConsumer>
      {({dict}) => (
        <div className="auth-container">
          <div className="auth-content">
            <div className="caption">{dict.SIGNUP_FORM_TITLE || "Create account"}</div>
            <div className="logo" onClick={() => history.push(ROUTE.ROOT)}/>
            <div>{dict.SIGNUP_PAYMENT_FORM_INFO || "Customer information"}</div>
            <table className="customer-info-table">
              <tbody>
              <tr>
                <td>{dict.SIGNUP_FORM_USERNAME || "Username"}:</td>
                <td>{user?.username}</td>
              </tr>
              <tr>
                <td>{dict.SIGNUP_FORM_EMAIL || "Email"}:</td>
                <td>{user?.email}</td>
              </tr>
              <tr>
                <td>{dict.SIGNUP_FORM_COUNTRY || "Country"}:</td>
                <td>{user?.countryName}</td>
              </tr>
              <tr>
                <td>{dict.SIGNUP_FORM_REF || "Referral user"}:</td>
                <td>{user?.referral}</td>
              </tr>
              </tbody>
            </table>

            <form onSubmit={submitOrder}>
              <div>{dict.SIGNUP_PAYMENT_FORM_SELECT_TITLE || "Select product package"}</div>
              <div className="selected-pack-container">
                {selectedPack && <>
                  <img src={selectedPack.logo}/>
                  <div>{dict.SIGNUP_PAYMENT_FORM_TOTAL_MEMBERSHIP || "Total membership"}</div>
                  <div>{toEur(totalSum)}</div>
                </>}
              </div>

              <div className="form-item">
                <div className="input-label">{dict.SIGNUP_PAYMENT_FORM_ENROLL_PACK || "Enrollment pack"}</div>
                <select onChange={handleChangePack} value={selectedPack?.id} className="guest-input">
                  {packs && packs.map((c, i) => {
                    return <option value={c.id} key={c.id}
                                   id={c.id}>{c.name + ", " + toEur(c.signupPrice)}</option>
                  })}
                </select>
              </div>

              <div className="form-item">
                <div className="input-label">{dict.SIGNUP_PAYMENT_FORM_CARD || "VISA Card"}</div>
                <select onChange={handleChangeCard} value={selectedCard?.id} className="guest-input">
                  {cards && renderCards()}
                  })}
                </select>
              </div>

              <div className="form-item">
                <div className="input-label">{dict.SIGNUP_PAYMENT_FORM_LRT || "Loyalty Reward Token"}</div>
                <select onChange={handleChangeTokenPack} value={selectedTokenPack?.id} className="guest-input">
                  {tokenPacks && renderTokenPacks()}
                  })}
                </select>
              </div>

              <div className={totalSum > 0 ? "form-item" : "form-item hidden"}>
                <div
                  className="input-label">{dict.SIGNUP_PAYMENT_FORM_PAYMENT_METHOD || "Choose your payment method"}</div>
                <select onChange={handleChangePaymentMethod} className="guest-input">
                  {paymentMethods.map((c, i) => {
                    return <option value={c.id} key={c.id} id={c.id}>{c.name}</option>
                  })}
                </select>
              </div>

              <div
                className={epinSelected && totalSum > 0 ? "form-item" : "form-item hidden"}>
                <div className="input-label">{dict.SIGNUP_PAYMENT_FORM_TYPE_EPIN || "Type e-pin"}</div>
                <input
                  id="epin"
                  name="epin"
                  type="text"
                  required={epinSelected}
                  value={epin}
                  onChange={handleChangeEpin}
                  autoComplete="off"
                  placeholder="E-pin"
                  className="guest-input"
                />
              </div>

              <div className="form-item">
                {renderPaymentData(dict)}
              </div>

              <div className="form-item form-button-container">
                {loading ? <span className="loader"/> :
                  <button className="button-green button-large"
                          type="submit">{dict.SIGNUP_PAYMENT_FORM_SUBMIT_BUTTON || "Submit"}</button>}
              </div>
            </form>

          </div>
        </div>
      )}
    </LanguageConsumer>
  )
}