import React, {useState} from "react";
import {Modal} from "@mui/material";
import {setModalStates, setUser} from "../../../store/actions/actions";
import {useDispatch, useSelector} from "react-redux";
import {notification} from "../../Notification";
import {client} from "../../../services/client";
import {ConfirmationCode} from "../../ConfrimationCode/ConfirmationCode";
import {LanguageConsumer} from "../../LanguageProvider/LanguageProvider";

export const ModalChangeUsername = () => {
  const dispatch = useDispatch();
  const modalStates = useSelector(store => store.base.modalStates);
  const user = useSelector(store => store.base.user);
  const [code, setCode] = useState();


  const handleChange = (value) => {
    setCode(value)
  }

  const handleCloseModal = () => {
    dispatch(setModalStates({...modalStates, modalChangeUsername: false}));
  }

  const sendCode = async (e) => {
    e.preventDefault();
    try {
      await client.confirmChangeUsername({
        username: e.target.username.value,
        confirmationCode: code,
      });
      handleCloseModal();
      dispatch(setUser({...user, username: e.target.username.value, usernameChanged: true}))
      notification.success("Username changed")
    } catch (e) {
      notification.warning(e.message);
    }
  }

  const modal = (
    <LanguageConsumer>
      {({dict}) => (
        <div className="modal">
          <div className="modal-close-button" onClick={handleCloseModal}/>
          <div className="modal-content center">
            <div>{dict.MODAL_CHANGE_USERNAME_CAPTION || "Add your new username and the security code sent to your current email address to confirm the modifications."}
            </div>

            <form onSubmit={sendCode}>
              <div className="form-item">
                <input
                  id="username"
                  type="text"
                  required
                  autoComplete="off"
                  placeholder=""
                />
                <label htmlFor="username">{dict.MODAL_CHANGE_USERNAME_NEW_USERNAME|| "New username"}</label>
                <div className="input-error"></div>
              </div>
              <div className="form-item1">
                <ConfirmationCode
                  onChange={handleChange}
                  onComplete={sendCode}
                  numberOfFields={6}
                />
              </div>
              <div className="modal-button-container">
                <button className="button-green button-large" type="submit">{dict.MODAL_CHANGE_USERNAME_BUTTON || "Confirm"}</button>
              </div>
            </form>
          </div>
        </div>
      )}
    </LanguageConsumer>
  );

  return (
    <Modal
      open={modalStates.modalChangeUsername}
      onClose={handleCloseModal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      {modal}
    </Modal>
  )
}