import React, {useState} from "react";
import "./TopPanelMobile.css";
import {ROUTE} from "../../constants/routes";
import {logout, setMenuId, setSubmenuId} from "../../store/actions/actions";
import {useHistory} from "react-router-dom";
import {useDispatch} from "react-redux";
import {SwipeableDrawer} from "@mui/material";
import LanguageSelect from "../LanguageSelect/LanguageSelect";
import {LanguageConsumer} from "../LanguageProvider/LanguageProvider";

export const TopPanelMobile = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [menuOpen, setMenuOpen] = useState(false);

  const iOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);

  return (
    <LanguageConsumer>
      {({dict}) => (
        <div className="top-panel-mobile">
          <div className="icon-account-mobile" onClick={() => setMenuOpen(true)}/>
          <div className="icons-right-block-mobile">
            <LanguageSelect/>
            <div className="icon-bell-mobile"/>
          </div>


          <SwipeableDrawer
            open={menuOpen}
            onClose={() => setMenuOpen(false)}
            onOpen={() => setMenuOpen(true)}
            disableBackdropTransition={!iOS}
            disableDiscovery={iOS}
          >
            <div className="mobile-menu">
              <div className="mobile-menu-item" onClick={() => {
                dispatch(setMenuId(0));
                dispatch(setSubmenuId(1));
                localStorage.submenuId = 1;
                localStorage.menuId = 0;
                localStorage.route = ROUTE.DASHBOARD_ACCOUNT;
                history.push(ROUTE.DASHBOARD_ACCOUNT);
                setMenuOpen(false);
              }}>{dict.TOP_PANEL_MENU_MY_ACCOUNT || "My Account"}
              </div>
              <div className="menu-dropdown-item" onClick={() => {
                dispatch(setSubmenuId(3));
                dispatch(setMenuId(3));
                localStorage.submenuId = 3;
                localStorage.menuId = 3;
                localStorage.route = ROUTE.DASHBOARD_WALLETS;
                history.push(ROUTE.DASHBOARD_WALLETS);
                setMenuOpen(false);
              }}>{dict.TOP_PANEL_MENU_PAYOUT || "Payout"}
              </div>
              <div className="menu-dropdown-item" onClick={() => {
                dispatch(setSubmenuId(1));
                dispatch(setMenuId(0));
                localStorage.submenuId = 1;
                localStorage.menuId = 0;
                localStorage.route = ROUTE.DASHBOARD_ORDERS;
                history.push(ROUTE.DASHBOARD_ORDERS);
                setMenuOpen(false);
              }}>{dict.TOP_PANEL_MENU_ORDERS || "Orders"}
              </div>
              <div className="mobile-menu-item" onClick={() => {
                dispatch(setMenuId(0));
                dispatch(setSubmenuId(1));
                localStorage.submenuId = 1;
                localStorage.menuId = 0;
                localStorage.route = ROUTE.DASHBOARD_STATEMENTS;
                history.push(ROUTE.DASHBOARD_STATEMENTS);
                setMenuOpen(false);
              }}>{dict.TOP_PANEL_MENU_STATEMENTS || "Statements"}
              </div>
              <div className="mobile-menu-item" onClick={() => {
                window.open("https://made4share.com");
                setMenuOpen(false);
              }}>{dict.TOP_PANEL_MENU_EBOOK || "My E-book"}
              </div>
              <div className="mobile-menu-item" onClick={() => {
                window.open("https://reseven.com");
                setMenuOpen(false);
              }}>{dict.TOP_PANEL_MENU_EXCHANGE || "Exchange"}
              </div>
              <div className="mobile-menu-item" onClick={() => {
                dispatch(logout());
                setMenuOpen(false);
              }}>{dict.TOP_PANEL_MENU_LOGOUT || "Logout"}
              </div>
            </div>
          </SwipeableDrawer>

        </div>
      )}
    </LanguageConsumer>
  )
}