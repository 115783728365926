import React from "react";
import {ResponsiveChoropleth} from "@nivo/geo";
import worldCountries from "./worldsCountries.json";
import {useSelector} from "react-redux";
import {mapIso2ToIso3} from "./utils";

export const Map = () => {
  const mapStat = useSelector(store => store.base.mapStat);

  return(
    <div className="world-map">
      {mapStat && (
        <ResponsiveChoropleth
          data={mapIso2ToIso3(mapStat.mapData)}
          features={worldCountries.features}
          margin={{top: 0, right: 0, bottom: 0, left: 0}}
          domain={[0, 1]}
          unknownColor="#666666"
          label="properties.name"
          // valueFormat=".2s"
          projectionTranslation={[0.5, 0.6]}
          projectionRotation={[0, 0, 0]}
          // enableGraticule={true}
          graticuleLineColor="#dddddd"
          borderWidth={0.5}
          borderColor="white"
          colors={["#d0d1d2", "dodgerblue"]}
          // tooltip={MapTooltip}
        />
      )}
    </div>
  )
}