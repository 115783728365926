import React from "react";
import {LanguageConsumer} from "../../components/LanguageProvider/LanguageProvider";
import "./Static.css";
import {StaticPageHeader} from "./components/StaticPageHeader";
import {LandingFooter} from "../../components/Footer/LandingFooter";

export const History = () => {
  return (
    <LanguageConsumer>
      {({dict}) => (
        <div className="static-container">
          <div className="static-page-history">

            <StaticPageHeader title={dict.HISTORY_PAGE_TITLE} dict={dict}/>

            <div className="static-page-content-container">
              <div className="static-page-content">
                <div>
                  <div className="static-page-p">
                    <div className="static-page-p-title">{dict.HISTORY_PAGE_P1_TITLE}</div>
                    <div>{dict.HISTORY_PAGE_P1}</div>
                  </div>

                  <div className="static-page-p">
                    <div>{dict.HISTORY_PAGE_P2}</div>
                  </div>

                  <div className="static-page-p">
                    <div>{dict.HISTORY_PAGE_P3}</div>
                  </div>

                  <div className="static-page-p">
                    <div>{dict.HISTORY_PAGE_P5}</div>
                  </div>

                  <div className="static-page-p">
                    <div>{dict.HISTORY_PAGE_P6}</div>
                  </div>

                  <div className="static-page-p">
                    <div>{dict.HISTORY_PAGE_P7}</div>
                  </div>

                  <div className="static-page-p">
                    <div className="static-page-img-history1"/>
                  </div>

                  <div className="static-page-p">
                    <div className="static-page-p-title">{dict.HISTORY_PAGE_P8_TITLE}</div>
                    <div>
                      <ul>
                        <li>{dict.HISTORY_PAGE_P8_ITEM1}</li>
                        <li>{dict.HISTORY_PAGE_P8_ITEM2}</li>
                        <li>{dict.HISTORY_PAGE_P8_ITEM3}</li>
                        <li>{dict.HISTORY_PAGE_P8_ITEM4}</li>
                        <li>{dict.HISTORY_PAGE_P8_ITEM5}</li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div>
                  <div className="static-page-p">
                    <div className="static-page-p-title">{dict.HISTORY_PAGE_P9_TITLE}</div>
                    <div>{dict.HISTORY_PAGE_P9}</div>
                  </div>

                  <div className="static-page-p">
                    <div>{dict.HISTORY_PAGE_P10}</div>
                  </div>

                  <div className="static-page-p">
                    <div>{dict.HISTORY_PAGE_P11}</div>
                  </div>

                  <div className="static-page-p">
                    <div>{dict.HISTORY_PAGE_P12}</div>
                  </div>

                  <div className="static-page-p">
                    <div>{dict.HISTORY_PAGE_P13}</div>
                  </div>

                  <div className="static-page-p">
                    <div>{dict.HISTORY_PAGE_P14}</div>
                  </div>

                  <div className="static-page-p">
                    <div className="static-page-p-title">{dict.HISTORY_PAGE_P15_TITLE}</div>
                    <div>{dict.HISTORY_PAGE_P15}</div>
                  </div>

                  <div className="static-page-p">
                    <div>{dict.HISTORY_PAGE_P16}</div>
                  </div>

                </div>

              </div>
            </div>

            <LandingFooter dict={dict}/>

          </div>

        </div>
      )}
    </LanguageConsumer>
  )
}