import React from "react";
import "../Static.css"
import {setModalStates} from "../../../store/actions/actions";
import {useDispatch, useSelector} from "react-redux";
import {ModalInvite} from "../../../components/Modals/ModalInvite/ModalInvite";
import {ROUTE} from "../../../constants/routes";
import LanguageSelect from "../../../components/LanguageSelect/LanguageSelect";
import {useHistory} from "react-router-dom";

export const StaticPageHeader = ({dict, title}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const modalStates = useSelector(store => store.base.modalStates);

  const handleRegister = () => {
    dispatch(setModalStates({...modalStates, modalInvite: true}));
  }

  return (
    <>
      <div className="static-header">
        <div className="static-logo" onClick={() => history.push(ROUTE.ROOT)}/>
        <div className="static-lang-selector-container">
          <LanguageSelect/>
        </div>
      </div>

      <div className="static-page-content-container">

        <div className="static-page-content static-flex-direction">
          <div className="static-page-title">{title}</div>
          <button className="static-page-button" onClick={handleRegister}>
            {dict.LANDING_BUTTON_REGISTER || "Register"}
          </button>

          <ModalInvite/>

        </div>
      </div>
    </>
  )
}