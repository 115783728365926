import React, {useCallback, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {NetworkBlocks} from "../../../components/Blocks/NetworkBlocks";
import {isMobile} from "../../../utils/checkDevice";
import {NetworkMobileBlocks} from "../../../components/Blocks/NetworkMobileBlocks";
import "./Network.css";
import {client} from "../../../services/client";
import {notification} from "../../../components/Notification";
import {CustomTree} from "./components/CustomTree/CustomTree";
import {LanguageConsumer} from "../../../components/LanguageProvider/LanguageProvider";

export const Network = () => {
  const user = useSelector(store => store.base.user);
  const [root, setRoot] = useState();
  const [networkStat, setNetworkStat] = useState();
  const [activeTab, setActiveTab] = useState("binary");
  const [searchValue, setSearchValue] = useState("");

  const changeUserValue = (e) => {
    const v = e.target.value;
    setSearchValue(v);
  }

  const changeActiveTab = (tab) => {
    setActiveTab(tab);
  }

  const getNetworkStat = useCallback(async () => {
    try {
      const response = await client.getCurrentUserNetworkStat();
      setNetworkStat(response);
    } catch (e) {
      notification.warning(e.message);
    }
  }, [networkStat]);

  useEffect(() => {
    getNetworkStat();
  }, []);

  const searchUser = () => {
    client.searchInBinarySubtree(searchValue)
      .then((r) => setRoot(r))
      .catch(() => setRoot(user));
  }

  const upInSubtree = () => {
    client.upInBinarySubtree(root.id)
      .then((r) => setRoot(r))
  }

  useEffect(() => {
    setRoot(user);
  }, [user]);

  useEffect(() => {
    if (searchValue === "" || activeTab === "sponsor") {
      setRoot(user)
    } else {
      searchUser();
    }
  }, [searchValue]);

  useEffect(() => {
    if (root) {
      setRoot(root)
    }
  }, [])


  const renderTree = (dict) => {
    if (activeTab === "sponsor" && root) {
      return <div className="dashboard-blocks-container">
        <div className="dashboard-block tree-block">
          {/*Without this the sponsor tree doesn't work. I'm not an expert in js magic, need to ask frontend team*/}
          {console.log("sponsor")}
          <div className="dashboard-block-title">{dict.NETWORK_SPONSOR_TREE || "Sponsor tree"}</div>
          <CustomTree type="sponsor" _root={root}/>
        </div>
      </div>
    }
    if (activeTab === "binary" && root) {
      {/*LOL and here when back from sponsor tree tab the binary tree doesn't work. I don't have time to this js shit*/}
      {console.log("binary")}
      return <div className="dashboard-blocks-container">
        <div className="dashboard-block tree-block">
          <div className="dashboard-block-title">{dict.NETWORK_BINARY_TREE || "Binary tree"}</div>
          <div onClick={upInSubtree} className="tree-button-with-label">
            <div className="tree-button"/>
            <div>Up</div>
          </div>
          <CustomTree type="binary" _root={root}/>
        </div>
      </div>
    }
  }

  return (
    <LanguageConsumer>
      {({dict}) => (
        <>
          {isMobile() ? <NetworkMobileBlocks data={networkStat}/> : <NetworkBlocks data={networkStat}/>}

          <div className="dashboard-block tree-search-panel">
            <div className={activeTab === "binary" ? "tree-tab tree-tab-active" : "tree-tab"}
                 onClick={() => changeActiveTab("binary")}>
              {dict.NETWORK_BINARY_TREE || "Binary tree"}
            </div>
            <div className={activeTab === "sponsor" ? "tree-tab tree-tab-active" : "tree-tab"}
                 onClick={() => changeActiveTab("sponsor")}>
              {dict.NETWORK_SPONSOR_TREE || "Sponsor tree"}
            </div>
            <div>
              <div className="custom-input-label">Search partners</div>
              <input
                type="text"
                value={searchValue}
                onChange={changeUserValue}
                placeholder="Type username"
                className="custom-input"
              />
            </div>
          </div>

          {root && renderTree(dict)}

        </>
      )}
    </LanguageConsumer>
  )
}