import React, {useEffect, useState} from "react";
import {notification} from "../../../components/Notification";
import {client} from "../../../services/client";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {toEur} from "../../../utils/tools";
import QRCode from "qrcode.react";
import {ORDER_STATUS} from "../../../constants/orderStatuses";
import {setUser, setUserStat, setWallet} from "../../../store/actions/actions";
import {ROUTE} from "../../../constants/routes";
import {WalletMobileBlocks} from "../../../components/Blocks/WalletMobileBlocks";
import {WalletBlocks} from "../../../components/Blocks/WalletBlocks";
import {isMobile} from "../../../utils/checkDevice";
import "../../auth/Auth.css";
import {USER_STATUS} from "../../../constants/userStatuses";

export const DashboardWaiting = ({type}) => {
  const user = useSelector(store => store.base.user);
  const dispatch = useDispatch();
  const history = useHistory();
  const [order, setOrder] = useState();

  useEffect(() => {
    if (type === "signup") {
      localStorage.route = ROUTE.DASHBOARD_SIGNUP_WAITING;
    } else {
      localStorage.route = ROUTE.DASHBOARD_ACCOUNT_UPGRADE_WAITING;
    }
    if (user && user.hasPartPaidOrder) {
      getLastOrder();
    }
  }, [user]);

  const getLastOrder = async () => {
    try {
      const response = await client.getLastOrder();
      setOrder(response);
    } catch (error) {
      notification.warning(error.message);
    }
  }

  const handleReload = () => {
    getLastOrder();
  }

  return (
    <>
      {isMobile() ? <WalletMobileBlocks/> : <WalletBlocks/>}
      <div className="dashboard-blocks-container">
        <div className="auth-content">
          <div className="caption">Thank you</div>
          <div className="logo" onClick={() => history.push(ROUTE.ROOT)}/>
          <div className="form-item center waiting-content">
            We have emailed the registered address with information on proceeding and activating the account. Customers
            that choose not to buy any service or product will only retain any sales volume created by other customers
            for
            this ongoing week. We will delete any sales volume after this period.
          </div>
          {order && order.paymentMethod !== "EPIN" && order.status !== ORDER_STATUS.FREE && order.status !== ORDER_STATUS.PAID &&
          <>
            <div className="form-item center waiting-content">
              {order.paymentMethod !== "BANK" && <div className="qr-code-container">
                <div>Please scan this QR code and pay the amount
                  of {order.cryptoAmount} {order.paymentMethod} within 1
                  hour. Or copy below address and pay the amount:
                </div>
                <div>{order.cryptoAddress}</div>
                <div><QRCode value={order.cryptoAddress}/></div>
              </div>}
            </div>
            <div className="form-item">
              <button className="button-blue button-large" onClick={handleReload}>Reload</button>
            </div>
          </>
          }

          {order && (order.status === ORDER_STATUS.FREE || order.status === ORDER_STATUS.PAID) &&
          <div className="form-item">
            <button className="button-blue button-large" onClick={() => {
              dispatch(setUser({...user, status: USER_STATUS.MEMBER}));
              history.push(ROUTE.DASHBOARD);
            }
            }>OK
            </button>
          </div>
          }
        </div>
      </div>
    </>
  )
}