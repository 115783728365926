import React, {useEffect, useState} from "react";
import "./Landing.css";
import {useHistory} from "react-router-dom";
import {ModalInvite} from "../../components/Modals/ModalInvite/ModalInvite";
import {useDispatch, useSelector} from "react-redux";
import {logout, setModalStates} from "../../store/actions/actions";
import LanguageSelect from "../../components/LanguageSelect/LanguageSelect";
import {LanguageConsumer} from "../../components/LanguageProvider/LanguageProvider";
import {isMobile} from "../../utils/checkDevice";
import {ROUTE} from "../../constants/routes";
import {client} from "../../services/client";
import {notification} from "../../components/Notification";
import {LandingFooter} from "../../components/Footer/LandingFooter";

export const Landing = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const modalStates = useSelector(store => store.base.modalStates);
  const [ref, setRef] = useState();
  const [step, setStep] = useState(1);

  useEffect(async () => {
    const _ref = new URLSearchParams(history.location.search).get('ref');

    if (_ref) {
      try {
        await client.validateRef(_ref);
        dispatch(logout());
        setRef(_ref);
      } catch (error) {
        notification.warning(error.message);
      }
    }
  }, []);

  const renderCaption = (dict) => {
    if (step === 1) {
      return dict.LANDING_TITLE_PAGE1 || "What does your future hold? Are you worried or excited?";
    }
    if (step === 2) {
      return dict.LANDING_TITLE_PAGE2 || "Creating wealth is one thing; keeping it and making it grow is another.";
    }
    if (step === 3) {
      return dict.LANDING_TITLE_PAGE3 || "The proper education and a model that ensures ownership are like walking in the park.";
    }
    if (step === 4) {
      return dict.LANDING_TITLE_PAGE4 || "You’re one click away to register for free.";
    }
  }

  const renderSubtitle = (dict) => {
    if (step === 1) {
      return dict.LANDING_SUBTITLE_PAGE1 || "We found a way to be positively excited about the future.";
    }
    if (step === 2) {
      return dict.LANDING_SUBTITLE_PAGE2 || "With us, you keep what you have made, and together we make it grow.";
    }
    if (step === 3) {
      return dict.LANDING_SUBTITLE_PAGE3 || "With us, you can create value and store it most securely.";
    }
    if (step === 4) {
      return dict.LANDING_SUBTITLE_PAGE4 || "Start free as a customer and choose your education and publishing package for your future.";
    }
  }

  const handleRegister = () => {
    if (!ref) {
      dispatch(setModalStates({...modalStates, modalInvite: true}));
    } else {
      history.push(ROUTE.SIGNUP + "?ref=" + ref);
    }
  }

  return (
    <LanguageConsumer>
      {({dict}) => (
        <div className={"landing-container landing-container-step" + step}>
          <div className="landing-content">
            <div className="landing-logo-container">
              {isMobile() && <div className="landing-login-button-mobile" onClick={() => history.push(ROUTE.LOGIN)}/>}
              <div className="landing-logo"/>
            </div>

            <div className="landing-lang-selector-container">
              {!isMobile() && <button className="landing-login-button landing-button-transparent"
                                      onClick={() => history.push(ROUTE.LOGIN)}>
                {dict.LOGIN_FORM_BUTTON || "Login"}
              </button>
              }
              <LanguageSelect/>
            </div>

            <div className="landing-ref-info">
              {ref && <div>
                You arrived here by invitation from: <br/> {ref}
              </div>}
            </div>

            <div className="landing-content-inner">
              <div className="landing-content-left">
                <div className="landing-caption1">{renderCaption(dict)}</div>
                <div className="landing-caption2">{renderSubtitle(dict)}</div>
              </div>
              {!isMobile() && <div className={"landing-img" + step}/>}
            </div>

            <div className="landing-button-container">
              <button className="landing-button landing-button-green" onClick={handleRegister}>
                {dict.LANDING_BUTTON_REGISTER || "Register"}
              </button>
              {step < 4 &&
                <button className="landing-button landing-button-transparent" onClick={() => setStep(step + 1)}>
                  {dict.LANDING_BUTTON_READ_MORE || "Read more"}
                </button>
              }
            </div>
            <div className="landing-login-link" onClick={() => history.push(ROUTE.LOGIN)}>
              {dict.LANDING_LOGIN_LINK_QUESTION || "Already have an account?"}
            </div>

            <LandingFooter dict={dict}/>

          </div>

          <ModalInvite/>

        </div>
      )}
    </LanguageConsumer>
  );
}