import React, {useEffect} from "react";
import {useSelector} from "react-redux";
import {isMobile} from "../../../utils/checkDevice";
import {StatMobileBlocks} from "../../../components/Blocks/StatMobileBlocks";
import {EventCalendar} from "./components/EventCalendar/EventCalendar";
import {News} from "./components/News/News";
import {Resources} from "./components/Resources/Resources";

export const Events = () => {
  const submenuId = useSelector(store => store.base.submenuId);

  useEffect(() => {

  }, [])

  return (
    <>
      {isMobile() && <><StatMobileBlocks/>
        {submenuId && (+submenuId === 1 ? <EventCalendar/> : +submenuId === 2 ? <Resources/> : +submenuId === 3 && <News/>)}
      </>
      }

      {!isMobile() && <div>
        <div className="dashboard-blocks-container">
          <EventCalendar/>
          <News/>
        </div>
        <div className="dashboard-blocks-container">
          <Resources/>
        </div>
      </div>}
    </>
  )
}