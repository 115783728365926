import React from 'react';
import {Route, Switch} from "react-router";
import {Redirect} from "react-router-dom";
import {GuestLayout} from "./components/Layouts/GuestLayout/GuestLayout";
import {Landing} from "./pages/landing/Landing";
import {DashboardLayout} from "./components/Layouts/DashboardLayout/DashboardLayout";
import {Login} from "./pages/auth/login/Login";
import {Signup} from "./pages/auth/signup/Signup";
import {SignupPayment} from "./pages/auth/signup/SignupPayment";
import {DashboardMain} from "./pages/dashboard/main/DashboardMain";
import {USER_STATUS} from "./constants/userStatuses";
import {SignupWaiting} from "./pages/auth/signup/SignupWaiting";
import {ROUTE} from "./constants/routes";
import {Network} from "./pages/dashboard/network/Network";
import {Wallets} from "./pages/dashboard/wallets/Wallets";
import {Epin} from "./pages/dashboard/epin/Epin";
import {Events} from "./pages/dashboard/events/Events";
import {Account} from "./pages/dashboard/account/Account";
import {Statements} from "./pages/dashboard/statements/Statements";
import {UpgradePayment} from "./pages/dashboard/account/UpgradePayment";
import {DashboardSignup} from "./pages/dashboard/signup/DashboardSignup";
import {DashboardSignupPayment} from "./pages/dashboard/signup/DashboardSignupPayment";
import {ConfirmPassword} from "./pages/auth/login/ConfirmPassword";
import {DashboardWaiting} from "./pages/dashboard/account/DashboardWaiting";
import {News} from "./pages/dashboard/events/components/News/News";
import {About} from "./pages/static/About";
import {Product} from "./pages/static/Product";
import {Press} from "./pages/static/Press";
import {History} from "./pages/static/History";
import {Orders} from "./pages/dashboard/orders/Orders";


export const useRoutes = (isAuth, userStatus) => {
  if (isAuth && userStatus === USER_STATUS.MEMBER) {
    return (
      <DashboardLayout>
        <Switch>
          <Route path={ROUTE.DASHBOARD} exact={true}>
            <DashboardMain/>
          </Route>
          <Route path={ROUTE.DASHBOARD_NETWORK} exact={true}>
            <Network/>
          </Route>
          <Route path={ROUTE.DASHBOARD_WALLETS} exact={true}>
            <Wallets/>
          </Route>
          <Route path={ROUTE.DASHBOARD_EPIN} exact={true}>
            <Epin/>
          </Route>
          <Route path={ROUTE.DASHBOARD_ORDERS} exact={true}>
            <Orders/>
          </Route>
          <Route path={ROUTE.DASHBOARD_EVENTS} exact={true}>
            <Events/>
          </Route>
          <Route path={ROUTE.DASHBOARD_SIGNUP} exact={true}>
            <DashboardSignup/>
          </Route>
          <Route path={ROUTE.DASHBOARD_SIGNUP_PAYMENT} exact={true}>
            <DashboardSignupPayment/>
          </Route>
          <Route path={ROUTE.DASHBOARD_SIGNUP_WAITING} exact={true}>
            <DashboardWaiting/>
          </Route>
          <Route path={ROUTE.DASHBOARD_ACCOUNT} exact={true}>
            <Account/>
          </Route>
          <Route path={ROUTE.DASHBOARD_ACCOUNT_UPGRADE_PAYMENT} exact={true}>
            <UpgradePayment/>
          </Route>
          <Route path={ROUTE.DASHBOARD_ACCOUNT_UPGRADE_WAITING} exact={true}>
            <DashboardWaiting/>
          </Route>
          <Route path={ROUTE.DASHBOARD_STATEMENTS} exact={true}>
            <Statements/>
          </Route>

          <Route path={ROUTE.ROOT}>
            <Redirect to={ROUTE.DASHBOARD}/>
          </Route>

        </Switch>
      </DashboardLayout>
    )
  } else {
    return (
      <GuestLayout>
        <Switch>
          <Route path={ROUTE.LANDING} exact={true}>
            <Landing/>
          </Route>

          <Route path={ROUTE.LOGIN} exact={true}>
            <Login/>
          </Route>

          <Route path={ROUTE.SIGNUP} exact={true}>
            <Signup/>
          </Route>

          <Route path={ROUTE.STATIC_ABOUT} exact={true}>
            <About/>
          </Route>

          <Route path={ROUTE.STATIC_PRODUCT} exact={true}>
            <Product/>
          </Route>

          <Route path={ROUTE.STATIC_PRESS} exact={true}>
            <Press/>
          </Route>

          <Route path={ROUTE.STATIC_HISTORY} exact={true}>
            <History/>
          </Route>

          <Route path={ROUTE.PASSWORD_RESET} exact={true}>
            <ConfirmPassword/>
          </Route>

          <Route path={ROUTE.SIGNUP_PAYMENT} render={() => (
            !isAuth ? (
              <Redirect to={ROUTE.LOGIN}/>
            ) : userStatus === USER_STATUS.NEW ? (
              <SignupPayment/>
            ) : userStatus === USER_STATUS.WAITING_FOR_PAYMENT && (
              <SignupWaiting/>
            )
          )}/>

          <Route path={ROUTE.SIGNUP_WAITING} render={() => (
            !isAuth ? (
              <Redirect to={ROUTE.LOGIN}/>
            ) : userStatus === USER_STATUS.WAITING_FOR_PAYMENT ? (
              <SignupWaiting/>
            ) : userStatus === USER_STATUS.NEW && (
              <SignupPayment/>
            )
          )}/>

          <Route path={ROUTE.ROOT}>
            <Redirect to={ROUTE.LANDING}/>
          </Route>
        </Switch>
      </GuestLayout>
    )
  }
}