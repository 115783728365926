import React, {useEffect, useState} from "react";
import "../Auth.css";
import {Link, useHistory} from "react-router-dom";
import {notification} from "../../../components/Notification";
import {Autocomplete, Box, TextField} from "@mui/material";
import countries from "../../../constants/country";
import {makeStyles} from "@mui/styles";
import {client} from "../../../services/client";
import {jwt} from "../../../services/jwt";
import {useDispatch} from "react-redux";
import {logout, setAuth, setUser} from "../../../store/actions/actions";
import {LanguageConsumer} from "../../../components/LanguageProvider/LanguageProvider";

const useStyles = makeStyles(theme => ({
  inputRoot: {
    padding: "0px !important",
    border: "none !important",
    outline: "none !important",
    borderRadius: 1,
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none !important",
      outline: "none !important",
    },
  },
  popupIndicator: {},
  root: {
    border: "none !important",
    outline: "none !important",
  },
}));

export const Signup = () => {
  const [countryId, setCountryId] = useState(208);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [ref, setRef] = useState();
  const [pos, setPos] = useState();
  const [terms, setTerms] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();

  const [error, setError] = useState(false);
  const [errorUsername, setErrorUsername] = useState(false);
  const [errorEmail, setErrorEmail] = useState(false);

  const regexUsername = /^[0-9a-z_]+$/
  const hintUsername = "Usernames needs min 3 and max 15 characters with small letters and numbers.";
  const handleChangeCountry = (e, v) => {
    setCountryId(v.id);
  }

  const handleChangeTerms = () => {
    setTerms(!terms);
  }

  const validateUsername = async (e) => {
    const username = e.target.value;
    if (username.length < 1) {
      return;
    }
    if (!regexUsername.test(username)) {
      setErrorUsername(hintUsername);
      return;
    } else {
      setErrorUsername(false);
    }
    try {
      await client.validateUsername(username);
      setErrorUsername(false);
    } catch (error) {
      setErrorUsername(error.message);
    }
  }

  const validateEmail = async (e) => {
    const email = e.target.value;
    if (email.length < 1) {
      return;
    }
    try {
      await client.validateEmail(email);
      setErrorEmail(false);
    } catch (error) {
      setErrorEmail(error.message);
    }
  }

  useEffect(async () => {
    const _ref = new URLSearchParams(history.location.search).get('ref');
    const _pos = new URLSearchParams(history.location.search).get('pos');

    if (!_ref) {
      setError("Invite required");
      return;
    }

    if (_pos) {
      if (_pos.toUpperCase() !== "LEFT" && _pos.toUpperCase() !== "RIGHT") {
        setError("Wrong position. It must be left or right");
        return;
      }
      setPos(_pos);
    }

    try {
      await client.validateRef(_ref);
      dispatch(logout());
      setRef(_ref);
      setError(false);
    } catch (error) {
      setError(error.message);
    }


  }, []);

  const handleSignup = async (e) => {
    e.preventDefault();
    if (loading) {
      return;
    }
    setLoading(true);
    try {
      const response = await client.signup({
        countryId: countryId,
        username: e.target.username.value,
        phone: e.target.phone.value,
        phoneCode: "null",
        email: e.target.email.value,
        password: e.target.password.value,
        ref: ref,
        refPosition: pos,
        dashboardSignup: false
      });
      if (response.access_token !== '') {
        jwt.setJwtData(response.access_token, response.refresh_token);
        const user = await client.getCurrentUser();
        dispatch(setUser(user));
        dispatch(setAuth(true));

        if (user.status === "NEW") {
          history.push("/signup/payment");
        }
        if (user.status === "WAITING_FOR_PAYMENT") {
          history.push("/signup/status");
        }
        if (user.status === "MEMBER") {
          history.push("/dashboard");
        }
        setLoading(false);
      }
    } catch (error) {
      notification.warning(error.message);
      setLoading(false);
    }
  }

  return (
    <LanguageConsumer>
      {({dict}) => (
        <div className="auth-container">
          <div className="auth-content">
            <div className="caption">{dict.SIGNUP_FORM_TITLE || "Create Account"}</div>
            <div className="logo" onClick={() => history.push("/")}/>

            {error ? <div className="error">Error: {error}</div> :
              <form onSubmit={handleSignup}>
                <div className="form-item">
                  <input
                    id="username"
                    type="text"
                    required
                    autoComplete="off"
                    placeholder=" "
                    onChange={validateUsername}
                    className="guest-input"
                    pattern="^[0-9a-z_]+$"
                    minLength={3}
                    maxLength={15}
                    title="Usernames needs min 3 and max 15 characters with small letters and numbers."
                  />
                  <label htmlFor="username">{dict.SIGNUP_FORM_USERNAME || "Username"}</label>
                  <div className="input-error">{errorUsername}</div>
                </div>
                <div className="form-item">
                  <span className="country-label">{dict.SIGNUP_FORM_COUNTRY || "Country"}</span>
                  <Autocomplete
                    id="country"
                    classes={classes}
                    options={countries}
                    fullWidth
                    autoHighlight
                    getOptionLabel={(option) => option.name}
                    defaultValue={countries.find(c => c.id === countryId)}
                    onChange={handleChangeCountry}
                    renderOption={(props, option) => (
                      <Box component="li" sx={{'& > img': {mr: 2, flexShrink: 0}}} {...props}
                           style={{color: "black", background: "white"}}>
                        <img
                          loading="lazy"
                          width="20"
                          src={`https://flagcdn.com/w20/${option.iso.toLowerCase()}.png`}
                          srcSet={`https://flagcdn.com/w40/${option.iso.toLowerCase()}.png 2x`}
                          alt=""
                        />
                        {option.name}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        size="small"
                        className="guest-input"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'new-password', // disable autocomplete and autofill. Works with new-password const
                        }}
                      />
                    )}
                  />
                </div>
                <div className="form-item">
                  <input
                    id="phone"
                    type="tel"
                    required
                    autoComplete="off"
                    placeholder=" "
                    className="guest-input"
                    pattern="^[+0-9\s()]+$"
                  />
                  <label htmlFor="phone">{dict.SIGNUP_FORM_PHONE || "Mobile phone"}</label>
                </div>
                <div className="form-item">
                  <input
                    id="email"
                    type="email"
                    required
                    autoComplete="off"
                    placeholder=" "
                    onChange={validateEmail}
                    className="guest-input"
                  />
                  <label htmlFor="email">{dict.SIGNUP_FORM_EMAIL || "Email"}</label>
                  <div className="input-error">{errorEmail}</div>
                </div>
                <div className="form-item">
                  <input
                    id="password"
                    type="password"
                    required
                    autoComplete="off"
                    placeholder=" "
                    pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                    className="guest-input"
                    title="Password needs at least 1 uppercase, 1 lowercase, 1 number and 1 special char. Min 8 chars."
                  />
                  <label htmlFor="password">{dict.SIGNUP_FORM_PASSWORD || "Password"}</label>
                </div>
                <div className="form-item">
                  <input
                    id="referral"
                    type="text"
                    required
                    autoComplete="off"
                    value={ref}
                    placeholder=" "
                    className="guest-input"
                  />
                  <label htmlFor="referral">{dict.SIGNUP_FORM_REF || "Referral"}</label>
                </div>
                <div className="form-item checkbox-container">
                  <input
                    id="terms"
                    type="checkbox"
                    onChange={handleChangeTerms}
                    checked={terms}
                    required
                    className="guest-input"
                  />
                  <span className="checkbox-label">{dict.SIGNUP_FORM_AGREEMENT || "I confirm that I am over 18 years old and have read the Terms & Conditions and Privacy Policy to become a Binarytorrent customer for free."}</span>
                </div>
                <div className="form-item form-button-container">
                  {loading ? <span className="loader"/> : <button className="button-blue" type="submit">{dict.SIGNUP_FORM_SUBMIT_BUTTON || "Signup"}</button>}
                </div>
                <div className="login-link">
                  {dict.SIGNUP_FORM_HAVE_ACCOUNT || "Already have an account?"} <Link to="/login">{dict.SIGNUP_FORM_LOGIN_LINK || "Log in"}</Link>
                </div>
              </form>
            }
          </div>
        </div>
      )}
    </LanguageConsumer>
  )
}