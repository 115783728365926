import React from "react";
import {isMobile} from "../../../utils/checkDevice";
import {WalletBlocks} from "../../../components/Blocks/WalletBlocks";
import {StatMobileBlocks} from "../../../components/Blocks/StatMobileBlocks";
import {LanguageConsumer} from "../../../components/LanguageProvider/LanguageProvider";
import {OrdersTable} from "./components/OrdersTable";

export const Orders = () => {
  return (
    <LanguageConsumer>
      {({dict}) => (
        <>
          {isMobile() ? <StatMobileBlocks/> : <WalletBlocks/>}

          <div className="dashboard-blocks-container">
            <div className="dashboard-block dashboard-epin-block4">
              <div className="dashboard-table-title">{dict.ORDERS_TITLE || "Orders"}</div>
              <OrdersTable/>
            </div>
          </div>
        </>
      )}
    </LanguageConsumer>
  )
}