import React, {useEffect, useState} from 'react';

import {Cn, De, Dk, Es, Fi, Fr, Gb, Hu, In, Jp, Kr, No, Pl, Pt, Ru, Se, Tz, Vn} from 'react-flags-select';

import {LanguageConsumer} from '../LanguageProvider/LanguageProvider';
import {isMobile} from "../../utils/checkDevice";
import {Menu, MenuItem} from "@mui/material";
import {makeStyles} from "@mui/styles";
import "./LanguageSelect.css";


class LanguageSelect extends React.Component {
  handleSelect = (localeKey) => {
    this.props.getLocale({
      data: {localeKey}
    })
  };

  render() {
    return (
      <LanguageConsumer>
        {({lang, onChange}) => (
          <>
            <div onClick={this.props.handleClick} className="lang-select-button">{this.props.getFlag()}</div>
            <Menu
              id="simple-menu"
              anchorEl={this.props.anchorEl}
              keepMounted
              open={Boolean(this.props.anchorEl)}
              onClose={this.props.handleClose}
              className={this.props.classes.menu}
            >
              <MenuItem id={'GB'} onClick={(e) => {
                onChange('GB');
                this.props.handleMenuItemClick(e)
              }}>{<Gb
                className={this.props.classes.flag}/>} English
              </MenuItem>

              <MenuItem id={'ES'} onClick={(e) => {
                onChange('ES');
                this.props.handleMenuItemClick(e)
              }}>{<Es
                className={this.props.classes.flag}/>} Español
              </MenuItem>

              <MenuItem id={'PT'} onClick={(e) => {
                onChange('PT');
                this.props.handleMenuItemClick(e)
              }}>{<Pt
                className={this.props.classes.flag}/>} Português
              </MenuItem>

              <MenuItem id={'FR'} onClick={(e) => {
                onChange('FR');
                this.props.handleMenuItemClick(e)
              }}>{<Fr
                className={this.props.classes.flag}/>} Français
              </MenuItem>

              <MenuItem id={'DE'} onClick={(e) => {
                onChange('DE');
                this.props.handleMenuItemClick(e)
              }}>{<De
                className={this.props.classes.flag}/>} Deutsch
              </MenuItem>

              <MenuItem id={'RU'} onClick={(e) => {
                onChange('RU');
                this.props.handleMenuItemClick(e)
              }}>{<Ru
                className={this.props.classes.flag}/>} Русский
              </MenuItem>

              <MenuItem id={'HI'} onClick={(e) => {
                onChange('HI');
                this.props.handleMenuItemClick(e)
              }}>{<In
                className={this.props.classes.flag}/>} हिन्दी
              </MenuItem>

              <MenuItem id={'SE'} onClick={(e) => {
                onChange('SE');
                this.props.handleMenuItemClick(e)
              }}>{<Se
                className={this.props.classes.flag}/>} Svenska
              </MenuItem>

              <MenuItem id={'NO'} onClick={(e) => {
                onChange('NO');
                this.props.handleMenuItemClick(e)
              }}>{<No
                className={this.props.classes.flag}/>} Norsk
              </MenuItem>

              <MenuItem id={'DK'} onClick={(e) => {
                onChange('DK');
                this.props.handleMenuItemClick(e)
              }}>{<Dk
                className={this.props.classes.flag}/>} Dansk
              </MenuItem>

              <MenuItem id={'PL'} onClick={(e) => {
                onChange('PL');
                this.props.handleMenuItemClick(e)
              }}>{<Pl
                className={this.props.classes.flag}/>} Język polski
              </MenuItem>

              <MenuItem id={'HU'} onClick={(e) => {
                onChange('HU');
                this.props.handleMenuItemClick(e)
              }}>{<Hu
                className={this.props.classes.flag}/>} Hungarian
              </MenuItem>

              <MenuItem id={'VN'} onClick={(e) => {
                onChange('VN');
                this.props.handleMenuItemClick(e)
              }}>{<Vn
                className={this.props.classes.flag}/>} Tiếng Việt
              </MenuItem>

              <MenuItem id={'CN'} onClick={(e) => {
                onChange('CN');
                this.props.handleMenuItemClick(e)
              }}>{<Cn
                className={this.props.classes.flag}/>} 中国人
              </MenuItem>

            </Menu>
          </>
        )}
      </LanguageConsumer>
    );
  }
}

const useStyles = makeStyles((theme) => ({
  flag: {
    fontSize: '20px',
    marginRight: 10
  },
  menu: {
    "& .MuiPaper-root": {
      background: "linear-gradient(#FFFFFF 100%, #FFFFFF 49%) !important",
      color: 'black !important'
    }
  }
}));

const useStylesMobile = makeStyles((theme) => ({
  flag: {
    marginRight: '5px',
    fontSize: '20px'
  },
  menu: {
    "& .MuiPaper-root": {
      background: "linear-gradient(#019321 100%, #019321 49%)",
      color: 'white'
    }
  }
}));

export default () => {
  const classes = isMobile() ? useStylesMobile() : useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [flag, setFlag] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    if (flag === null) {
      if (localStorage.locale) {
        const _locale = JSON.parse(localStorage.locale);
        setFlag(_locale.lang)
      } else {
        setFlag('GB');
      }
    }
  })

  const handleMenuItemClick = (e) => {
    setFlag(e.currentTarget.id);
    setAnchorEl(null);
  };


  const handleClose = () => {
    setAnchorEl(null);
  };

  const getFlag = () => {
    if (flag === 'GB') {
      return <Gb/>
    } else if (flag === 'RU') {
      return <Ru/>
    } else if (flag === 'ES') {
      return <Es/>
    } else if (flag === 'SW') {
      return <Tz/>
    } else if (flag === 'FR') {
      return <Fr/>
    } else if (flag === 'VN') {
      return <Vn/>
    } else if (flag === 'SE') {
      return <Se/>
    } else if (flag === 'CN') {
      return <Cn/>
    } else if (flag === 'FI') {
      return <Fi/>
    } else if (flag === 'JP') {
      return <Jp/>
    } else if (flag === 'KR') {
      return <Kr/>
    } else if (flag === 'NO') {
      return <No/>
    } else if (flag === 'DK') {
      return <Dk/>
    } else if (flag === 'PL') {
      return <Pl/>
    } else if (flag === 'HU') {
      return <Hu/>
    } else if (flag === 'CN') {
      return <Hu/>
    } else if (flag === 'PT') {
      return <Pt/>
    } else if (flag === 'HI') {
      return <In/>
    } else if (flag === 'DE') {
      return <De/>
    }

  }
  return (

    <LanguageSelect locale={'GB'} classes={classes} handleClick={handleClick}
                    anchorEl={anchorEl}
                    handleClose={handleClose}
                    handleMenuItemClick={handleMenuItemClick}
                    getFlag={getFlag}
    />
  );
}