import React from "react";
import "./Blocks.css";
import {LanguageConsumer} from "../LanguageProvider/LanguageProvider";

export const NetworkBlocks = ({data}) => {
  return (
    <LanguageConsumer>
      {({dict}) => (
        <div className="block-container">
          <div className="block block-small block-purple1">
            <div className="block-caption">{data?.downlineImps}</div>
            <div className="block-text">{dict.NETWORK_BLOCK_DOWNLINE_IMPS || "Downline IMPs"}</div>
          </div>
          <div className="block block-small block-yellow">
            <div className="block-caption">{data?.impsLeft}</div>
            <div className="block-text">{dict.NETWORK_BLOCK_IMPS_LEFT_LEG || "IMPs left leg"}</div>
          </div>
          <div className="block block-small block-orange">
            <div className="block-caption">{data?.impsRight}</div>
            <div className="block-text">{dict.NETWORK_BLOCK_IMPS_RIGHT_LEG || "IMPs right leg"}</div>
          </div>
          <div className="block block-small block-gray">
            <div className="block-caption">{data?.whitePacks}</div>
            <div className="block-text">{dict.NETWORK_BLOCK_WHITE_PACKS || "White packs"}</div>
          </div>
          <div className="block block-small block-green2">
            <div className="block-caption">{data?.greenPacks}</div>
            <div className="block-text">{dict.NETWORK_BLOCK_GREEN_PACKS || "Green packs"}</div>
          </div>
          <div className="block block-small block-blue">
            <div className="block-caption">{data?.bluePacks}</div>
            <div className="block-text">{dict.NETWORK_BLOCK_BLUE_PACKS || "Blue packs"}</div>
          </div>
          <div className="block block-small block-red3">
            <div className="block-caption">{data?.redPacks}</div>
            <div className="block-text">{dict.NETWORK_BLOCK_RED_PACKS || "Red packs"}</div>
          </div>
          <div className="block block-small block-purple3">
            <div className="block-caption">{data?.purplePacks}</div>
            <div className="block-text">{dict.NETWORK_BLOCK_PURPLE_PACKS || "Purple packs"}</div>
          </div>
          <div className="block block-small block-black">
            <div className="block-caption">{data?.blackPacks}</div>
            <div className="block-text">{dict.NETWORK_BLOCK_BLACK_PACKS || "Black packs"}</div>
          </div>
        </div>
      )}
    </LanguageConsumer>
  )
}