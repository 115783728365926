import React, {useState} from 'react'
import classnames from 'classnames'
import {createUseStyles} from 'react-jss'
import styles from "./ConfirmationCode.styles"

const useStyles = createUseStyles(styles)

export const KEY_CODE = {
  backspace: 8,
  down: 40,
  left: 37,
  right: 39,
  up: 38,
}

export const ConfirmationCode = ({
                                   numberOfFields = 6,
                                   autoFocusIndex = 0,
                                   className,
                                   onChange,
                                   onComplete,
                                 }) => {
  const inputRefs = ['', '', '', '', '', ''].map(() => React.createRef())
  const classes = useStyles()
  const [values, setValues] = useState(Array(numberOfFields).fill(''))

  const trigger = (value) => {
    const code = value.join('')
    const isComplete = code.length === numberOfFields
    if (onChange) {
      onChange(code)
    }
    if (isComplete && onComplete) {
      onComplete(code)
    }
  }

  const _onChange = (e) => {
    const index = e.currentTarget.dataset.index ? parseInt(e.currentTarget.dataset.index, 10) : 0
    const value = e.currentTarget.value
    const next = inputRefs[index + 1]

    setValues(prevValues => {
      const newValues = [...prevValues]
      newValues[index] = value.length > 1 ? prevValues[index] : value
      trigger(newValues)
      return newValues
    })

    if (next && next.current) {
      next.current.focus()
      next.current.select()
    }
  }

  const onKeyDown = (e) => {
    const index = e.currentTarget.dataset.index ? parseInt(e.currentTarget.dataset.index, 10) : 0
    const prevIndex = index - 1
    const nextIndex = index + 1
    const prev = inputRefs[prevIndex]
    const next = inputRefs[nextIndex]

    switch (e.keyCode) {
      case KEY_CODE.backspace: {
        e.preventDefault()
        setValues(prevValues => {
          const newValues = [...prevValues]
          if (newValues[index]) {
            newValues[index] = ''
          } else if (prev && prev.current) {
            newValues[prevIndex] = ''
            prev.current.focus()
          }
          trigger(newValues)
          return newValues
        })
        break
      }
      case KEY_CODE.left: {
        e.preventDefault()
        if (prev && prev.current) {
          prev.current.focus()
        }
        break
      }
      case KEY_CODE.right: {
        e.preventDefault()
        if (next && next.current) {
          next.current.focus()
        }
        break
      }
      case KEY_CODE.up:
      case KEY_CODE.down: {
        e.preventDefault()
      }
    }
  }

  const onFocus = (e) => {
    e.currentTarget.select()
  }

  return (
    <div className={classnames(classes.codeInputForm, className)}>
      {values.map((value, index) => (
        <input
          ref={inputRefs[index]}
          key={index}
          data-index={index}
          value={value}
          placeholder=""
          autoFocus={index === autoFocusIndex}
          className={classnames(classes.inputCell)}
          onChange={_onChange}
          onFocus={onFocus}
          onKeyDown={onKeyDown}
        />
      ))}
    </div>
  )
}
