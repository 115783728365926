import React, {useEffect, useState} from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import {useSelector} from "react-redux";

ChartJS.register(ArcElement, Tooltip, Legend);


export const CountryStat = () => {
  const mapStat = useSelector(store => store.base.mapStat);
  const [labels, setLabels] = useState();
  const [amounts, setAmounts] = useState();

  useEffect(() => {
    let _labels = [];
    let _amounts = [];
    if (mapStat !== null) {
      (Object.entries(mapStat.countryTotalData)).forEach((k,v) => {
        _labels.push(k[0]);
        _amounts.push(k[1]);
      });
      setLabels(_labels);
      setAmounts(_amounts);
    }
  }, [mapStat]);

  const data = {
    labels: labels,
    datasets: [
      {
        label: '# of Votes',
        data: amounts,
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  return(
    <div>
      <Pie data={data}/>
    </div>
  );
}