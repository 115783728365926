import React, {useCallback, useEffect, useState} from "react";
import {Table, TableBody, TableCell, TableHead, TablePagination, TableRow} from "@mui/material";
import {client} from "../../../../services/client";
import {notification} from "../../../../components/Notification";
import {isMobile} from "../../../../utils/checkDevice";
import {toEur} from "../../../../utils/tools";
import {useDispatch, useSelector} from "react-redux";
import {setWallet} from "../../../../store/actions/actions";
import {LanguageConsumer} from "../../../../components/LanguageProvider/LanguageProvider";

export const PayoutTransactionsTable = () => {
  const [data, setData] = useState();
  const [currentPage, setCurrentPage] = useState(0)
  const [total, setTotal] = useState(1);
  const [updateState, setUpdateState] = useState(0);
  const wallet = useSelector(store => store.base.wallet);
  const dispatch = useDispatch();

  const [headerFields, setHeaderFields] = useState(isMobile() ? [
    {name: 'type', value: 'Status', direction: 'asc', sorted: true},
    {name: 'amount', value: 'Amount', direction: 'asc', sorted: true},
    {name: 'date', value: 'Date', direction: 'asc', sorted: true},
    {name: 'action', value: '', direction: 'asc', sorted: true},
  ] : [
    {name: 'id', value: 'ID', direction: 'asc', sorted: true},
    {name: 'type', value: 'Status', direction: 'asc', sorted: true},
    {name: 'address', value: 'Wallet address', direction: 'asc', sorted: true},
    {name: 'amount', value: 'Amount', direction: 'asc', sorted: true},
    {name: 'date', value: 'Date', direction: 'asc', sorted: true},
    {name: 'action', value: 'Action', direction: 'asc', sorted: true},
  ]);

  const onChangePage = (_, newPage) => {
    setCurrentPage(newPage)
  }

  const getData = useCallback(async () => {
    try {
      const response = await client.getAllPayouts({
        page: currentPage,
        size: 10,
      });
      setData(response.content);
      setTotal(response.totalElements)
    } catch (e) {
      notification.warning(e.message);
    }
  }, [currentPage, updateState]);

  useEffect(() => {
    getData();
  }, [currentPage, updateState])

  const renderStatus = (e) => {
    return e.status.toLowerCase();
  }

  const renderDelete = (e) => {
    return (e.status === "REQUESTED" || e.status === "CONFIRMED") &&
      <div className="delete-icon" id={e.id} onClick={handleDelete}/>
  }

  const handleDelete = async (e) => {
    try {
      const response = await client.cancelPayout(e.target.id);
      setUpdateState(updateState + 1);
      dispatch(setWallet({
        ...wallet,
        pendingPayout: wallet.pendingPayout - response.amountEur,
        cashBalance: wallet.cashBalance + response.amountEur
      }));
      notification.success("Payout canceled");
    } catch (error) {
      notification.warning(error.message);
    }
  }

  return (
    <LanguageConsumer>
      {({dict}) => (
        <>
          <Table className="table" size="small">
            <TableHead>
              {isMobile() ?
                <TableRow>
                  <TableCell key={1}>{dict.TABLE_PAYOUT_TRANSACTIONS_TYPE || "Type"}</TableCell>
                  <TableCell key={2}>{dict.TABLE_PAYOUT_TRANSACTIONS_AMOUNT || "Amount"}</TableCell>
                  <TableCell key={3}>{dict.TABLE_PAYOUT_TRANSACTIONS_DATE || "Date"}</TableCell>
                  <TableCell key={3}>{dict.TABLE_PAYOUT_TRANSACTIONS_ACTION || "Action"}</TableCell>
                </TableRow> :
                <TableRow>
                  <TableCell key={1}>{dict.TABLE_PAYOUT_TRANSACTIONS_ID || "ID"}</TableCell>
                  <TableCell key={2}>{dict.TABLE_PAYOUT_TRANSACTIONS_TYPE || "Type"}</TableCell>
                  <TableCell key={5}>{dict.TABLE_PAYOUT_TRANSACTIONS_ADDRESS || "Address"}</TableCell>
                  <TableCell key={4}>{dict.TABLE_PAYOUT_TRANSACTIONS_AMOUNT || "Amount"}</TableCell>
                  <TableCell key={5}>{dict.TABLE_PAYOUT_TRANSACTIONS_DATE || "Date"}</TableCell>
                  <TableCell key={6}>{dict.TABLE_PAYOUT_TRANSACTIONS_ACTION || "Action"}</TableCell>
                </TableRow>}
            </TableHead>
            <TableBody>
              {data && data.map((e, index) => (
                isMobile() ?
                  <TableRow hover key={index}>
                    <TableCell>{renderStatus(e)}</TableCell>
                    <TableCell>{toEur(e.amountEur)}</TableCell>
                    <TableCell>{e.createdAt}</TableCell>
                    <TableCell>{renderDelete(e)}</TableCell>
                  </TableRow> :
                  <TableRow hover key={index}>
                    <TableCell>{e.id}</TableCell>
                    <TableCell>{renderStatus(e)}</TableCell>
                    <TableCell>{e.address}</TableCell>
                    <TableCell>{toEur(e.amountEur)}</TableCell>
                    <TableCell>{e.createdAt}</TableCell>
                    <TableCell>{renderDelete(e)}</TableCell>
                  </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={total}
            rowsPerPage={10}
            rowsPerPageOptions={[]}
            page={currentPage}
            backIconButtonProps={{
              'aria-label': 'previous page',
            }}
            nextIconButtonProps={{
              'aria-label': 'next page',
            }}
            onPageChange={onChangePage}
            className="table-pagination"
          />
        </>
      )}
    </LanguageConsumer>
  );
}