import React from "react";
import "./MobileBlocks.css";
import {toEur} from "../../utils/tools";
import {useSelector} from "react-redux";
import {LanguageConsumer} from "../LanguageProvider/LanguageProvider";

export const WalletMobileBlocks = () => {
  const wallet = useSelector(store => store.base.wallet);

  return (
    <LanguageConsumer>
      {({dict}) => (
        <div className="mobile-blocks-container">
          <div className="mobile-blocks-row">
            <div className="mobile-blocks-cell">
              <div>{dict.WALLET_MOBILE_BLOCK_CASH || "Cash"}:</div>
              <div>{wallet && toEur(wallet.cashBalance)}</div>
            </div>
            <div className="mobile-blocks-cell">
              <div>{dict.WALLET_MOBILE_BLOCK_MANDATORY || "Mandatory"}:</div>
              <div>{wallet && toEur(wallet.mandatoryBalance)}</div>
            </div>
          </div>
          <div className="mobile-blocks-row">
            <div className="mobile-blocks-cell">
              <div>{dict.WALLET_MOBILE_BLOCK__LMGT || "LMGT value"}:</div>
              <div>{wallet && toEur(wallet.lmgtValue)}</div>
            </div>
            <div className="mobile-blocks-cell">
              <div>{dict.WALLET_MOBILE_BLOCK_LRT || "LRT token"}:</div>
              <div>{wallet && toEur(wallet.lrtBalance)}</div>
            </div>
          </div>
          <div className="mobile-blocks-row">
            <div className="mobile-blocks-cell">
              <div>{dict.WALLET_MOBILE_BLOCK_LRT_REWARD || "LRT reward"}:</div>
              <div>{wallet && wallet.loyaltyReward && toEur(wallet.loyaltyReward)}</div>
            </div>
            <div className="mobile-blocks-cell">
              <div>{dict.WALLET_MOBILE_BLOCK_TOTAL_EARNINGS || "Total earnings"}:</div>
              <div>{wallet && toEur(wallet.earned)}</div>
            </div>
          </div>
        </div>
      )}
    </LanguageConsumer>
  )
}