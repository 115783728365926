import React, {useEffect, useState} from "react";
import "./Sidebar.css";
import {isMobile} from "../../utils/checkDevice";
import {useHistory} from "react-router-dom";
import {ROUTE} from "../../constants/routes";
import {useDispatch, useSelector} from "react-redux";
import {setMenuId, setSubmenuId} from "../../store/actions/actions";
import {LanguageConsumer} from "../LanguageProvider/LanguageProvider";

export const Sidebar = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const submenuId = useSelector(store => store.base.submenuId);
  const menuId = useSelector(store => store.base.menuId);


  const menuItems = [
    {
      id: 1,
      route: ROUTE.DASHBOARD,
      mobile: true,
      title: "Dashboard",
      icon: "sidebar-icon icon-dashboard",
      iconActive: "sidebar-icon icon-dashboard-active",
      subMenu: [
        {id: 1, title: "Personal"},
        {id: 2, title: "Paid"},
        {id: 3, title: "Team"}
      ]
    },
    {
      id: 2,
      route: ROUTE.DASHBOARD_NETWORK,
      mobile: true,
      title: "Network",
      icon: "sidebar-icon icon-network",
      iconActive: "sidebar-icon icon-network-active",
      subMenu: [
        {id: 1, title: "Binary"},
        {id: 2, title: "Sponsor"},
        {id: 3, title: "Signup"}
      ]
    },
    {
      id: 3,
      route: ROUTE.DASHBOARD_WALLETS,
      mobile: true,
      title: "Wallets",
      icon: "sidebar-icon icon-wallet",
      iconActive: "sidebar-icon icon-wallet-active",
      subMenu: [
        {id: 1, title: "Cash"},
        {id: 5, title: "Mandatory"},
        {id: 3, title: "Pay out"},
        {id: 4, title: "LMGT value"}
      ]
    },
    {
      id: 4,
      route: ROUTE.DASHBOARD_EPIN,
      mobile: true,
      title: "E-pin",
      icon: "sidebar-icon icon-epin",
      iconActive: "sidebar-icon icon-epin-active",
      subMenu: [
        {id: 1, title: "Create"},
        {id: 2, title: "Overview"},
        {id: 3, title: "Promotion"}
      ]
    },
    {
      id: 5,
      route: ROUTE.DASHBOARD_EVENTS,
      mobile: true,
      title: "Events",
      icon: "sidebar-icon icon-events",
      iconActive: "sidebar-icon icon-events-active",
      subMenu: [
        {id: 1, title: "Events"},
        {id: 2, title: "Resources"},
        {id: 3, title: "News & Updates"},
        {id: 4, title: "Support"}
      ]
    },
    {
      id: 6,
      route: ROUTE.DASHBOARD_SIGNUP,
      mobile: false,
      title: "Signup",
      icon: "sidebar-icon icon-signup",
      iconActive: "sidebar-icon icon-signup-active",
      subMenu: [
        {id: 1, title: "Personal"},
        {id: 2, title: "Paid"},
        {id: 3, title: "Team"}
      ]
    },
  ];


  return (
    <LanguageConsumer>
      {({dict}) => (
        <>
          {isMobile() &&
          <div className="sidebar-mobile-panel">
            {menuItems && menuId && menuId > 0 && menuItems[menuId - 1].subMenu.map((item, i) => {
              return <div
                key={i}
                className={item.id === +submenuId ? "sidebar-mobile-item sidebar-mobile-item-active" : "sidebar-mobile-item"}
                onClick={() => {
                  dispatch(setSubmenuId(item.id));
                  localStorage.submenuId = item.id;
                }}
              >
                {menuId === 1 && item.id === 1 && (dict.SIDEBAR_MENU_DASHBOARD_SUBMENU_PERSONAL || item.title)}
                {menuId === 1 && item.id === 2 && (dict.SIDEBAR_MENU_DASHBOARD_SUBMENU_PAID || item.title)}
                {menuId === 1 && item.id === 3 && (dict.SIDEBAR_MENU_DASHBOARD_SUBMENU_TEAM || item.title)}
                {menuId === 2 && item.id === 1 && (dict.SIDEBAR_MENU_NETWORK_SUBMENU_BINARY || item.title)}
                {menuId === 2 && item.id === 2 && (dict.SIDEBAR_MENU_NETWORK_SUBMENU_SPONSOR || item.title)}
                {menuId === 2 && item.id === 3 && (dict.SIDEBAR_MENU_NETWORK_SUBMENU_SIGNUP || item.title)}
                {menuId === 3 && item.id === 1 && (dict.SIDEBAR_MENU_WALLETS_SUBMENU_CASH || item.title)}
                {menuId === 3 && item.id === 5 && (dict.SIDEBAR_MENU_WALLETS_SUBMENU_MANDATORY || item.title)}
                {menuId === 3 && item.id === 3 && (dict.SIDEBAR_MENU_WALLETS_SUBMENU_PAYOUT || item.title)}
                {menuId === 3 && item.id === 4 && (dict.SIDEBAR_MENU_WALLETS_SUBMENU_LMGT || item.title)}
                {menuId === 4 && item.id === 1 && (dict.SIDEBAR_MENU_EPIN_SUBMENU_CREATE || item.title)}
                {menuId === 4 && item.id === 2 && (dict.SIDEBAR_MENU_EPIN_SUBMENU_OVERVIEW || item.title)}
                {menuId === 4 && item.id === 3 && (dict.SIDEBAR_MENU_EPIN_SUBMENU_PROMO || item.title)}
                {menuId === 5 && item.id === 1 && (dict.SIDEBAR_MENU_EVENTS_SUBMENU_EVENTS || item.title)}
                {menuId === 5 && item.id === 2 && (dict.SIDEBAR_MENU_EVENTS_SUBMENU_RES || item.title)}
                {menuId === 5 && item.id === 3 && (dict.SIDEBAR_MENU_EVENTS_SUBMENU_NEWS || item.title)}
                {menuId === 5 && item.id === 4 && (dict.SIDEBAR_MENU_EVENTS_SUBMENU_SUPPORT || item.title)}
                {menuId === 6 && item.id === 1 && (dict.SIDEBAR_MENU_SIGNUP_SUBMENU_PERS || item.title)}
                {menuId === 6 && item.id === 2 && (dict.SIDEBAR_MENU_SIGNUP_SUBMENU_PAID || item.title)}
                {menuId === 6 && item.id === 3 && (dict.SIDEBAR_MENU_SIGNUP_SUBMENU_TEAM || item.title)}
              </div>
            })}
          </div>
          }
          <div className="sidebar">
            {!isMobile() &&
            <div className="sidebar-item">
              <div className="sidebar-logo"/>
            </div>
            }

            {menuItems && menuItems.map((item, i) => {
              return ((isMobile() && item.mobile === true) || (!isMobile())) &&
                <div key={i} className={item.id === +menuId ? "sidebar-item sidebar-item-active" : "sidebar-item"}
                     onClick={() => {
                       dispatch(setMenuId(item.id));
                       dispatch(setSubmenuId(1));
                       localStorage.submenuId = 1;
                       localStorage.menuId = item.id;
                       localStorage.route = item.route;
                       history.push(item.route);
                     }}>
                  <div className={item.id === +menuId ? item.iconActive : item.icon}/>
                  <div className="sidebar-item-title">
                    {item.id === 1 && (dict.SIDEBAR_MENU_DASHBOARD || item.title)}
                    {item.id === 2 && (dict.SIDEBAR_MENU_NETWORK || item.title)}
                    {item.id === 3 && (dict.SIDEBAR_MENU_WALLETS || item.title)}
                    {item.id === 4 && (dict.SIDEBAR_MENU_EPIN || item.title)}
                    {item.id === 5 && (dict.SIDEBAR_MENU_EVENTS || item.title)}
                    {item.id === 6 && (dict.SIDEBAR_MENU_SIGNUP || item.title)}
                  </div>
                </div>
            })}
          </div>
        </>
      )}
    </LanguageConsumer>
  );

}