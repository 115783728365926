import React from "react";
import {Modal} from "@mui/material";
import {setModalStates} from "../../../store/actions/actions";
import {useDispatch, useSelector} from "react-redux";
import {LanguageConsumer} from "../../LanguageProvider/LanguageProvider";

export const ModalInvite = () => {
  const dispatch = useDispatch();
  const modalStates = useSelector(store => store.base.modalStates)

  const handleCloseModal = () => {
    dispatch(setModalStates({...modalStates, modalInvite: false}));
  }

  const handleSubmitInvite = async (e) => {
    e.preventDefault();
    handleCloseModal();
  }

  const modalBodyInvite = (
    <LanguageConsumer>
      {({dict}) => (
        <div className="modal">
          <div className="modal-close-button" onClick={handleCloseModal}/>
          <div className="modal-content center">
            <div>{dict.MODAL_INVITE_CAPTION || "I want to have an invitation to join the pre-launch of Binary Torrent as a customer."}</div>

            <form onSubmit={handleSubmitInvite}>
              <div className="form-item">
                <input
                  id="email"
                  type="text"
                  required
                  autoFocus
                  autoComplete="off"
                  placeholder=" "
                />
                <label htmlFor="email">{dict.MODAL_INVITE_EMAIL || "Your email"}</label>
              </div>
              <div className="modal-button-container">
                <button className="button-blue button-large"
                        type="submit">{dict.MODAL_INVITE_BUTTON || "Send invitation"}</button>
              </div>
            </form>
          </div>
        </div>
      )}
    </LanguageConsumer>
  );

  return (
    <Modal
      open={modalStates.modalInvite}
      onClose={handleCloseModal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      {modalBodyInvite}
    </Modal>
  )
}