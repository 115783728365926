import { rem } from '../../styles/mixins'
import { colors } from '../../styles/colors'

export default {
  codeInputForm: {
    display: 'inline-flex',
    justifyContent: 'center',
  },
  inputCell: {
    '&::placeholder': {
      color: colors.primary,
    },
    '&:focus': {
      border: [1, 'solid', colors.grey50],
    },
    '&:focus::placeholder': {
      color: 'transparent',
    },
    '&:last-child': {
      marginRight: 0,
    },
    backgroundColor: colors.white,
    border: [1, 'solid', colors.grey20],
    borderRadius: 4,
    boxSizing: 'border-box',
    color: colors.primary,
    fontSize: rem(24),
    fontWeight: 300,
    height: 48,
    lineHeight: rem(33),
    marginRight: 16,
    outline: 'none',
    textAlign: 'center',
    width: 38,
  },
}
