import React, {useEffect, useState} from "react";
import {LanguageConsumer} from "../../components/LanguageProvider/LanguageProvider";
import "./Static.css";
import {StaticPageHeader} from "./components/StaticPageHeader";
import {LandingFooter} from "../../components/Footer/LandingFooter";
import {client} from "../../services/client";
import {notification} from "../../components/Notification";

export const Product = () => {
  const [packs, setPacks] = useState();

  const getPacks = async () => {
    await client.getPacks().then(setPacks).catch((error) => notification.warning(error.message));
  }

  useEffect(() => {
    getPacks();
  })
  return (
    <LanguageConsumer>
      {({dict}) => (
        <div className="static-container">
          <div className="static-page-product">

            <StaticPageHeader title={dict.PRODUCT_PAGE_TITLE} dict={dict}/>

            <div className="static-page-content-container">
              <div className="static-page-content">
                <p className="static-page-p">
                  <div className="static-page-margin">{dict.PRODUCT_PAGE_P1}</div>
                  {dict.PRODUCT_PAGE_P2}
                </p>
              </div>
            </div>

            <div className="static-page-content-container">
              <div className="static-page-content">
                <div>
                  <div className="static-page-p">
                    <div className="static-product-container static-product-container-black">
                      <div>
                        <div className="static-product-pack-title">{packs && packs[6].name}</div>
                      </div>
                      <div className="static-product-icon static-product-black-icon"/>
                      <div className="static-product-row">
                        <div>{dict.PRODUCT_PR || "Publishing rights"}:</div>
                        <div>50,000</div>
                      </div>
                      <div className="static-product-row">
                        <div>{dict.PRODUCT_EDUCATION || "Education"}</div>
                      </div>
                      <div className="static-product-row">
                        <div>{dict.PRODUCT_VIDEO_LIBRARY || "Video library"}</div>
                      </div>
                      <div className="static-product-row">
                        <div>{dict.PRODUCT_VISA_CARD || "Visa card"}</div>
                        <div className="visa-icon"/>
                      </div>
                      <div className="static-product-row">
                        <div>{dict.PRODUCT_CRYPTO_WALLET || "Crypto wallet"}</div>
                      </div>
                      <div className="static-product-row">
                        <div>{dict.PRODUCT_PRIORITY_PASS || "Priority pass"}</div>
                        <div className="priority-icon"/>
                      </div>
                      <div className="static-product-row">
                        <div>{dict.PRODUCT_IBAN_ACCOUNT || "IBAN account"}</div>
                      </div>
                      <div className="static-product-row">
                        <div>{dict.PRODUCT_FREE_TOKENS || "Free air-dropped tokens"}:</div>
                        <div>{packs && packs[6].lmgt}</div>
                      </div>
                      <div className="static-product-row">
                        <div>{dict.PRODUCT_CV || "CV"}:</div>
                        <div>4,500</div>
                      </div>
                      <div className="static-product-row">
                        <div>{dict.PRODUCT_PRICE || "PRICE"}:</div>
                        <div>€5,000</div>
                      </div>
                    </div>
                  </div>

                  <div className="static-page-p">
                    <div className="static-product-container static-product-container-purple">
                      <div>
                        <div className="static-product-pack-title">{packs && packs[5].name}</div>
                      </div>
                      <div className="static-product-icon static-product-purple-icon"/>
                      <div className="static-product-row">
                        <div>{dict.PRODUCT_PR || "Publishing rights"}:</div>
                        <div>25,000</div>
                      </div>
                      <div className="static-product-row">
                        <div>{dict.PRODUCT_EDUCATION || "Education"}</div>
                      </div>
                      <div className="static-product-row">
                        <div>{dict.PRODUCT_VIDEO_LIBRARY || "Video library"}</div>
                      </div>
                      <div className="static-product-row">
                        <div>{dict.PRODUCT_VISA_CARD || "Visa card"}</div>
                        <div className="visa-icon"/>
                      </div>
                      <div className="static-product-row">
                        <div>{dict.PRODUCT_CRYPTO_WALLET || "Crypto wallet"}</div>
                      </div>
                      <div className="static-product-row">
                        <div>{dict.PRODUCT_PRIORITY_PASS || "Priority pass"}</div>
                        <div className="priority-icon"/>
                      </div>
                      <div className="static-product-row">
                        <div>{dict.PRODUCT_FREE_TOKENS || "Free air-dropped tokens"}:</div>
                        <div>{packs && packs[5].lmgt}</div>
                      </div>
                      <div className="static-product-row">
                        <div>{dict.PRODUCT_CV || "CV"}:</div>
                        <div>2,250</div>
                      </div>
                      <div className="static-product-row">
                        <div>{dict.PRODUCT_PRICE || "PRICE"}:</div>
                        <div>€2,500</div>
                      </div>
                    </div>
                  </div>

                  <div className="static-page-p">
                    <div className="static-product-container static-product-container-red">
                      <div>
                        <div className="static-product-pack-title">{packs && packs[4].name}</div>
                      </div>
                      <div className="static-product-icon static-product-red-icon"/>
                      <div className="static-product-row">
                        <div>{dict.PRODUCT_PR || "Publishing rights"}:</div>
                        <div>12,500</div>
                      </div>
                      <div className="static-product-row">
                        <div>{dict.PRODUCT_EDUCATION || "Education"}</div>
                      </div>
                      <div className="static-product-row">
                        <div>{dict.PRODUCT_VIDEO_LIBRARY || "Video library"}</div>
                      </div>
                      <div className="static-product-row">
                        <div>{dict.PRODUCT_VISA_CARD || "Visa card"}</div>
                        <div className="visa-icon"/>
                      </div>
                      <div className="static-product-row">
                        <div>{dict.PRODUCT_CRYPTO_WALLET || "Crypto wallet"}</div>
                      </div>
                      <div className="static-product-row">
                        <div>{dict.PRODUCT_FREE_TOKENS || "Free air-dropped tokens"}:</div>
                        <div>{packs && packs[4].lmgt}</div>
                      </div>
                      <div className="static-product-row">
                        <div>{dict.PRODUCT_CV || "CV"}:</div>
                        <div>1,125</div>
                      </div>
                      <div className="static-product-row">
                        <div>{dict.PRODUCT_PRICE || "PRICE"}:</div>
                        <div>€1,250</div>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <div className="static-page-p">
                    <div className="static-product-container static-product-container-blue">
                      <div>
                        <div className="static-product-pack-title">{packs && packs[3].name}</div>
                      </div>
                      <div className="static-product-icon static-product-blue-icon"/>
                      <div className="static-product-row">
                        <div>{dict.PRODUCT_PR || "Publishing rights"}:</div>
                        <div>4,500</div>
                      </div>
                      <div className="static-product-row">
                        <div>{dict.PRODUCT_EDUCATION || "Education"}</div>
                      </div>
                      <div className="static-product-row">
                        <div>{dict.PRODUCT_VIDEO_LIBRARY || "Video library"}</div>
                      </div>
                      <div className="static-product-row">
                        <div>{dict.PRODUCT_VISA_CARD || "Visa card"}</div>
                        <div className="visa-icon"/>
                      </div>
                      <div className="static-product-row">
                        <div>{dict.PRODUCT_FREE_TOKENS || "Free air-dropped tokens"}:</div>
                        <div>{packs && packs[3].lmgt}</div>
                      </div>
                      <div className="static-product-row">
                        <div>{dict.PRODUCT_CV || "CV"}:</div>
                        <div>405</div>
                      </div>
                      <div className="static-product-row">
                        <div>{dict.PRODUCT_PRICE || "PRICE"}:</div>
                        <div>€450</div>
                      </div>
                    </div>
                  </div>

                  <div className="static-page-p">
                    <div className="static-product-container static-product-container-green">
                      <div>
                        <div className="static-product-pack-title">{packs && packs[2].name}</div>
                      </div>
                      <div className="static-product-icon static-product-green-icon"/>
                      <div className="static-product-row">
                        <div>{dict.PRODUCT_PR || "Publishing rights"}:</div>
                        <div>1,500</div>
                      </div>
                      <div className="static-product-row">
                        <div>{dict.PRODUCT_EDUCATION || "Education"}</div>
                      </div>
                      <div className="static-product-row">
                        <div>{dict.PRODUCT_FREE_TOKENS || "Free air-dropped tokens"}:</div>
                        <div>{packs && packs[2].lmgt}</div>
                      </div>
                      <div className="static-product-row">
                        <div>{dict.PRODUCT_CV || "CV"}:</div>
                        <div>135</div>
                      </div>
                      <div className="static-product-row">
                        <div>{dict.PRODUCT_PRICE || "PRICE"}:</div>
                        <div>€150</div>
                      </div>
                    </div>
                  </div>

                  <div className="static-page-p">
                    <div className="static-product-container static-product-container-white">
                      <div>
                        <div className="static-product-pack-title">{packs && packs[1].name}</div>
                      </div>
                      <div className="static-product-icon static-product-white-icon"/>
                      <div className="static-product-row">
                        <div>{dict.PRODUCT_PR || "Publishing rights"}:</div>
                        <div>500</div>
                      </div>
                      <div className="static-product-row">
                        <div>{dict.PRODUCT_EDUCATION || "Education"}</div>
                      </div>
                      <div className="static-product-row">
                        <div>{dict.PRODUCT_FREE_TOKENS || "Free air-dropped tokens"}:</div>
                        <div>{packs && packs[1].lmgt}</div>
                      </div>
                      <div className="static-product-row">
                        <div>{dict.PRODUCT_CV || "CV"}:</div>
                        <div>45</div>
                      </div>
                      <div className="static-product-row">
                        <div>{dict.PRODUCT_PRICE || "PRICE"}:</div>
                        <div>€50</div>
                      </div>
                    </div>
                  </div>
                </div>


              </div>
            </div>

            <LandingFooter dict={dict}/>

          </div>
        </div>
      )}
    </LanguageConsumer>
  )
}