import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {ROUTE} from "../../../constants/routes";
import "../Auth.css";
import {USER_STATUS} from "../../../constants/userStatuses";
import QRCode from "qrcode.react";
import {client} from "../../../services/client";
import {notification} from "../../../components/Notification";
import {ORDER_STATUS} from "../../../constants/orderStatuses";
import {setUser} from "../../../store/actions/actions";
import {LanguageConsumer} from "../../../components/LanguageProvider/LanguageProvider";

export const SignupWaiting = () => {
  const user = useSelector(store => store.base.user);
  const dispatch = useDispatch();
  const history = useHistory();
  const [order, setOrder] = useState();

  useEffect(() => {
    if (user && user.status === USER_STATUS.WAITING_FOR_PAYMENT) {
      getLastOrder();
    }
  }, [user]);

  const getLastOrder = async () => {
    try {
      const response = await client.getLastOrder();
      setOrder(response);
    } catch (error) {
      notification.warning(error.message);
    }
  }

  const resetWaitingAddress = async () => {
    await client.resetWaitingStatus()
      .then(() => {
        dispatch(setUser({...user, status: USER_STATUS.NEW}));
        history.push(ROUTE.SIGNUP_PAYMENT);
      })
      .catch((error) => notification.warning(error.message));
  }

  const handleReload = () => {
    getLastOrder();
  }

  return (
    <LanguageConsumer>
      {({dict}) => (
        <div className="auth-container">
          <div className="auth-content">
            <div className="caption">{dict.SIGNUP_WAITING_FORM_TITLE || "Thank you"}</div>
            <div className="logo" onClick={() => history.push(ROUTE.ROOT)}/>
            <div className="form-item center waiting-content">
              {dict.SIGNUP_WAITING_FORM_TEXT || "We have emailed the registered address with information on proceeding and activating the account. Customers that choose not to buy any service or product will only retain any sales volume created by other customers for this ongoing week. We will delete any sales volume after this period."}
            </div>
            {order && order.paymentMethod !== "EPIN" && order.status !== ORDER_STATUS.FREE && order.status !== ORDER_STATUS.PAID &&
            <>
              <div className="form-item center waiting-content">
                {order.paymentMethod !== "BANK" && <div className="qr-code-container">
                  <div>{dict.SIGNUP_PAYMENT_FORM_QR_TITLE1 || "Please scan this QR code and pay the amount of "} {order.cryptoAmount} {order.paymentMethod} {dict.SIGNUP_PAYMENT_FORM_QR_TITLE2 || "within 1 hour. Or copy below address and pay the amount:"}
                  </div>
                  <div>{order.cryptoAddress}</div>
                  <div><QRCode value={order.cryptoAddress}/></div>
                </div>}
              </div>
              <div className="form-item waiting-button-container">
                <button className="button-blue" onClick={handleReload}>{dict.SIGNUP_WAITING_FORM_BUTTON || "Reload"}</button>
                <button className="button-green" onClick={resetWaitingAddress}>{dict.SIGNUP_WAITING_FORM_GO_BACK || "Go back"}</button>
              </div>
            </>
            }

            {order && (order.status === ORDER_STATUS.FREE || order.status === ORDER_STATUS.PAID) &&
            <div className="form-item">
              <button className="button-blue button-large" onClick={() => {
                dispatch(setUser({...user, status: USER_STATUS.MEMBER}));
                history.push(ROUTE.DASHBOARD);
              }
              }>OK
              </button>
            </div>
            }
          </div>
        </div>
      )}
    </LanguageConsumer>
  )
}