import React, {useState} from "react";
import {Modal} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {LanguageConsumer} from "../../LanguageProvider/LanguageProvider";
import {setModalStates} from "../../../store/actions/actions";
import {toEur} from "../../../utils/tools";
import {ROUTE} from "../../../constants/routes";
import {useHistory} from "react-router-dom";
import "./ModalLRTSales.css";
import {client} from "../../../services/client";
import {notification} from "../../Notification";

export const ModalLRTSales = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const modalStates = useSelector(store => store.base.modalStates);
  const [price, setPrice] = useState(0);
  const [percentage, setPercentage] = useState(0);
  const [date, setDate] = useState(0);

  const handleCloseModal = () => {
    localStorage.setItem("showLRTSalesModal", "false");
    dispatch(setModalStates({...modalStates, modalLRTSales: false}));
    history.push(ROUTE.DASHBOARD_ACCOUNT_UPGRADE_PAYMENT + "?type=buy_pack")
  }

  useState(() => {

    client.getSettings()
      .then((r) => {
        setPrice(+r.LRT_PRICE);
        setDate(r.LRT_PRICE_INCREASE);
        setTimeout(function(){
          setPercentage(r.LRT_SOLD_PRECENT);
        }, 100);
      })
      .catch(() => notification.warning("Settings not available"))

  }, [])

  const modal = (
    <LanguageConsumer>
      {({dict}) => (
        <div className="modal">
          <div className="modal-close-button" onClick={handleCloseModal}/>
            <div className="modal-content center">
              <div>
                <div>The Loyalty Reward Token price is {price && toEur(price)}</div>
                <br/>
                <div>Price will increase {date}</div>
              </div>
              <div className="lrt-sales-container">
                <div className="lrt-sales-progress">
                  <div className="lrt-sales-progress-filled" style={{width: percentage + "%"}}/>
                </div>
                <div>
                  {percentage}% sold
                </div>
              </div>
              <div className="modal-button-container">
                <button className="button-blue lrt-sales-button" onClick={handleCloseModal}>Buy tokens at {toEur(price)}</button>
              </div>
            </div>
        </div>
      )}
    </LanguageConsumer>
  );

  return (
    <Modal
      open={modalStates.modalLRTSales}
      onClose={handleCloseModal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      {modal}
    </Modal>
  )
}