import React from "react";
import {Modal} from "@mui/material";
import {setModalStates} from "../../../store/actions/actions";
import {useDispatch, useSelector} from "react-redux";
import {notification} from "../../Notification";
import {client} from "../../../services/client";
import {LanguageConsumer} from "../../LanguageProvider/LanguageProvider";

export const ModalSendEpin = ({id}) => {
  const dispatch = useDispatch();
  const modalStates = useSelector(store => store.base.modalStates)

  const handleCloseModal = () => {
    dispatch(setModalStates({...modalStates, modalSendEpin: false}));
  }

  const sendEpin = async (e) => {
    e.preventDefault();
    const email = e.target.email.value;
    try {
      await client.sendEpin({
        id: id,
        email: email
      });
      handleCloseModal();
      notification.success("Sent")
    } catch (e) {
      notification.warning(e.message);
    }
  }

  const modalSendEpin = (
    <LanguageConsumer>
      {({dict}) => (
        <div className="modal">
          <div className="modal-close-button" onClick={handleCloseModal}/>
          <div className="modal-content center">
            <div>{dict.MODAL_SEND_EPIN_CAPTION || "Please enter the email you want to send the E-pin to."}</div>

            <form onSubmit={sendEpin}>
              <div className="form-item">
                <input
                  id="email"
                  type="text"
                  required
                  autoFocus
                  autoComplete="off"
                  placeholder=" "
                  className="white-input"
                />
                <label htmlFor="email">{dict.MODAL_SEND_EPIN_EMAIL || "Email"}</label>
              </div>
              <div className="modal-button-container">
                <button className="button-blue button-large" type="submit">{dict.MODAL_SEND_EPIN_BUTTON || "Send"}</button>
              </div>
            </form>
          </div>
        </div>
      )}
    </LanguageConsumer>
  );

  return (
    <Modal
      open={modalStates.modalSendEpin}
      onClose={handleCloseModal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      {modalSendEpin}
    </Modal>
  )
}