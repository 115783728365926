export const ROUTE = {
  ROOT: "/",
  LANDING: "/",

  LOGIN: "/login",
  SIGNUP: "/signup",
  PASSWORD_RESET: "/password-reset",
  SIGNUP_PAYMENT: "/signup/payment",
  SIGNUP_WAITING: "/signup/waiting",

  STATIC_ABOUT: "/about",
  STATIC_PRODUCT: "/product",
  STATIC_PRESS: "/press",
  STATIC_HISTORY: "/history",


  DASHBOARD: "/dashboard/main",
  DASHBOARD_NETWORK: "/dashboard/network",
  DASHBOARD_WALLETS: "/dashboard/wallets",
  DASHBOARD_ORDERS: "/dashboard/orders",
  DASHBOARD_EPIN: "/dashboard/epin",
  DASHBOARD_EVENTS: "/dashboard/events",
  DASHBOARD_SIGNUP: "/dashboard/signup",
  DASHBOARD_SIGNUP_PAYMENT: "/dashboard/signup/payment",
  DASHBOARD_SIGNUP_WAITING: "/dashboard/signup/waiting",
  DASHBOARD_ACCOUNT: "/dashboard/account",
  DASHBOARD_ACCOUNT_UPGRADE_PAYMENT: "/dashboard/account/buy",
  DASHBOARD_ACCOUNT_UPGRADE_WAITING: "/dashboard/account/buy/waiting",
  DASHBOARD_VAULT: "/dashboard/vault",

  DASHBOARD_STATEMENTS: "/dashboard/statements",
}
