import React from "react";
import {ROUTE} from "../../constants/routes";
import {useHistory} from "react-router-dom";

export const LandingFooter = ({dict}) => {
  const history = useHistory();

  return(
    <div className="landing-footer-container">
      <div className="landing-footer">
        <div onClick={() => history.push(ROUTE.STATIC_ABOUT)}>
          {dict.LANDING_MENU_ITEM1 || "About us"}
        </div>
        <div onClick={() => history.push(ROUTE.STATIC_PRODUCT)}>
          {dict.LANDING_MENU_ITEM2 || "Product"}
        </div>
        <div onClick={() => history.push(ROUTE.STATIC_PRESS)}>
          {dict.LANDING_MENU_ITEM3 || "Press"}
        </div>
        <div onClick={() => window.open("https://binarytorrent.freshdesk.com/support/home", "_blank")}>
          {dict.LANDING_MENU_ITEM4 || "Support & FAQ"}
        </div>
        <div onClick={() => history.push(ROUTE.STATIC_HISTORY)}>
          {dict.LANDING_MENU_ITEM5 || "History gold"}
        </div>
        <div onClick={() => window.open("https://laplandminerals.com", "_blank")}>
          {dict.LANDING_MENU_ITEM6 || "Lapland Minerals"}
        </div>
        <div onClick={() => window.open("https://made4share.com", "_blank")}>
          {dict.LANDING_MENU_ITEM7 || "Made4Share Publishing"}
        </div>
        <div onClick={() => window.open("https://binarytorrent.com/terms.pdf", "_blank")}>
          {dict.LANDING_MENU_ITEM8 || "Terms of Service"}
        </div>
        <div onClick={() => window.open("https://binarytorrent.com/privacy.pdf", "_blank")}>
          {dict.LANDING_MENU_ITEM9 || "Privacy Policy"}
        </div>

      </div>
    </div>
  )
}