export const API_URLS = {
  OAUTH: {
    TOKEN: 'oauth/token',
  },
  USERS: {
    CURRENT: 'users/current',
    SIGNUP: 'users',
    REQUEST_RESET_PASSWORD: 'users/password/reset',
    CONFIRM_RESET_PASSWORD: 'users/password/confirm',
    REQUEST_CHANGE_EMAIL: 'users/email/request',
    RESET_WAITING_STATUS: 'users/reset-waiting-status',
    CONFIRM_CHANGE_EMAIL: 'users/email/confirm',
    REQUEST_CHANGE_USERNAME: 'users/username/request',
    CONFIRM_CHANGE_USERNAME: 'users/username/confirm',
    UPDATE_PASSWORD: 'users/password',
    PERSONALLY_INVITED: 'users/invited',
    LATEST_TEAM_IMPS: 'users/team/latest',
    PARTNERS: 'users/partners',
    UPDATE: (id) => `users/${id}`,
    SPONSOR_SUBTREE: (id) => `users/sponsor-subtree/${id}`,
    BINARY_SUBTREE: (id) => `users/binary-subtree/${id}`,
    SEARCH_IN_BINARY_SUBTREE: (username) => `users/binary-subtree-search/${username}`,
    UP_IN_BINARY_SUBTREE: (id) => `users/binary-subtree-up/${id}`,
    VALIDATE_REF: (ref) => `users/validate/ref/${ref}`,
    VALIDATE_EMAIL: (email) => `users/validate/email/${email}`,
    VALIDATE_USERNAME: (username) => `users/validate/username/${username}`,
    CHANGE_REF_TYPE: 'users/ref-type',
    CHANGE_COUNTRY: 'users/country',
  },

  USERS_STAT: {
    CURRENT: 'users/stat/current',
    NETWORK: 'users/stat/network/current',
    MAP_STAT: 'users/stat/map',
    MONTHLY: 'users/stat/monthly'
  },

  WALLETS: {
    GET: 'wallets/user',
    REQUEST_CHANGE_WALLET: 'wallets/address/change/request',
    CONFIRM_CHANGE_WALLET: 'wallets/address/change/confirm',
  },

  SSE: {
    STREAM: 'sse/public/stream'
  },

  PACKS: {
    GET_ALL: 'packs',
    GET_TOKEN_PACKS: 'packs/token/LRT',
    GET_UPGRADE: 'packs/upgrade',
    GET_PAID: 'packs/paid',
  },

  ORDERS: {
    CREATE: 'orders',
    GET_LAST: 'orders/last',
    GET_ALL: 'orders',
    GET: (id) => `orders/${id}`,
    SUBMIT: (externalId) => `orders/${externalId}`,
  },

  COUNTRIES: {
    GET_ALL: 'countries'
  },

  EPIN: {
    CREATE: 'epins',
    DELETE: (id) => `epins/${id}`,
    GET_ALL: 'epins',
    GET_STAT: 'epins/stat',
    SEND: 'epins/send',
    CONFIRM: 'epins/confirm',
  },

  PAYOUT: {
    CREATE: 'payouts',
    CANCEL: (id) => `payouts/${id}/cancel`,
    CONFIRM: 'payouts/confirm',
    GET_ALL: 'payouts',
  },

  TRANSACTIONS: {
    GET_ALL_BY_BALANCE_TYPE: 'transactions',
  },

  STATEMENTS: {
    GET_ALL_BONUS_RUN: 'bonus-run',
    GET_LAST_BONUS_RUN: 'bonuses/bonus-run',
    GET_LAST_CV_STAT: 'cv/last-week',
  },

  TRANSLATIONS: {
    GET_LOCALE: (localeKey) => `translations/locale/${localeKey}`
  },

  NEWS: {
    GET_ALL: 'news',
  },

  EVENTS: {
    GET_ALL: 'events',
  },

  EDUCATION: {
    GET_NEXT: 'education/next',
    ACTIVATE: (id) => `education/${id}/activate`
  },

  RESOURCES: {
    GET: 'resources',
  },

  LOYALTY: {
    GET_ALL: 'loyalty/rewards',
  },

  CARDS: {
    GET_ALL: 'cards',
  },

  SETTINGS: {
    GET_ALL: 'settings',
  },
};
