import React, {useEffect, useState} from "react";
import "../../auth/Auth.css";
import {useHistory} from "react-router-dom";
import {notification} from "../../../components/Notification";
import {Autocomplete, Box, TextField} from "@mui/material";
import countries from "../../../constants/country";
import {makeStyles} from "@mui/styles";
import {client} from "../../../services/client";
import {useDispatch, useSelector} from "react-redux";
import {ROUTE} from "../../../constants/routes";
import {setMenuId, setSubmenuId} from "../../../store/actions/actions";
import {LanguageConsumer} from "../../../components/LanguageProvider/LanguageProvider";

const useStyles = makeStyles(theme => ({
  inputRoot: {
    padding: "0px !important",
    border: "none !important",
    outline: "none !important",
    borderRadius: 1,
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none !important",
      outline: "none !important",
    },
  },
  popupIndicator: {},
  root: {
    border: "none !important",
    outline: "none !important",
  },
}));

export const DashboardSignup = () => {
  const [countryId, setCountryId] = useState(208);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [terms, setTerms] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector(store => store.base.user);

  const [error, setError] = useState(false);
  const [errorUsername, setErrorUsername] = useState(false);
  const [errorEmail, setErrorEmail] = useState(false);

  const [parentId, setParentId] = useState();
  const [parentPosition, setParentPosition] = useState();

  const regexUsername = /^[0-9a-z_]+$/
  const hintUsername = "Allowed symbols: underscore, numbers, letters a-z in lower case. Min length 3, max length: 15";

  const handleChangeCountry = (e, v) => {
    setCountryId(v.id);
  }

  const handleChangeTerms = () => {
    setTerms(!terms);
  }

  const validateUsername = async (e) => {
    const username = e.target.value;
    if (username.length < 1) {
      return;
    }
    if (!regexUsername.test(username)) {
      setErrorUsername(hintUsername);
      return;
    } else {
      setErrorUsername(false);
    }
    try {
      await client.validateUsername(username);
      setErrorUsername(false);
    } catch (error) {
      setErrorUsername(error.message);
    }
  }

  const validateEmail = async (e) => {
    const email = e.target.value;
    if (email.length < 1) {
      return;
    }
    try {
      await client.validateEmail(email);
      setErrorEmail(false);
    } catch (error) {
      setErrorEmail(error.message);
    }
  }

  useEffect(async () => {
    if (user && user.hasPartPaidOrder) {
      history.push(ROUTE.DASHBOARD_ACCOUNT_UPGRADE_WAITING);
      return;
    }

    const pos = new URLSearchParams(history.location.search).get('pos');
    const parent = new URLSearchParams(history.location.search).get('parent');

    if (pos) {
      setParentPosition(pos);
    }
    if (parent) {
      setParentId(parent);
    }
  }, []);

  const handleSignup = async (e) => {
    e.preventDefault();
    if (loading) {
      return;
    }
    setLoading(true);
    try {
      const response = await client.signup({
        countryId: countryId,
        username: e.target.username.value,
        phone: e.target.phone.value,
        phoneCode: "null",
        email: e.target.email.value,
        password: e.target.password.value,
        ref: user.username,
        parentId: parentId,
        parentPosition: parentPosition,
        refPosition: parentPosition,
        dashboardSignup: true
      });
      if (response.access_token !== '') {
        setLoading(false);
        dispatch(setMenuId(6));
        dispatch(setSubmenuId(1));
        localStorage.submenuId = 1;
        localStorage.menuId = 6;
        localStorage.route = ROUTE.DASHBOARD_SIGNUP_PAYMENT;
        localStorage.dashboardUserUsername = e.target.username.value;
        localStorage.dashboardUserEmail = e.target.email.value;
        localStorage.additionalUser = [
          {username: e.target.username.value},
          {email: e.target.email.value},
        ]
        history.push(ROUTE.DASHBOARD_SIGNUP_PAYMENT);
      }
    } catch (error) {
      notification.warning(error.message);
      setLoading(false);
    }
  }

  return (
    <LanguageConsumer>
      {({dict}) => (
        <div className="dashboard-blocks-container">
          <div className="auth-content">
            <div className="caption">{dict.SIGNUP_FORM_TITLE || "Create Account"}</div>
            <div className="logo"/>

            {error ? <div className="error">Error: {error}</div> :
              <form onSubmit={handleSignup}>
                <div className="form-item">
                  <input
                    id="username"
                    type="text"
                    required
                    autoComplete="off"
                    placeholder=" "
                    onChange={validateUsername}
                    className="guest-input"
                    pattern="^[0-9a-z_]+$"
                    minLength={3}
                    maxLength={15}
                    title={hintUsername}
                  />
                  <label htmlFor="username">{dict.SIGNUP_FORM_USERNAME || "Username"}</label>
                  <div className="input-error">{errorUsername}</div>
                </div>
                <div className="form-item">
                  <span className="country-label">{dict.SIGNUP_FORM_COUNTRY || "Country"}</span>
                  <Autocomplete
                    id="country"
                    classes={classes}
                    options={countries}
                    fullWidth
                    autoHighlight
                    getOptionLabel={(option) => option.name}
                    defaultValue={countries.find(c => c.id === countryId)}
                    onChange={handleChangeCountry}
                    renderOption={(props, option) => (
                      <Box component="li" sx={{'& > img': {mr: 2, flexShrink: 0}}} {...props}
                           style={{color: "black", background: "white"}}>
                        <img
                          loading="lazy"
                          width="20"
                          src={`https://flagcdn.com/w20/${option.iso.toLowerCase()}.png`}
                          srcSet={`https://flagcdn.com/w40/${option.iso.toLowerCase()}.png 2x`}
                          alt=""
                        />
                        {option.name}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        size="small"
                        className="guest-input"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'new-password', // disable autocomplete and autofill. Works with new-password const
                        }}
                      />
                    )}
                  />
                </div>
                <div className="form-item">
                  <input
                    id="phone"
                    type="text"
                    required
                    autoComplete="off"
                    placeholder=" "
                    className="guest-input"
                  />
                  <label htmlFor="phone">{dict.SIGNUP_FORM_PHONE || "Mobile phone"}</label>
                </div>
                <div className="form-item">
                  <input
                    id="email"
                    type="email"
                    required
                    autoComplete="off"
                    placeholder=" "
                    onChange={validateEmail}
                    className="guest-input"
                  />
                  <label htmlFor="email">{dict.SIGNUP_FORM_EMAIL || "Email"}</label>
                  <div className="input-error">{errorEmail}</div>
                </div>
                <div className="form-item">
                  <input
                    id="password"
                    type="password"
                    required
                    autoComplete="off"
                    placeholder=" "
                    pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                    className="guest-input"
                  />
                  <label htmlFor="password">{dict.SIGNUP_FORM_PASSWORD || "Password"}</label>
                </div>
                <div className="form-item">
                  <input
                    id="referral"
                    type="text"
                    required
                    autoComplete="off"
                    value={user?.username}
                    placeholder=" "
                    className="guest-input"
                  />
                  <label htmlFor="referral">{dict.SIGNUP_FORM_REF || "Referral"}</label>
                </div>
                <div className="form-item checkbox-container">
                  <input
                    id="terms"
                    type="checkbox"
                    onChange={handleChangeTerms}
                    checked={terms}
                    required
                    className="guest-input"
                  />
                  <span className="checkbox-label">{dict.SIGNUP_FORM_AGREEMENT || "I confirm that I am over 18 years old and have read the Terms & Conditions and Privacy Policy to become a Binarytorrent customer for free."}</span>
                </div>
                <div className="form-item form-button-container">
                  {loading ? <span className="loader"/> : <button className="button-blue" type="submit">{dict.SIGNUP_FORM_SUBMIT_BUTTON || "Signup"}</button>}
                </div>
              </form>
            }
          </div>
        </div>
      )}
    </LanguageConsumer>
  )
}