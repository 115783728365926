import React from "react";
import "./MobileBlocks.css";
import {LanguageConsumer} from "../LanguageProvider/LanguageProvider";

export const NetworkMobileBlocks = ({data}) => {
  return (
    <LanguageConsumer>
      {({dict}) => (
        <div className="mobile-blocks-container">
          <div className="mobile-blocks-row">
            <div className="mobile-blocks-cell">
              <div>{dict.NETWORK_BLOCK_MOBILE_IMPS_LEFT || "IMPs left"}:</div>
              <div>{data?.impsLeft}</div>
            </div>
            <div className="mobile-blocks-cell">
              <div>{dict.NETWORK_BLOCK_MOBILE_IMPS_RIGHT || "IMPs right"}:</div>
              <div>{data?.impsRight}</div>
            </div>
          </div>
          <div className="mobile-blocks-row">
            <div className="mobile-blocks-cell mobile-blocks-cell-full-width">
              <div>{dict.NETWORK_BLOCK_MOBILE_W || "W"}:{data?.whitePacks}</div>
              <div>{dict.NETWORK_BLOCK_MOBILE_G || "G"}:{data?.greenPacks}</div>
              <div>{dict.NETWORK_BLOCK_MOBILE_B || "B"}:{data?.bluePacks}</div>
              <div>{dict.NETWORK_BLOCK_MOBILE_R || "R"}:{data?.redPacks}</div>
              <div>{dict.NETWORK_BLOCK_MOBILE_P || "P"}:{data?.purplePacks}</div>
              <div>{dict.NETWORK_BLOCK_MOBILE_B || "B"}:{data?.blackPacks}</div>
            </div>
          </div>
        </div>
      )}
    </LanguageConsumer>
  )
}