import React, {useCallback, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {client} from "../../../services/client";
import {setMenuId, setSubmenuId, setUser, setUserStat, setWallet} from "../../../store/actions/actions";
import "./DashboardLayout.css";
import {Sidebar} from "../../Sidebar/Sidebar";
import {TopPanel} from "../../TopPanel/TopPanel";
import {isMobile} from "../../../utils/checkDevice";
import {TopPanelMobile} from "../../TopPanel/TopPanelMobile";

export const DashboardLayout = ({children}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector(store => store.base.user);
  const userStat = useSelector(store => store.base.userStat);
  const wallet = useSelector(store => store.base.wallet);

  const fetchCurrentUser = useCallback(async () => {
    const isAuth = !!localStorage.accessToken && !!localStorage.refreshToken;
    if (user === null && isAuth) {
      const user = await client.getCurrentUser();
      dispatch(setUser(user));
    }
  }, [user, history]);

  const fetchUserWallet = useCallback(async () => {
    if (wallet === null) {
      const response = await client.getWallet();
      dispatch(setWallet(response));
    }
  }, [wallet, history]);

  const fetchUserStat = useCallback(async () => {
    if (userStat === null) {
      const response = await client.getCurrentUserStat();
      dispatch(setUserStat(response));
    }
  }, [userStat, history]);

  useEffect(() => {
    fetchCurrentUser();
    fetchUserWallet();
    if (localStorage.menuId !== undefined) {
      if (localStorage.menuId !== 1) {
        dispatch(setMenuId(localStorage.menuId));
      }
    } else {
      localStorage.menuId = 1;
    }
    if (localStorage.submenuId !== undefined) {
      if (localStorage.submenuId !== 1) {
        dispatch(setSubmenuId(localStorage.submenuId));
      }
    } else {
      localStorage.submenuId = 1;
    }
    if (localStorage.route !== undefined) {
      history.push(localStorage.route);
    }
  }, []);

  useEffect(() => {
    if (user !== null) {
      fetchUserStat();
    }
  }, [user]);

  return (
    <>
      <div className="container dashboard-layout">
        <Sidebar/>
        {isMobile() ? <TopPanelMobile/> : <TopPanel/>}
        <div className="dashboard-content">
          {children}
        </div>
      </div>
    </>
  )
}