import React, {useEffect, useState} from "react";
import {notification} from "../../../components/Notification";
import {client} from "../../../services/client";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {toEur} from "../../../utils/tools";
import QRCode from "qrcode.react";
import {ORDER_STATUS} from "../../../constants/orderStatuses";
import {setUserStat, setWallet} from "../../../store/actions/actions";
import {ROUTE} from "../../../constants/routes";
import {WalletMobileBlocks} from "../../../components/Blocks/WalletMobileBlocks";
import {WalletBlocks} from "../../../components/Blocks/WalletBlocks";
import {isMobile} from "../../../utils/checkDevice";
import "../../auth/Auth.css";
import {LanguageConsumer} from "../../../components/LanguageProvider/LanguageProvider";

export const UpgradePayment = () => {
  const user = useSelector(store => store.base.user);
  const dispatch = useDispatch();
  const [packs, setPacks] = useState(null);
  const [selectedPack, setSelectedPack] = useState();
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState();
  const [tokenPacks, setTokenPacks] = useState(null);
  const [cards, setCards] = useState(null);
  const [selectedTokenPack, setSelectedTokenPack] = useState(null);
  const [selectedCard, setSelectedCard] = useState(null);
  const [totalSum, setTotalSum] = useState(0);
  const [epin, setEpin] = useState();
  const [epinSelected, setEpinSelected] = useState(true);
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const paymentMethods = [
    {id: 1, code: "EPIN", name: "E-pin"},
    {id: 2, code: "BTC", name: "Bitcoin"},
    // {id: 3, code: "ETH", name: "Ethereum"},
    // {id: 4, code: "USDT (ERC-20)", name: "USDT (ERC-20)"},
    // {id: 5, code: "USDT (BEP-20)", name: "USDT (BEP-20)"},
    // {id: 6, code: "BNB", name: "BNB"},
    {id: 7, code: "BANK", name: "Bank transfer"},
    {id: 10, code: "CASH", name: "Cash wallet"},
  ];


  useEffect(async () => {
    if (user) {
      if (user.hasPartPaidOrder) {
        history.push(ROUTE.DASHBOARD_ACCOUNT_UPGRADE_WAITING);
        return;
      }
    }

    await client.getPacks()
      .then((r) => {
        r.shift();
        r.unshift({
          id: 0,
          name: "No pack",
          signupPrice: 0
        })
        setPacks(r);
        setSelectedPack(r[0]);
        setSelectedPaymentMethod(paymentMethods[0]);
      })
      .catch((error) => notification.warning(error.message));

    await client.getTokenPacks()
      .then((r) => {
        r.unshift({
          id: 0,
          amount: 0,
          price: 0
        })
        setTokenPacks(r);
        setSelectedTokenPack(r[0]);
      })
      .catch((error) => notification.warning(error.message));

    await client.getCards()
      .then((r) => {
        r.unshift({
          id: 0,
          name: "No card",
          description: "No card",
          price: 0
        })
        setCards(r);
        setSelectedCard(r[0]);
      })
      .catch((error) => notification.warning(error.message));

  }, []);

  const handleChangePaymentMethod = (e) => {
    const id = parseInt(e.target.value);
    const pm = paymentMethods.filter(e => e.id === id)[0];
    if (e.target.value === 8) {
      window.open("https://rebatas.eu/", "_blank");
      setSelectedPaymentMethod(paymentMethods[0]);
    }
    setSelectedPaymentMethod(pm);
  }

  const handleChangeEpin = (e) => {
    setEpin(e.target.value);
  }

  useEffect(() => {
    if (selectedPaymentMethod) {
      //Epin?
      if (selectedPaymentMethod.id === 1) {
        setEpinSelected(true);
        setOrder(null);
      } else {
        setEpinSelected(false);
        if ((totalSum > 0) && selectedPaymentMethod.code !== "CASH" && selectedPaymentMethod.code !== "BANK") {
          createOrder();
        }
      }
      if (selectedPaymentMethod.code === "BANK") {
        setOrder(null);
      }
    }
  }, [selectedPaymentMethod]);

  const handleChangePack = (e) => {
    setSelectedPack(packs.filter(pack => +pack.id === +e.target.value)[0]);
  }

  useEffect(() => {
    if (selectedPack && selectedCard && selectedTokenPack) {
      let s = selectedPack.signupPrice + selectedTokenPack.price + selectedCard.price;
      if ((selectedPack.id === 3 && user.userPack.pack.id === 3) && selectedCard.id > 0) {
        // s -= 100;
      }
      if (selectedPack.id === 1) {
        s -= selectedTokenPack.price;
        setSelectedTokenPack(tokenPacks[0]);
      }
      setTotalSum(s);

    }
  }, [selectedPack, selectedCard, selectedTokenPack])

  useEffect(() => {
    //Free pack?
    if (totalSum === 0) {
      setOrder(null);
      setEpinSelected(false);
    } else {
      //Not epin and not cash?
      if (selectedPaymentMethod && selectedPaymentMethod.id !== 1 && selectedPaymentMethod.code !== "BANK" && selectedPaymentMethod.code !== "CASH") {
        createOrder();
      } else if (selectedPaymentMethod && selectedPaymentMethod.code === "EPIN") {
        setEpinSelected(true);
      }
    }
  }, [totalSum]);

  const handleChangeTokenPack = (e) => {
    if (selectedPack.id === 0 && user.userPack.pack.id === 1) {
      notification.info("LRT tokens not available for Free membership. Please choose Basic or Premium.")
    } else {
      setSelectedTokenPack(tokenPacks[e.target.value]);
    }
  }

  const createOrder = async () => {
    if (loading) {
      return;
    }
    if (selectedPaymentMethod.code === "BEEKASH") {
      return;
    }
    setLoading(true);
    try {
      const response = await client.createOrder({
        packId: selectedPack.id === 0 ? null : selectedPack.id,
        type: "BUY_PACK",
        paymentMethod: selectedPaymentMethod.code,
        epin: epin,
        tokenPackId: selectedTokenPack.id === 0 ? null : selectedTokenPack.id,
        cardId: selectedCard.id === 0 ? null : selectedCard.id
      });
      setOrder(response);

      switch (response.status) {
        case ORDER_STATUS.PENDING: {
          notification.info("We have emailed the registered address with information on your order. " +
            "If you choose not to pay the order, you might lose your qualification.");
          localStorage.route = ROUTE.DASHBOARD_ACCOUNT;
          history.push(ROUTE.DASHBOARD_ACCOUNT);
          break;
        }
        case ORDER_STATUS.PAID: {
          notification.success("Successful payment");
          localStorage.route = ROUTE.DASHBOARD_ACCOUNT;
          await client.getWallet().then(response => dispatch(setWallet(response)));
          await client.getCurrentUserStat().then(response => dispatch(setUserStat(response)));
          history.push(ROUTE.DASHBOARD_ACCOUNT);
          break;
        }
      }

    } catch (error) {
      notification.warning(error.message);
    }
    setLoading(false);
  }

  const renderCards = () => {
    return cards.map((c, i) => {
      return <option value={c.id} key={c.id} id={c.id}>{c.description}, {toEur(c.price)}</option>
    });
  }

  const renderTokenPacks = () => {
    return tokenPacks.map((c, i) => {
      if (c.id === 0) {
        return <option value={c.id} key={c.id} id={c.id}>No tokens, {toEur(0)}</option>
      } else {
        return <option value={c.id} key={c.id} id={c.id}>{c.amount} LRTs, {toEur(c.price)}</option>
      }
    });
  }

  const handleChangeCard = (e) => {
    setSelectedCard(cards[e.target.value]);
  }

  const submitOrder = async (e) => {
    e.preventDefault();
    if (loading) {
      return;
    }
    if (selectedPaymentMethod.code === "BEEKASH") {
      return;
    }
    setLoading(true);
    if (selectedPaymentMethod.code === "EPIN" || selectedPaymentMethod.code === "CASH" || selectedPaymentMethod.code === "BANK" || totalSum === 0) {
      createOrder();
    } else {
      try {
        await client.submitOrder(order.externalId);
        history.push(ROUTE.DASHBOARD_ACCOUNT);
      } catch (error) {
        notification.warning(error.message);
      }
    }
    setLoading(false);
  }

  const renderCaption = (dict) => {
    return dict.UPGRADE_FORM_TITLE_BUY || "Buy";
  }

  return (
    <LanguageConsumer>
      {({dict}) => (
        <>
          {isMobile() ? <WalletMobileBlocks/> : <WalletBlocks/>}
          <div className="dashboard-blocks-container">
            <div className="auth-content dashboard-mobile-colors">
              <div className="caption">
                {renderCaption(dict)}
              </div>

              <form onSubmit={submitOrder} className="upgrade-form">
                <div>{dict.SIGNUP_PAYMENT_FORM_SELECT_TITLE || "Select product package"}</div>

                <div className="selected-pack-container">
                  {selectedPack && <>
                    <img src={selectedPack.logo}/>
                    <div>{selectedPack.name}</div>
                    <div>{toEur(totalSum)}</div>
                  </>}
                </div>

                <div className="form-item">
                  <div className="input-label">Package</div>
                  <select onChange={handleChangePack} value={selectedPack?.id}>
                    {packs && packs.map((c, i) => {
                      return <option value={c.id} key={c.id}
                                     id={c.id}>{c.name + ", " + c.signupPrice}
                      </option>
                    })}
                  </select>
                </div>

                <div className="form-item">
                  <div className="input-label">{dict.SIGNUP_PAYMENT_FORM_CARD || "VISA Card"}</div>
                  <select onChange={handleChangeCard} value={selectedCard?.id} className="guest-input">
                    {cards && renderCards()}
                    })}
                  </select>
                </div>

                <div className="form-item">
                  <div className="input-label">{dict.SIGNUP_PAYMENT_FORM_LRT || "Loyalty Reward Token"}</div>
                  <select onChange={handleChangeTokenPack} value={selectedTokenPack?.id} className="guest-input">
                    {tokenPacks && renderTokenPacks()}
                    })}
                  </select>
                </div>

                <div className={totalSum > 0 ? "form-item" : "form-item hidden"}>
                  <div className="input-label">Choose your payment method</div>
                  <select onChange={handleChangePaymentMethod}>
                    {paymentMethods.map((c, i) => {
                      return <option value={c.id} key={c.id} id={c.id}>{c.name}</option>
                    })}
                  </select>
                </div>

                <div
                  className={epinSelected && totalSum > 0 ? "form-item" : "form-item hidden"}>
                  <div className="input-label">Type e-pin</div>
                  <input
                    id="epin"
                    name="epin"
                    type="text"
                    required={epinSelected}
                    value={epin}
                    onChange={handleChangeEpin}
                    autoComplete="off"
                    placeholder="E-pin"
                  />
                </div>

                <div className="form-item">
                  {order && (
                    order.paymentMethod === "EPIN" || order.paymentMethod === "CASH" || order.paymentMethod === "BANK" ?
                      <div/> :
                      selectedPaymentMethod?.code !== "CASH" && selectedPaymentMethod?.code !== "BANK" &&
                      <div className="qr-code-container">
                        <div>Please scan this QR code and pay the amount
                          of {order.cryptoAmount} {order.paymentMethod} within 1
                          hour. Or copy below address and pay the amount:
                        </div>
                        <div>{order.cryptoAddress}</div>
                        <div><QRCode value={order.cryptoAddress}/></div>
                      </div>)}
                </div>

                <div className="form-item form-button-container">
                  {loading ? <span className="loader"/> :
                    totalSum > 0 ? <button className="button-green button-large" type="submit">Submit</button> : ""}
                </div>
              </form>

            </div>
          </div>
        </>
      )}
    </LanguageConsumer>
  )
}