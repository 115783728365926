import {
  LOG_OUT,
  SET_AUTH,
  SET_USER,
  SET_WALLET,
  SET_MODAL_STATES,
  SET_SUBMENU_ID,
  SET_MENU_ID,
  SET_USER_STAT,
  SET_MAP_STAT
} from './actionTypes';
import {createAction} from 'redux-actions'
import {jwt} from '../../services/jwt'

const actionList = {
  logout: createAction(LOG_OUT),
  setAuth: createAction(SET_AUTH),
  setUser: createAction(SET_USER),
  setWallet: createAction(SET_WALLET),
  setModalStates: createAction(SET_MODAL_STATES),
  setSubmenuId: createAction(SET_SUBMENU_ID),
  setMenuId: createAction(SET_MENU_ID),
  setUserStat: createAction(SET_USER_STAT),
  setMapStat: createAction(SET_MAP_STAT)
}

export const logout = () => {
  localStorage.clear()
  sessionStorage.clear();
  jwt.destroy()
  return actionList.logout()
}

export const setAuth = (isAuth) => {
  return actionList.setAuth(isAuth)
}

export const setUser = (user) => {
  return actionList.setUser(user)
}

export const setWallet = (wallet) => {
  return actionList.setWallet(wallet)
}

export const setModalStates = (modalStates) => {
  return actionList.setModalStates(modalStates);
}

export const setSubmenuId = (submenuId) => {
  return actionList.setSubmenuId(submenuId);
}

export const setMenuId = (menuId) => {
  return actionList.setMenuId(menuId);
}

export const setUserStat = (userStat) => {
  return actionList.setUserStat(userStat);
}

export const setMapStat = (mapStat) => {
  return actionList.setMapStat(mapStat);
}