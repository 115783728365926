import React, {useEffect, useState} from "react";
import "./EventCalendar.css";
import {client} from "../../../../../services/client";
import {notification} from "../../../../../components/Notification";
import {isMobile} from "../../../../../utils/checkDevice";

export const EventCalendar = () => {
  const [events, setEvents] = useState();
  const [data, setData] = useState();
  const [activeTab, setActiveTab] = useState("w");

  useEffect(() => {
    try {
      client.getAllEvents().then(setEvents);
    } catch (error) {
      notification.warning(error.message);
    }
  }, [])

  useEffect(() => {

    if (events) {

      let Cal = function (divId) {
        this.divId = divId;
        this.DaysOfWeek = [
          'Mon',
          'Tue',
          'Wed',
          'Thu',
          'Fri',
          'Sat',
          'Sun'
        ];
        this.Months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

        let d = new Date();
        this.currMonth = d.getMonth();
        this.currYear = d.getFullYear();
        this.currDay = d.getDate();
      };

// Переход к следующему месяцу
      Cal.prototype.nextMonth = function () {
        if (this.currMonth === 11) {
          this.currMonth = 0;
          this.currYear = this.currYear + 1;
        } else {
          this.currMonth = this.currMonth + 1;
        }
        this.showcurr();
      };

// Переход к предыдущему месяцу
      Cal.prototype.previousMonth = function () {
        if (this.currMonth === 0) {
          this.currMonth = 11;
          this.currYear = this.currYear - 1;
        } else {
          this.currMonth = this.currMonth - 1;
        }
        this.showcurr();
      };

// Показать текущий месяц
      Cal.prototype.showcurr = function () {
        this.showMonth(this.currYear, this.currMonth);
      };


// Показать месяц (год, месяц)
      Cal.prototype.showMonth = function (y, m) {

        let d = new Date()
          // Первый день недели в выбранном месяце
          , firstDayOfMonth = new Date(y, m, 7).getDay()
          // Последний день выбранного месяца
          , lastDateOfMonth = new Date(y, m + 1, 0).getDate()
          // Последний день предыдущего месяца
          , lastDayOfLastMonth = m === 0 ? new Date(y - 1, 11, 0).getDate() : new Date(y, m, 0).getDate();


        let html = '<table class="calendar-table">';

        // Запись выбранного месяца и года
        html += '<thead><tr>';
        html += '<td colspan="7">' + this.Months[m] + ' ' + y + '</td>';
        html += '</tr></thead>';


        // заголовок дней недели
        html += '<tr class="days">';
        for (let i = 0; i < this.DaysOfWeek.length; i++) {
          html += '<td>' + this.DaysOfWeek[i] + '</td>';
        }
        html += '</tr>';

        // Записываем дни
        let i = 1;
        do {

          let dow = new Date(y, m, i).getDay();

          // Начать новую строку в понедельник
          if (dow === 1) {
            html += '<tr>';
          }

          // Если первый день недели не понедельник показать последние дни предидущего месяца
          else if (i === 1) {
            html += '<tr>';
            let k = lastDayOfLastMonth - firstDayOfMonth + 1;
            for (let j = 0; j < firstDayOfMonth; j++) {
              html += '<td class="not-current">' + k + '</td>';
              k++;
            }
          }

          // Записываем текущий день в цикл
          const chk = new Date();
          const chkY = chk.getFullYear();
          const chkM = chk.getMonth();

          let _m = this.currMonth + 1;
          if (+_m < 10) _m = "0" + _m;

          let _d = i;
          // if (+_d < 10) _d = "0" + _d;

          const c = this.currYear + "-" + _m + "-" + i;

          let _class = "";
          if (events && events.find((e) => e.date === c && e.type === "WEBINAR") !== undefined) {
            _class = " calendar-webinar ";
          }

          if (events && events.find((e) => e.date === c && e.type === "MEETING") !== undefined) {
            _class = _class + "calendar-meeting";
          }

          if (chkY === this.currYear && chkM === this.currMonth && i === this.currDay) {
            _class = _class + " today"
          }
          html += '<td id="' + this.currYear + '-' + _m + '-' + _d + '" class="' + _class + '">' + i + '</td>';

          // закрыть строку в воскресенье
          if (dow === 0) {
            html += '</tr>';
          }
          // Если последний день месяца не воскресенье, показать первые дни следующего месяца
          else if (i === lastDateOfMonth) {
            let k = 1;
            for (dow; dow < 7; dow++) {
              html += '<td class="not-current">' + k + '</td>';
              k++;
            }
          }

          i++;
        } while (i <= lastDateOfMonth);

        html += '</table>';

        document.getElementById(this.divId).innerHTML = html;
      };

      // init calendar
      const c = new Cal("calendar");
      c.showcurr();

      document.getElementById('btnNext').onclick = function () {
        c.nextMonth();
        console.log(events)
      };
      document.getElementById('btnPrev').onclick = function () {
        c.previousMonth();
      };


      let webinars = document.getElementsByClassName("calendar-webinar");
      for (let i = 0, length = webinars.length; i < length; i++) {
        const e = webinars[i];
        e.onclick = function () {
          console.log("click w")
          const _data = events.filter((el) => el.date === e.id);
          const _w = _data.filter((el) => el.type === "WEBINAR")
          const _m = _data.filter((el) => el.type === "MEETING")
          setData({
            webinars: _w,
            meetings: _m
          });
        }
      }
      let meetings = document.getElementsByClassName("calendar-meeting");
      for (let i = 0, length = meetings.length; i < length; i++) {
        const e = meetings[i];
        e.onclick = function () {
          console.log("click m")
          const _data = events.filter((el) => el.date === e.id);
          const _w = _data.filter((el) => el.type === "WEBINAR")
          const _m = _data.filter((el) => el.type === "MEETING")
          setData({
            webinars: _w,
            meetings: _m
          });
        }
      }
    }


  }, [events]);


  const renderWebinar = () => {
    return data.webinars.map((el, i) => {
      const exp = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
      const content = el.content.replace(exp, "<a href='$1' target='_blank'>$1</a>");
      return <div key={i} dangerouslySetInnerHTML={{__html: content}}/>
    })
  }

  const renderMeeting = () => {
    return data.meetings.map((el, i) => {
      const exp = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
      const content = el.content.replace(exp, "<a href='$1' target='_blank'>$1</a>");
      return <div key={i} dangerouslySetInnerHTML={{__html: content}}/>
    })
  }

  const renderContent = () => {
    if (data && activeTab) {
      if (activeTab === "w" && data.webinars.length > 0) {
        return renderWebinar();
      } else if (activeTab === "w" && data.webinars.length === 0) {
        return renderMeeting();
      } else if (activeTab === "m" && data.meetings.length > 0) {
        return renderMeeting();
      } else if (activeTab === "m" && data.meetings.length === 0) {
        return renderWebinar();
      }
    }
  }

  return (
    <div className="dashboard-block event-calendar-container">
      Event Calendar

      <div className="event-calendar-wrapper">
        <div className="calendar-wrapper">
          <div id="btnPrev">{"<"}</div>
          <div id="btnNext">{">"}</div>
          <div id="calendar"/>
        </div>

        {!isMobile() && <div className="event-calendar-separator"/>}

        <div className="event-content-wrapper">
          <div className="event-tabs">
            <div className="event-tabs-item" onClick={() => setActiveTab("w")}>
              <div className="event-icon event-webinars-icon"/>
              <div>Webinars</div>
            </div>
            <div className="event-tabs-item" onClick={() => setActiveTab("m")}>
              <div className="event-icon event-meetings-icon"/>
              <div>Meetings</div>
            </div>
          </div>

          <div className="event-content">
            {renderContent()}
          </div>
        </div>

      </div>
    </div>
  )
}