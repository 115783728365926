import React, {useState} from "react";
import {Modal} from "@mui/material";
import {setModalStates, setUser} from "../../../store/actions/actions";
import {useDispatch, useSelector} from "react-redux";
import {notification} from "../../Notification";
import {client} from "../../../services/client";
import {LanguageConsumer} from "../../LanguageProvider/LanguageProvider";
import "./ModalEducationTime.css";

export const ModalEducationTime = ({data}) => {
  const dispatch = useDispatch();
  const modalStates = useSelector(store => store.base.modalStates);
  const user = useSelector(store => store.base.user);
  const [link, setLink] = useState();

  const handleCloseModal = () => {
    dispatch(setModalStates({...modalStates, modalEducationTime: false}));
  }

  const activate = async (e) => {
    e.preventDefault();
    if (!data) {
      return;
    }
    try {
      await client.activateEducationTime(data.id).then((r) => {
        window.open(r.link);
      });
      notification.success("Link activated")
    } catch (e) {
      notification.warning(e.message);
    }
  }

  const renderButton = (dict) => {
    if (data.status === "INACTIVE") {
      return <button
        className="button-grey education-time-button">{dict.MODAL_EDUCATION_BUTTON_INACTIVE || "Inactive"}</button>
    }
    if (data.status === "ACTIVE") {
      return <button onClick={activate}
                     className="button-purple education-time-button">{dict.MODAL_EDUCATION_BUTTON_LIVE || "Now live"}</button>
    }
    if (data.status === "EXPIRED") {
      return <button
        className="button-grey education-time-button">{dict.MODAL_EDUCATION_BUTTON_EXPIRED || "Expired"}</button>
    }
  }

  const modal = (
    <LanguageConsumer>
      {({dict}) => (
        <div className="modal">
          <div className="modal-close-button" onClick={handleCloseModal}/>
          <div className="modal-content center">
            {data ? <div>
              <div>
                {(dict.MODAL_EDUCATION_TITLE && dict.MODAL_EDUCATION_TITLE.replace("%TIME%", data.timeAlloc)) || "Live streaming education will appear here when scheduled. You will use %TIME% minutes of your educational time if you click an active link. All times are in UTC.".replace("%TIME%", data.timeAlloc)}
              </div>

              <div className="modal-education-time-table">
                <div className="modal-education-time-table-row">
                  <div className="modal-education-time-table-cell">
                    {dict.MODAL_EDUCATION_TABLE_TITLE || "Education title"}
                  </div>
                  <div className="modal-education-time-table-cell">
                    {dict.MODAL_EDUCATION_TABLE_DATE || "Date"}
                  </div>
                  <div className="modal-education-time-table-cell">
                    {dict.MODAL_EDUCATION_TABLE_START || "Start (UTC)"}
                  </div>
                </div>

                <div className="modal-education-time-table-row">
                  <div className="modal-education-time-table-cell">{data.title}</div>
                  <div className="modal-education-time-table-cell">{data.date}</div>
                  <div className="modal-education-time-table-cell">{data.time}</div>
                </div>

                <div className="modal-education-time-button-container">
                  {renderButton(dict)}
                  <div className="modal-activate-vault-link" onClick={handleCloseModal}>Cancel and take me back!</div>
                </div>
              </div>
            </div> : dict.MODAL_EDUCATION_NO_DATA || "No data"}
          </div>
        </div>
      )}
    </LanguageConsumer>
  );

  return (
    <Modal
      open={modalStates.modalEducationTime}
      onClose={handleCloseModal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      {modal}
    </Modal>
  )
}