import {handleActions} from 'redux-actions'
import {LOG_OUT, SET_AUTH, SET_USER, SET_WALLET, SET_MODAL_STATES, SET_SUBMENU_ID, SET_MENU_ID, SET_USER_STAT, SET_MAP_STAT} from '../actions/actionTypes'

const initialState = {
  isAuth: false,
  user: null,
  wallet: null,
  modalStates: {
    "modalInvite": false,
    "modalResetPassword": false,
    "modalSendEpin": false,
    "modalSecurityCode": false,
    "modalChangeEmail": false,
    "modalChangeUsername": false,
    "modalChangeWallet": false,
    "modalEducationTime": false,
    "modalActivationExpired": false,
    "modalLRTSales": false,
  },
  submenuId: 1,
  menuId: 1,
  userStat: null,
  mapStat: null
}

export const baseReducer = handleActions(
  {
    SET_AUTH: (state, {payload}) => {
      return {
        ...state,
        isAuth: payload,
      }
    },
    LOG_OUT: () => initialState,
    SET_USER: (state, {payload}) => ({
      ...state,
      user: payload,
    }),
    SET_WALLET: (state, {payload}) => ({
      ...state,
      wallet: payload,
    }),
    SET_MODAL_STATES: (state, {payload}) => ({
      ...state,
      modalStates: payload,
    }),
    SET_SUBMENU_ID: (state, {payload}) => ({
      ...state,
      submenuId: payload,
    }),
    SET_MENU_ID: (state, {payload}) => ({
      ...state,
      menuId: payload,
    }),
    SET_USER_STAT: (state, {payload}) => ({
      ...state,
      userStat: payload,
    }),
    SET_MAP_STAT: (state, {payload}) => ({
      ...state,
      mapStat: payload,
    })
  },
  initialState,
)
