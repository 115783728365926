import React, {useCallback, useEffect, useState} from "react";
import {Table, TableBody, TableCell, TableHead, TablePagination, TableRow} from "@mui/material";
import {client} from "../../../../services/client";
import {notification} from "../../../../components/Notification";
import {isMobile} from "../../../../utils/checkDevice";
import {toEur} from "../../../../utils/tools";
import "../../Dashboard.css";
import {LanguageConsumer} from "../../../../components/LanguageProvider/LanguageProvider";

export const BonusRunTable = () => {
  const [data, setData] = useState();
  const [currentPage, setCurrentPage] = useState(0)
  const [total, setTotal] = useState(1);

  const [updateState, setUpdateState] = useState(0);

  const [headerFields, setHeaderFields] = useState(isMobile() ? [
      {name: 'id', value: 'ID', direction: 'asc', sorted: true},
      {name: 'rank', value: 'Rank', direction: 'asc', sorted: true},
      {name: 'pack', value: 'Pack', direction: 'asc', sorted: true},
      {name: 'cvLeft', value: 'CV L', direction: 'asc', sorted: true},
      {name: 'cvRight', value: 'CV R', direction: 'asc', sorted: true},
      {name: 'rolloverLeft', value: 'RO L', direction: 'asc', sorted: true},
      {name: 'rolloverRight', value: 'RO R', direction: 'asc', sorted: true},
      {name: 'rolloverLeftTotal', value: 'RO L total', direction: 'asc', sorted: true},
      {name: 'rolloverRightTotal', value: 'RO R total', direction: 'asc', sorted: true},
      {name: 'cvPaidLeft', value: 'CV paid L', direction: 'asc', sorted: true},
      {name: 'cvPaidRight', value: 'CV paid R', direction: 'asc', sorted: true},
      {name: 'amount', value: 'Amount paid', direction: 'asc', sorted: true},
      {name: 'date', value: 'Date', direction: 'asc', sorted: true},
    ] :
    [
      {name: 'id', value: 'ID', direction: 'asc', sorted: true},
      {name: 'rank', value: 'Rank', direction: 'asc', sorted: true},
      {name: 'pack', value: 'Pack', direction: 'asc', sorted: true},
      {name: 'cvLeft', value: 'CV L', direction: 'asc', sorted: true},
      {name: 'cvRight', value: 'CV R', direction: 'asc', sorted: true},
      {name: 'rolloverLeft', value: 'RO L', direction: 'asc', sorted: true},
      {name: 'rolloverRight', value: 'RO R', direction: 'asc', sorted: true},
      {name: 'rolloverLeftTotal', value: 'RO L total', direction: 'asc', sorted: true},
      {name: 'rolloverRightTotal', value: 'RO R total', direction: 'asc', sorted: true},
      {name: 'cvPaidLeft', value: 'CV paid L', direction: 'asc', sorted: true},
      {name: 'cvPaidRight', value: 'CV paid R', direction: 'asc', sorted: true},
      {name: 'amount', value: 'Amount paid', direction: 'asc', sorted: true},
      {name: 'date', value: 'Date', direction: 'asc', sorted: true},
    ]
  );

  const onChangePage = (_, newPage) => {
    setCurrentPage(newPage)
  }

  const getData = useCallback(async () => {
    try {
      const response = await client.getAllBonusRun({
        page: currentPage,
        size: 10,
      });
      setData(response.content);
      setTotal(response.totalElements)
    } catch (e) {
      notification.warning(e.message);
    }
  }, [currentPage]);

  useEffect(() => {
    getData();
  }, [currentPage, updateState]);

  const renderActions = (e) => {
    return (
      <div id={e.id}>View</div>
    )
  }

  return (
    <LanguageConsumer>
      {({dict}) => (
        <>
          <Table className="table" size="small">
            <TableHead>
              {isMobile() ?
                <TableRow>
                  <TableCell key={1}>{dict.TABLE_BONUS_RUN_ID || "ID"}</TableCell>
                  <TableCell key={2}>{dict.TABLE_BONUS_RUN_RANK || "Rank"}</TableCell>
                  <TableCell key={3}>{dict.TABLE_BONUS_RUN_PACK || "Pack"}</TableCell>
                  <TableCell key={4}>{dict.TABLE_BONUS_RUN_CV_LEFT || "CV L"}</TableCell>
                  <TableCell key={5}>{dict.TABLE_BONUS_RUN_CV_RIGHT || "CV R"}</TableCell>
                  <TableCell key={6}>{dict.TABLE_BONUS_RUN_RO_L || "RO L"}</TableCell>
                  <TableCell key={7}>{dict.TABLE_BONUS_RUN_RO_R || "RO R"}</TableCell>
                  <TableCell key={8}>{dict.TABLE_BONUS_RUN_RO_L_TOTAL || "RO L total"}</TableCell>
                  <TableCell key={9}>{dict.TABLE_BONUS_RUN_RO_R_TOTAL || "RO R total"}</TableCell>
                  <TableCell key={10}>{dict.TABLE_BONUS_RUN_CV_PAID_L || "CV paid L"}</TableCell>
                  <TableCell key={11}>{dict.TABLE_BONUS_RUN_CV_PAID_R || "CV paid R"}</TableCell>
                  <TableCell key={12}>{dict.TABLE_BONUS_RUN_AMOUNT || "Amount paid"}</TableCell>
                  <TableCell key={13}>{dict.TABLE_BONUS_RUN_DATE || "Date"}</TableCell>
                </TableRow> :
                <TableRow>
                  <TableCell key={1}>{dict.TABLE_BONUS_RUN_ID || "ID"}</TableCell>
                  <TableCell key={2}>{dict.TABLE_BONUS_RUN_RANK || "Rank"}</TableCell>
                  <TableCell key={3}>{dict.TABLE_BONUS_RUN_PACK || "Pack"}</TableCell>
                  <TableCell key={4}>{dict.TABLE_BONUS_RUN_CV_LEFT || "CV L"}</TableCell>
                  <TableCell key={5}>{dict.TABLE_BONUS_RUN_CV_RIGHT || "CV R"}</TableCell>
                  <TableCell key={6}>{dict.TABLE_BONUS_RUN_RO_L || "RO L"}</TableCell>
                  <TableCell key={7}>{dict.TABLE_BONUS_RUN_RO_R || "RO R"}</TableCell>
                  <TableCell key={8}>{dict.TABLE_BONUS_RUN_RO_L_TOTAL || "RO L total"}</TableCell>
                  <TableCell key={9}>{dict.TABLE_BONUS_RUN_RO_R_TOTAL || "RO R total"}</TableCell>
                  <TableCell key={10}>{dict.TABLE_BONUS_RUN_CV_PAID_L || "CV paid L"}</TableCell>
                  <TableCell key={11}>{dict.TABLE_BONUS_RUN_CV_PAID_R || "CV paid R"}</TableCell>
                  <TableCell key={12}>{dict.TABLE_BONUS_RUN_AMOUNT || "Amount paid"}</TableCell>
                  <TableCell key={13}>{dict.TABLE_BONUS_RUN_DATE || "Date"}</TableCell>
                </TableRow>}
            </TableHead>
            <TableBody>
              {data && data.map((e, index) => (
                isMobile() ?
                  <TableRow hover key={index}>
                    <TableCell>{e.externalId}</TableCell>
                    <TableCell>{e.rank}</TableCell>
                    <TableCell>{e.pack}</TableCell>
                    <TableCell>{e.cvLeft}</TableCell>
                    <TableCell>{e.cvRight}</TableCell>
                    <TableCell>{e.rolloverLeft}</TableCell>
                    <TableCell>{e.rolloverRight}</TableCell>
                    <TableCell>{e.rolloverLeftTotal}</TableCell>
                    <TableCell>{e.rolloverRightTotal}</TableCell>
                    <TableCell>{e.cvPaidLeft}</TableCell>
                    <TableCell>{e.cvPaidRight}</TableCell>
                    <TableCell>{toEur(e.amountPaid)}</TableCell>
                    <TableCell>{e.createdAt}</TableCell>
                  </TableRow> :
                  <TableRow hover key={index}>
                    <TableCell>{e.externalId}</TableCell>
                    <TableCell>{e.rank}</TableCell>
                    <TableCell>{e.pack}</TableCell>
                    <TableCell>{e.cvLeft}</TableCell>
                    <TableCell>{e.cvRight}</TableCell>
                    <TableCell>{e.rolloverLeft}</TableCell>
                    <TableCell>{e.rolloverRight}</TableCell>
                    <TableCell>{e.rolloverLeftTotal}</TableCell>
                    <TableCell>{e.rolloverRightTotal}</TableCell>
                    <TableCell>{e.cvPaidLeft}</TableCell>
                    <TableCell>{e.cvPaidRight}</TableCell>
                    <TableCell>{toEur(e.amountPaid)}</TableCell>
                    <TableCell>{e.createdAt}</TableCell>
                  </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={total}
            rowsPerPage={10}
            rowsPerPageOptions={[]}
            page={currentPage}
            backIconButtonProps={{
              'aria-label': 'previous page',
            }}
            nextIconButtonProps={{
              'aria-label': 'next page',
            }}
            onPageChange={onChangePage}
            className="table-pagination"
          />

        </>
      )}
    </LanguageConsumer>
  );
}