import React, {useCallback, useEffect, useState} from "react";
import {Table, TableBody, TableCell, TableHead, TablePagination, TableRow} from "@mui/material";
import {client} from "../../../../services/client";
import {notification} from "../../../../components/Notification";
import {LanguageConsumer} from "../../../../components/LanguageProvider/LanguageProvider";

export const NewPartnersTable = () => {
  const [data, setData] = useState();
  const [currentPage, setCurrentPage] = useState(0)
  const [total, setTotal] = useState(1);

  const [headerFields, setHeaderFields] = useState([
    {name: 'username', value: 'Username', direction: 'asc', sorted: true},
    {name: 'country', value: 'Country', direction: 'asc', sorted: true},
    {name: 'package', value: 'Package', direction: 'asc', sorted: true},
    {name: 'date', value: 'Date', direction: 'asc', sorted: true},
    {name: 'leg', value: 'Leg', direction: 'asc', sorted: true},
  ]);

  const onChangePage = (_, newPage) => {
    setCurrentPage(newPage)
  }

  const getData = useCallback(async () => {
    try {
      const response = await client.getPartners({
        page: currentPage,
        size: 8,
      });
      setData(response.content);
      setTotal(response.totalElements)
    } catch (e) {
      notification.warning(e.message);
    }
  }, [currentPage]);

  useEffect(() => {
    getData();
  }, [currentPage])

  return (
    <LanguageConsumer>
      {({dict}) => (
        <>
          <Table className="table" size="small">
            <TableHead>
              <TableRow>
                <TableCell key={1}>{dict.TABLE_NEW_PARTNERS_USERNAME || "Username"}</TableCell>
                <TableCell key={2}>{dict.TABLE_NEW_PARTNERS_COUNTRY || "Country"}</TableCell>
                <TableCell key={3}>{dict.TABLE_NEW_PARTNERS_PACK || "Pack"}</TableCell>
                <TableCell key={4}>{dict.TABLE_NEW_PARTNERS_DATE || "Date"}</TableCell>
                <TableCell key={5}>{dict.TABLE_NEW_PARTNERS_LEG || "Leg"}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data && data.map((e, index) => (
                <TableRow hover key={index}>
                  <TableCell>{e.username}</TableCell>
                  <TableCell>{e.countryName}</TableCell>
                  <TableCell>{e.pack}</TableCell>
                  <TableCell>{e.createdAt}</TableCell>
                  <TableCell>{e.leg}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </>
      )}
    </LanguageConsumer>
  );
}