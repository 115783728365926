import React, {useCallback, useEffect, useState} from "react";
import {Table, TableBody, TableCell, TableHead, TablePagination, TableRow} from "@mui/material";
import {client} from "../../../../services/client";
import {notification} from "../../../../components/Notification";
import {isMobile} from "../../../../utils/checkDevice";
import {toEur} from "../../../../utils/tools";
import "../../Dashboard.css";
import {ModalSendEpin} from "../../../../components/Modals/ModalSendEpin/ModalSendEpin";
import {setModalStates} from "../../../../store/actions/actions";
import {useDispatch, useSelector} from "react-redux";
import {LanguageConsumer} from "../../../../components/LanguageProvider/LanguageProvider";

export const EpinTable = () => {
  const dispatch = useDispatch();
  const modalStates = useSelector(store => store.base.modalStates);
  const [data, setData] = useState();
  const [currentPage, setCurrentPage] = useState(0)
  const [total, setTotal] = useState(1);
  const [sendId, setSendId] = useState(1);

  const [emailLoading, setEmailLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const [updateState, setUpdateState] = useState(0);

  const [headerFields, setHeaderFields] = useState(isMobile() ? [
      {name: 'username', value: 'Username', direction: 'asc', sorted: true},
      {name: 'code', value: 'E-pin', direction: 'asc', sorted: true},
      {name: 'amount', value: 'Amount', direction: 'asc', sorted: true},
      {name: 'email', value: '', direction: 'asc', sorted: true},
      {name: 'delete', value: '', direction: 'asc', sorted: true},
    ] :
    [
      {name: 'username', value: 'Username', direction: 'asc', sorted: true},
      {name: 'code', value: 'E-pin', direction: 'asc', sorted: true},
      {name: 'createdAt', value: 'Created date', direction: 'asc', sorted: true},
      {name: 'amount', value: 'Amount', direction: 'asc', sorted: true},
      {name: 'status', value: 'Status', direction: 'asc', sorted: true},
      {name: 'transferable', value: 'Transferable', direction: 'asc', sorted: true},
      {name: 'email', value: 'Email', direction: 'asc', sorted: true},
      {name: 'delete', value: 'Delete', direction: 'asc', sorted: true},
    ]
  );

  const onChangePage = (_, newPage) => {
    setCurrentPage(newPage)
  }

  const getData = useCallback(async () => {
    try {
      const response = await client.getAllEpins({
        page: currentPage,
        size: 10,
      });
      setData(response.content);
      setTotal(response.totalElements)
    } catch (e) {
      notification.warning(e.message);
    }
  }, [currentPage]);

  useEffect(() => {
    getData();
  }, [currentPage, updateState]);

  const renderEmail = (e) => {
    return e.status === "ACTIVE" && <div className="email-icon" id={e.id} onClick={handleEmail}/>
  }

  const renderDelete = (e) => {
    return e.status === "ACTIVE" && <div className="delete-icon" id={e.id} onClick={handleDelete}/>
  }

  const handleEmail = (e) => {
    setEmailLoading(true);
    setSendId(e.target.id);
    dispatch(setModalStates({...modalStates, modalSendEpin: true}));
    setEmailLoading(false);
  }

  const handleDelete = async (e) => {
    setDeleteLoading(true);
    try {
      const response = await client.deleteEpin(e.target.id);
      setUpdateState(updateState + 1);
      notification.success("E-pin removed");
    } catch (error) {
      notification.warning(error.message);
    }
    setDeleteLoading(false);
  }

  return (
    <LanguageConsumer>
      {({dict}) => (
        <>
          <Table className="table" size="small">
            <TableHead>
              {isMobile() ?
                <TableRow>
                  <TableCell key={1}>{dict.TABLE_EPIN_USERNAME || "Username"}</TableCell>
                  <TableCell key={2}>{dict.TABLE_EPIN_CODE || "E-pin"}</TableCell>
                  <TableCell key={3}>{dict.TABLE_EPIN_AMOUNT || "Amount"}</TableCell>
                  <TableCell key={4}/>
                  <TableCell key={5}/>
                </TableRow> :
                <TableRow>
                  <TableCell key={1}>{dict.TABLE_EPIN_USERNAME || "Username"}</TableCell>
                  <TableCell key={2}>{dict.TABLE_EPIN_CODE || "E-pin"}</TableCell>
                  <TableCell key={3}>{dict.TABLE_EPIN_DATE || "Date"}</TableCell>
                  <TableCell key={4}>{dict.TABLE_EPIN_AMOUNT || "Amount"}</TableCell>
                  <TableCell key={5}>{dict.TABLE_EPIN_STATUS || "Status"}</TableCell>
                  <TableCell key={6}>{dict.TABLE_EPIN_TRANSFERABLE || "Transferable"}</TableCell>
                  <TableCell key={7}>{dict.TABLE_EPIN_EMAIL || "Email"}</TableCell>
                  <TableCell key={8}>{dict.TABLE_EPIN_DELETE || "Delete"}</TableCell>
                </TableRow>}
            </TableHead>
            <TableBody>
              {data && data.map((e, index) => (
                isMobile() ?
                  <TableRow hover key={index}>
                    <TableCell>{e.usedBy}</TableCell>
                    <TableCell>{e.code}</TableCell>
                    <TableCell>{toEur(e.amount)}</TableCell>
                    <TableCell>{renderEmail(e)}</TableCell>
                    <TableCell>{renderDelete(e)}</TableCell>
                  </TableRow> :
                  <TableRow hover key={index}>
                    <TableCell>{e.usedBy}</TableCell>
                    <TableCell>{e.code}</TableCell>
                    <TableCell>{e.createdAt}</TableCell>
                    <TableCell>{toEur(e.amount)}</TableCell>
                    <TableCell>{e.status}</TableCell>
                    <TableCell>{e.transferable === true ? "Yes" : "No"}</TableCell>
                    <TableCell>{renderEmail(e)}</TableCell>
                    <TableCell>{renderDelete(e)}</TableCell>
                  </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={total}
            rowsPerPage={10}
            rowsPerPageOptions={[]}
            page={currentPage}
            backIconButtonProps={{
              'aria-label': 'previous page',
            }}
            nextIconButtonProps={{
              'aria-label': 'next page',
            }}
            onPageChange={onChangePage}
            className="table-pagination"
          />

          <ModalSendEpin id={sendId}/>
        </>
      )}
    </LanguageConsumer>
  );
}