import React, {useEffect, useState} from "react";
import "./CustomTree.css";
import {client} from "../../../../../services/client";
import {BinaryItemList, SponsorItemList} from "./CustomTreeItem";

const ITEM_LIST = {
  sponsor: SponsorItemList,
  binary: BinaryItemList
}

export const CustomTree = ({type, _root}) => {
  const [root, setRoot] = useState(_root);
  const [user, setUser] = useState();

  useEffect(() => {
    setRoot(_root)
  }, [_root]);

  useEffect(() => {
    if (type === "binary") {
      client.getBinarySubtree(root.id).then(setUser);
    } else if (type === "sponsor"){
      client.getSponsorSubtree(root.id).then(setUser);
    }
  }, [root]);

  const handleExpand = () => {
    client.getBinarySubtree(root.id).then(setUser); //TODO all tree
  }

  const handleSearch = (id) => {
    client.getBinarySubtree(id).then(setUser); //TODO get user by username
  }

  const Component = ITEM_LIST[type];

  return (
    <>
      <div className="expand"/>
      <div className="search"/>
      <div className="custom-tree">
        {user ? <Component items={[user]} isRoot={true}/> : <div>Loading...</div>}
      </div>
    </>
  );
}