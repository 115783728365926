import React, {useEffect, useState} from "react";
import "../Auth.css";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {ModalInvite} from "../../../components/Modals/ModalInvite/ModalInvite";
import {logout, setAuth, setModalStates, setUser} from "../../../store/actions/actions";
import {notification} from "../../../components/Notification";
import {client} from "../../../services/client";
import {jwt} from "../../../services/jwt";
import {USER_STATUS} from "../../../constants/userStatuses";
import {ROUTE} from "../../../constants/routes";
import {ModalResetPassword} from "../../../components/Modals/ModalResetPassword/ModalResetPassword";
import {LanguageConsumer} from "../../../components/LanguageProvider/LanguageProvider";

export const Login = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const modalStates = useSelector(store => store.base.modalStates);

  useEffect(() => {
    dispatch(logout());
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();
    if (loading) {
      return;
    }
    setLoading(true);
    const email = e.target.email.value;
    const password = e.target.password.value;
    try {
      const response = await client.auth2fa({email, password});
      if (response.access_token !== '') {
        jwt.setJwtData(response.access_token, response.refresh_token);
        const user = await client.getCurrentUser();
        dispatch(setUser(user));
        dispatch(setAuth(true));

        localStorage.setItem("showLRTSalesModal", "true");

        if (user.status === USER_STATUS.NEW) {
          history.push(ROUTE.SIGNUP_PAYMENT);
        }
        if (user.status === USER_STATUS.WAITING_FOR_PAYMENT) {
          history.push(ROUTE.SIGNUP_WAITING);
        }
        if (user.status === USER_STATUS.MEMBER) {
          history.push(ROUTE.DASHBOARD);
        }

        setLoading(false);
      }
    } catch (error) {
      notification.warning(error.message);
      setLoading(false);
    }
  }

  return (
    <LanguageConsumer>
      {({dict}) => (
        <div className="auth-container">
          <div className="auth-content">
            <div className="caption">{dict.LOGIN_FORM_LOGIN_CAPTION || "Log in"}</div>
            <div className="logo" onClick={() => history.push(ROUTE.ROOT)}/>
            <form onSubmit={handleLogin}>
              <div className="form-item">
                <input
                  id="email"
                  type="text"
                  required
                  autoComplete="off"
                  placeholder=" "
                  className="guest-input"
                />
                <label htmlFor="email">{dict.LOGIN_FORM_EMAIL || "Email"}</label>
              </div>
              <div className="form-item">
                <input
                  id="password"
                  type="password"
                  required
                  autoComplete="off"
                  placeholder="Password"
                  className="guest-input"
                />
                <label htmlFor="password">{dict.LOGIN_FORM_PASSWORD || "Password"}</label>
              </div>
              <div className="form-item form-button-container">
                {loading ? <span className="loader"/> : <button type="submit" className="button-blue">{dict.LOGIN_FORM_BUTTON || "Login"}</button>}
              </div>
              <div className="login-help">
                <div>{dict.LOGIN_FORM_INVITE_LINK || "Don’t have an account? Ask us for an invitation"}
                  <span className="link-underline"
                        onClick={() => dispatch(setModalStates({...modalStates, modalInvite: true}))}>{dict.LOGIN_FORM_HERE || "here"}</span>
                </div>
                <div className="link"
                     onClick={() => dispatch(setModalStates({...modalStates, modalResetPassword: true}))}>
                  {dict.LOGIN_FORM_FORGOT_PASSWORD_LINK || "Forgot your password?"}
                </div>
              </div>
            </form>
          </div>

          <ModalInvite/>
          <ModalResetPassword/>

        </div>
      )}
    </LanguageConsumer>
  )
}