import React from "react";
import {LanguageConsumer} from "../../components/LanguageProvider/LanguageProvider";
import "./Static.css";
import {StaticPageHeader} from "./components/StaticPageHeader";
import {LandingFooter} from "../../components/Footer/LandingFooter";

export const About = () => {
  return (
    <LanguageConsumer>
      {({dict}) => (
        <div className="static-container">
          <div className="static-page-about">

            <StaticPageHeader title={dict.ABOUT_PAGE_TITLE} dict={dict}/>

            <div className="static-page-content-container">
              <div className="static-page-content">
                <div>
                  <div className="static-page-p">
                    <div className="static-page-p-title">{dict.ABOUT_PAGE_P1_TITLE}</div>
                    <div>{dict.ABOUT_PAGE_P1}</div>
                  </div>

                  <div className="static-page-p">
                    <div className="static-page-p-title">{dict.ABOUT_PAGE_P2_TITLE}</div>
                    <div>{dict.ABOUT_PAGE_P2}</div>
                  </div>

                  <div className="static-page-p">
                    <div className="static-page-p-title">{dict.ABOUT_PAGE_P3_TITLE}</div>
                    <div>{dict.ABOUT_PAGE_P3}</div>
                  </div>

                  <div className="static-page-p">
                    <div className="static-page-img-about1"/>
                  </div>
                </div>

                <div>
                  <div className="static-page-p">
                    <div className="static-page-p-title">{dict.ABOUT_PAGE_P4_TITLE}</div>
                    <div>{dict.ABOUT_PAGE_P4}</div>
                  </div>

                  <div className="static-page-p">
                    <div className="static-page-img-about2"/>
                  </div>

                  <div className="static-page-p">
                    <div className="static-page-p-title">{dict.ABOUT_PAGE_P5_TITLE}</div>
                    <div>{dict.ABOUT_PAGE_P5}</div>
                  </div>

                  <div className="static-page-p">
                    <div className="static-page-p-title">{dict.ABOUT_PAGE_P6_TITLE}</div>
                    <div>{dict.ABOUT_PAGE_P6}</div>
                  </div>

                  <div className="static-page-p">
                    <div className="static-page-img-about3"/>
                  </div>

                </div>

              </div>
            </div>

            <LandingFooter dict={dict}/>

          </div>
        </div>
      )}
    </LanguageConsumer>
  )
}