import React, {useState} from "react";
import {Modal} from "@mui/material";
import {setModalStates, setUser, setWallet} from "../../../store/actions/actions";
import {useDispatch, useSelector} from "react-redux";
import {notification} from "../../Notification";
import {client} from "../../../services/client";
import {ConfirmationCode} from "../../ConfrimationCode/ConfirmationCode";
import {LanguageConsumer} from "../../LanguageProvider/LanguageProvider";

export const ModalChangeWallet = ({walletType}) => {
  const dispatch = useDispatch();
  const wallet = useSelector(store => store.base.wallet);
  const modalStates = useSelector(store => store.base.modalStates);
  const [code, setCode] = useState();


  const handleChange = (value) => {
    setCode(value)
  }

  const handleCloseModal = () => {
    dispatch(setModalStates({...modalStates, modalChangeWallet: false}));
  }

  const sendCode = async (e) => {
    e.preventDefault();
    const address = e.target.address.value;
    try {
      await client.confirmChangeWallet({
        address: address,
        type: walletType,
        confirmationCode: code,
      });
      handleCloseModal();
      notification.success("Wallet address changed");
      switch (walletType) {
        case "BTC": {
          dispatch(setWallet({...wallet, btcAddress: address}));
          break;
        }
        case "ETH": {
          dispatch(setWallet({...wallet, ethAddress: address}));
          break;
        }
        case "USDT": {
          dispatch(setWallet({...wallet, usdtAddress: address}));
          break;
        }
      }

    } catch (e) {
      notification.warning(e.message);
    }
  }

  const modal = (
    <LanguageConsumer>
      {({dict}) => (
        <div className="modal">
          <div className="modal-close-button" onClick={handleCloseModal}/>
          <div className="modal-content center">
            <div>{dict.MODAL_CHANGE_WALLET_CAPTION_PART1 || "Add your new"} {walletType} {dict.MODAL_CHANGE_WALLET_CAPTION_PART2 || "wallet address and the security code sent to your current email address to confirm the modifications."}
            </div>

            <form onSubmit={sendCode}>
              <div className="form-item">
                <input
                  id="address"
                  type="text"
                  required
                  autoComplete="off"
                  placeholder=""
                />
                <label htmlFor="address">{walletType} {dict.MODAL_CHANGE_WALLET_ADDRESS || "address"}</label>
                <div className="input-error"></div>
              </div>
              <div className="form-item1">
                <ConfirmationCode
                  onChange={handleChange}
                  onComplete={sendCode}
                  numberOfFields={6}
                />
              </div>
              <div className="modal-button-container">
                <button className="button-green button-large" type="submit">{dict.MODAL_CHANGE_WALLET_BUTTON || "Confirm"}</button>
              </div>
            </form>
          </div>
        </div>
      )}
    </LanguageConsumer>
  );

  return (
    <Modal
      open={modalStates.modalChangeWallet}
      onClose={handleCloseModal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      {modal}
    </Modal>
  )
}