import React from "react";
import "./Blocks.css";
import {toEur} from "../../utils/tools";
import {useDispatch, useSelector} from "react-redux";
import {setMenuId, setSubmenuId} from "../../store/actions/actions";
import {ROUTE} from "../../constants/routes";
import {useHistory} from "react-router-dom";
import {LanguageConsumer} from "../LanguageProvider/LanguageProvider";

export const WalletBlocks = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const wallet = useSelector(store => store.base.wallet);

  return (
    <LanguageConsumer>
      {({dict}) => (
        <div className="block-container">
          <div className="block block-purple1 cursor-pointer" onClick={() => {
            dispatch(setSubmenuId(1));
            history.push(ROUTE.DASHBOARD_WALLETS);
          }}>
            <div className="block-caption">{wallet && toEur(wallet.cashBalance)}</div>
            <div className="block-text">{dict.BLOCK_WALLET_CASH || "Cash"}</div>
          </div>

          <div className="block block-green1 cursor-pointer" onClick={() => {
            dispatch(setSubmenuId(5));
            history.push(ROUTE.DASHBOARD_WALLETS);
          }}>
            <div className="block-caption">{wallet && toEur(wallet.mandatoryBalance)}</div>
            <div className="block-text">{dict.BLOCK_WALLET_MANDATORY || "Mandatory"}</div>
          </div>

          <div className="block block-brown cursor-pointer" onClick={() => {
            dispatch(setSubmenuId(4));
            history.push(ROUTE.DASHBOARD_WALLETS);
          }}>
            <div className="block-caption">{wallet && toEur(wallet.lmgtValue)}</div>
            <div className="block-text">{dict.BLOCK_WALLET_LMGT || "LMGT value"}</div>
          </div>

          <div className="block block-red1">
            <div className="block-caption">{wallet && toEur(wallet.lrtBalance, false)}</div>
            <div className="block-text">{dict.BLOCK_WALLET_LRT || "LRT token"}</div>
          </div>

          <div className="block block-purple2 cursor-pointer" onClick={() => {
            dispatch(setSubmenuId(2));
            history.push(ROUTE.DASHBOARD_WALLETS);
          }}>
            <div className="block-caption">{wallet && wallet.loyaltyReward && toEur(wallet.loyaltyReward)}</div>
            <div className="block-text">{dict.BLOCK_WALLET_LRT_REWARD || "Loyalty reward"}</div>
          </div>

          <div className="block block-green1">
            <div className="block-caption">{wallet && toEur(wallet.earned)}</div>
            <div className="block-text">{dict.BLOCK_WALLET_TOTAL_EARNINGS || "Total earnings"}</div>
          </div>
        </div>
      )}
    </LanguageConsumer>
  )
}