import React, {useCallback, useEffect, useState} from "react";
import {isMobile} from "../../../utils/checkDevice";
import {WalletBlocks} from "../../../components/Blocks/WalletBlocks";
import {StatMobileBlocks} from "../../../components/Blocks/StatMobileBlocks";
import "../Dashboard.css";
import {client} from "../../../services/client";
import {notification} from "../../../components/Notification";
import {toEur} from "../../../utils/tools";
import {BonusRunTable} from "./components/BonusRunTable";
import {LanguageConsumer} from "../../../components/LanguageProvider/LanguageProvider";

export const Statements = () => {
  const [lastBonusRun, setLastBonusRun] = useState();
  const [lastCvStat, setLastCvStat] = useState();

  const getLastBonusRun = useCallback(async () => {
    try {
      await client.getLastBonusRun().then(setLastBonusRun);
    } catch (e) {
      notification.warning(e.message);
    }
  }, [lastBonusRun]);

  const getLastCvStat = useCallback(async () => {
    try {
      await client.getLastCvStat().then(setLastCvStat);
    } catch (e) {
      notification.warning(e.message);
    }
  }, [lastCvStat]);

  useEffect(() => {
    getLastBonusRun();
    getLastCvStat();
  }, [])

  return (
    <LanguageConsumer>
      {({dict}) => (
        <>
          {isMobile() ? <StatMobileBlocks/> : <WalletBlocks/>}

          <div className="dashboard-blocks-container">
            <div className="dashboard-block dashboard-statements-block1">
              {lastBonusRun && <>
                <div className="dashboard-statements-block1-title">
                  <div>{dict.STATEMENTS_BONUS_RUN_TITLE || "Bonus run"}</div>
                  <div>{dict.STATEMENTS_BONUS_RUN_WEEK || "Week"} {lastBonusRun.week} - {lastBonusRun.dateStart} {dict.STATEMENTS_BONUS_RUN_WEEK_TO || "to"} {lastBonusRun.dateEnd}</div>
                </div>

                <div className="dashboard-main-block1-table">
                  <div className="dashboard-main-block1-table-row">
                    <div className="dashboard-main-block1-table-cell">{dict.STATEMENTS_BONUS_RUN_TYPE || "Type"}</div>
                    <div className="dashboard-main-block1-table-cell"/>
                    <div className="dashboard-main-block1-table-cell">{dict.STATEMENTS_BONUS_RUN_AMOUNT || "Amount"}</div>
                  </div>

                  {lastBonusRun.binaryBonus.map((t) => {
                    return <div className="dashboard-main-block1-table-row">
                      <div className="dashboard-main-block1-table-cell">{dict.STATEMENTS_BONUS_RUN_BINARY_BONUS || "Binary bonus"}</div>
                      <div className="dashboard-main-block1-table-cell">{t.percent} % x {t.amountOriginal}</div>
                      <div className="dashboard-main-block1-table-cell">{toEur(t.amountPaid)}</div>
                    </div>
                  })}

                  {lastBonusRun.matchingBonus.map((t) => {
                    return <div className="dashboard-main-block1-table-row">
                      <div className="dashboard-main-block1-table-cell">{dict.STATEMENTS_BONUS_RUN_MATCHING_BONUS || "Matching bonus"}</div>
                      <div className="dashboard-main-block1-table-cell">{dict.STATEMENTS_BONUS_RUN_LEVEL || "Level"} {t.level}</div>
                      <div className="dashboard-main-block1-table-cell">{toEur(t.amountPaid)}</div>
                    </div>
                  })}

                  <div className="dashboard-main-block1-table-row">
                    <div className="dashboard-main-block1-table-cell">{dict.STATEMENTS_BONUS_RUN_LSB || "Life style bonus"}</div>
                    <div className="dashboard-main-block1-table-cell"/>
                    <div className="dashboard-main-block1-table-cell">
                      {lastBonusRun.lifeStyleBonus ? toEur(lastBonusRun.lifeStyleBonus.amountPaid) : toEur(0)}
                    </div>
                  </div>
                  <div className="dashboard-main-block1-table-row">
                    <div className="dashboard-main-block1-table-cell">{dict.STATEMENTS_BONUS_RUN_RAB || "Rank advancement bonus"}</div>
                    <div className="dashboard-main-block1-table-cell"/>
                    <div className="dashboard-main-block1-table-cell">
                      {lastBonusRun.rankAdvancementBonus && toEur(lastBonusRun.rankAdvancementBonus.amountPaid)}
                    </div>
                  </div>
                  <div className="dashboard-main-block1-table-row">
                    <div className="dashboard-main-block1-table-cell">{dict.STATEMENTS_BONUS_RUN_TOTAL || "TOTAL"}</div>
                    <div className="dashboard-main-block1-table-cell"/>
                    <div className="dashboard-main-block1-table-cell">
                      {lastBonusRun.total ? toEur(lastBonusRun.total) : toEur(0)}
                    </div>
                  </div>
                </div>

              </>}
            </div>

            <div className="dashboard-block dashboard-statements-block1">
              {lastCvStat ? <>
                <div className="dashboard-statements-block1-title">
                  <div>{dict.STATEMENTS_SUMMARY_CV || "Summary CV"}</div>
                </div>
                <div className="dashboard-main-block1-table">
                  <div className="dashboard-main-block1-table-row">
                    <div className="dashboard-main-block1-table-cell">{dict.STATEMENTS_SUMMARY_TYPE || "Type"}</div>
                    <div className="dashboard-main-block1-table-cell">{dict.STATEMENTS_SUMMARY_LEFT || "Left"}</div>
                    <div className="dashboard-main-block1-table-cell">{dict.STATEMENTS_SUMMARY_RIGHT || "Right"}</div>
                  </div>

                  <div className="dashboard-main-block1-table-row">
                    <div className="dashboard-main-block1-table-cell">{dict.STATEMENTS_SUMMARY_CURRENT_CV || "Current week CV"}</div>
                    <div className="dashboard-main-block1-table-cell">{lastCvStat.currentWeek?.leftCV}</div>
                    <div className="dashboard-main-block1-table-cell">{lastCvStat.currentWeek?.rightCV}</div>
                  </div>

                  <div className="dashboard-main-block1-table-row">
                      <div className="dashboard-main-block1-table-cell">{dict.STATEMENTS_SUMMARY_RO || "Roll over next week"}</div>
                    <div className="dashboard-main-block1-table-cell">{lastCvStat.nextWeek?.leftCV}</div>
                    <div className="dashboard-main-block1-table-cell">{lastCvStat.nextWeek?.rightCV}</div>
                  </div>

                  <div className="dashboard-main-block1-table-row">
                    <div className="dashboard-main-block1-table-cell">{dict.STATEMENTS_SUMMARY_EXPIRING || "Expiring CV next week"}</div>
                    <div className="dashboard-main-block1-table-cell">{lastCvStat.expiring?.leftCV}</div>
                    <div className="dashboard-main-block1-table-cell">{lastCvStat.expiring?.rightCV}</div>
                  </div>

                  <br/>
                  <br/>

                  <div className="dashboard-main-block1-table-row">
                    <div className="dashboard-main-block1-table-cell">{dict.STATEMENTS_SUMMARY_TOTAL_RO || "Total roll over CV"}</div>
                    <div className="dashboard-main-block1-table-cell">{lastCvStat.totalRollover?.leftCV}</div>
                    <div className="dashboard-main-block1-table-cell">{lastCvStat.totalRollover?.rightCV}</div>
                  </div>

                  <div className="dashboard-main-block1-table-row">
                    <div className="dashboard-main-block1-table-cell">{dict.STATEMENTS_SUMMARY_TOTAL_PAID || "Total paid CV"}</div>
                    <div className="dashboard-main-block1-table-cell">{lastCvStat.totalPaid?.leftCV}</div>
                    <div className="dashboard-main-block1-table-cell">{lastCvStat.totalPaid?.rightCV}</div>
                  </div>

                  <div className="dashboard-main-block1-table-row">
                    <div className="dashboard-main-block1-table-cell">{dict.STATEMENTS_SUMMARY_TOTAL_EXPIRED || "Total expired CV"}</div>
                    <div className="dashboard-main-block1-table-cell">{lastCvStat.totalExpired?.leftCV}</div>
                    <div className="dashboard-main-block1-table-cell">{lastCvStat.totalExpired?.rightCV}</div>
                  </div>

                </div>

              </> : <div>{dict.STATEMENTS_SUMMARY_NO_DATA || "No data"}</div>}
            </div>

          </div>

          <div className="dashboard-blocks-container">
            <div className="dashboard-block dashboard-epin-block4">
              <div className="dashboard-table-title">{dict.STATEMENTS_BONUS_RUN_TABLE_TITLE || "Completed bonus-run"}</div>
              <BonusRunTable/>
            </div>
          </div>
        </>
      )}
    </LanguageConsumer>
  )
}