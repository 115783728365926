import React from "react";
import "./MobileBlocks.css";
import {toEur} from "../../utils/tools";
import {useSelector} from "react-redux";
import {LanguageConsumer} from "../LanguageProvider/LanguageProvider";

export const StatMobileBlocks = () => {
  const userStat = useSelector(store => store.base.userStat);
  const wallet = useSelector(store => store.base.wallet);

  return (
    <LanguageConsumer>
      {({dict}) => (
        <div className="mobile-blocks-container">
          <div className="mobile-blocks-row">
            <div className="mobile-blocks-cell">
              <div>{dict.TOP_PANEL_MOBILE_LEFT_CV || "Left CV"}:</div>
              <div>{userStat?.leftCV}</div>
            </div>
            <div className="mobile-blocks-cell">
              <div>{dict.TOP_PANEL_MOBILE_RIGHT_CV || "Right CV"}:</div>
              <div>{userStat?.rightCV}</div>
            </div>
          </div>
          <div className="mobile-blocks-row">
            <div className="mobile-blocks-cell mobile-blocks-cell-full-width">
              <div>{dict.TOP_PANEL_MOBILE_NEXT_BONUS_RUN || "Next bonus run"}:</div>
              <div>{userStat && (userStat.nextBonusRun + " UTC")}</div>
            </div>
          </div>
          <div className="mobile-blocks-row">
            <div className="mobile-blocks-cell">
              <div>{dict.TOP_PANEL_MOBILE_EARNINGS || "Earnings"}:</div>
              <div>{wallet && toEur(wallet.earned)}</div>
            </div>
            <div className="mobile-blocks-cell">
              <div>{dict.TOP_PANEL_MOBILE_TOKEN_VALUE || "Token value"}:</div>
              <div>{wallet && toEur(wallet.lmgtValue)}</div>
            </div>
          </div>
        </div>
      )}
    </LanguageConsumer>
  )
}