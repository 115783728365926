import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {WalletBlocks} from "../../../components/Blocks/WalletBlocks";
import {isMobile} from "../../../utils/checkDevice";
import {StatMobileBlocks} from "../../../components/Blocks/StatMobileBlocks";
import "../Dashboard.css";
import {notification} from "../../../components/Notification";
import {PersonallyInvitedTable} from "../network/components/PersonallyInvitedTable";
import {LatestTeamImpsTable} from "../network/components/LatestTeamImpsTable";
import {NewPartnersTable} from "../network/components/NewPartnersTable";
import {Share} from "../../../components/Share/Share";
import {client} from "../../../services/client";
import {Map} from "../network/components/Map/Map";
import {setMapStat, setModalStates, setUser} from "../../../store/actions/actions";
import {CountryStat} from "../network/components/CountryStat/CountryStat";
import {OrganisationChart} from "../network/components/OrganisationChart/OrganisationChart";
import {LanguageConsumer} from "../../../components/LanguageProvider/LanguageProvider";
import {ModalEducationTime} from "../../../components/Modals/ModalEducationTime/ModalEducationTime";
import {ROUTE} from "../../../constants/routes";
import {useHistory} from "react-router-dom";
import {ModalActivationExpired} from "../../../components/Modals/ModalActivationExpired/ModalActivationExpired";
import {ModalLRTSales} from "../../../components/Modals/ModalLRTSales/ModalLRTSales";

export const DashboardMain = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector(store => store.base.user);
  const wallet = useSelector(store => store.base.wallet);
  const userStat = useSelector(store => store.base.userStat);
  const submenuId = useSelector(store => store.base.submenuId);
  const modalEducationTime = useSelector(store => store.base.modalEducationTime);
  const [shareLink, setShareLink] = useState();
  const [educationNext, setEducationNext] = useState(null);
  const modalStates = useSelector(store => store.base.modalStates);

  const [selectedPlacement, setSelectedPlacement] = useState()
  const placements = [
    {id: 1, refType: "LEFT", name: "Left leg"},
    {id: 2, refType: "RIGHT", name: "Right leg"},
    {id: 3, refType: "AUTO", name: "Automatic"},
    // {id: 4, refType: "HOLDING_TANK", name: "Holding tank"}
  ];

  const handleChangePlacement = async (e) => {
    setSelectedPlacement(placements[e.target.value - 1]);
    try {
      await client.changeRefType({
        refType: placements[e.target.value - 1].refType
      });
      dispatch(setUser({...user, refTypeOrdinal: e.target.value - 1}))
    } catch (error) {
      notification.warning(error.message);
    }
  }

  const getCountryStatistics = async () => {
    try {
      const response = await client.getMapStat();
      dispatch(setMapStat(response));
    } catch (error) {
      notification.warning(error.message);
    }
  };

  const getEducationNext = async () => {
    try {
      await client.getEducationTimeNext().then(setEducationNext);
    } catch (error) {
      notification.warning(error.message);
    }
  };

  useEffect(() => {
    getCountryStatistics();
    getEducationNext();
    if (user && !user.active) {
      dispatch(setModalStates({...modalStates, modalActivationExpired: true}));
    }
    if (localStorage.getItem("showLRTSalesModal") === "true") {
      dispatch(setModalStates({...modalStates, modalLRTSales: true}));
    }
  }, []);

  useEffect(() => {
    console.log(localStorage.getItem("showLRTSalesModal"));
    if (localStorage.getItem("showLRTSalesModal") === "true") {
      dispatch(setModalStates({...modalStates, modalLRTSales: true}));
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      setShareLink("https://binarytorrent.com/?ref=" + user.username);
      setSelectedPlacement(+user.refTypeOrdinal + 1);
    }
  }, [user]);

  const showEducationTimeModal = () => {
    dispatch(setModalStates({...modalEducationTime, modalEducationTime: true}));
  }

  const handleBuyClick = () => {
    history.push(ROUTE.DASHBOARD_ACCOUNT_UPGRADE_PAYMENT + "?type=buy_pack")
  }

  const handleUpgradeClick = () => {
    history.push(ROUTE.DASHBOARD_ACCOUNT_UPGRADE_PAYMENT + "?type=upgrade")
  }

  const renderBuyButton = (dict) => {
    return <div className="buy-button" onClick={handleBuyClick}>
      <div className="buy-button-icon"/>
      <div>{dict.DASHBOARD_MAIN_BUTTON_BUY || "Buy"}</div>
    </div>
  }

  const renderEducationTimeButton = () => {
    if (wallet) {
      return <div className="education-time-button" onClick={showEducationTimeModal}>
        <div>{wallet.timeBalance} min left</div>
      </div>
    }
  }

  const renderPayonowButton = () => {
      return <div className="payonow-button" onClick={() => {window.open("https://payonow.com")}}>
        <div>Go to Payonow</div>
      </div>
  }

  const renderPlacementName = (id, dict) => {
    if (id === 1) {
      return dict.DASHBOARD_MAIN_CHANGE_PLACEMENT_LABEL_LEFT || "Left leg";
    } else if (id === 2) {
      return dict.DASHBOARD_MAIN_CHANGE_PLACEMENT_LABEL_RIGHT || "Right leg";
    } else if (id === 3) {
      return dict.DASHBOARD_MAIN_CHANGE_PLACEMENT_LABEL_AUTO || "Automatic";
    } else if (id === 4) {
      return dict.DASHBOARD_MAIN_CHANGE_PLACEMENT_LABEL_HOLDING_TANK || "Holding tank";
    }
  }

  const renderLoyaltyButton = (dict) => {
    return <div className="education-time-button" onClick={handleBuyClick}>
      <div>{dict.DASHBOARD_MAIN_EARN_LOYALTY_BUTTON || "Earn 3x loyalty"}</div>
    </div>
  }


  return (
    <LanguageConsumer>
      {({dict}) => (
        <>
          {isMobile() && <><StatMobileBlocks/>
            <div className="mobile-block">
              <div className="dashboard-main-block2-table-row">
                <div
                  className="dashboard-main-block2-table-cell">{dict.DASHBOARD_MAIN_LABEL_PAYONOW || "Get your VISA card:"}</div>
                <div className="dashboard-main-block2-table-cell">{renderPayonowButton()}</div>
                <div className="dashboard-main-block2-table-cell"/>
              </div>
              <div className="dashboard-main-block2-table-row">
                <div
                  className="dashboard-main-block2-table-cell">{dict.DASHBOARD_MAIN_LABEL_BUY || "Buy product/upgrade"}</div>
                <div className="dashboard-main-block2-table-cell">{renderBuyButton(dict)}</div>
                <div className="dashboard-main-block2-table-cell"/>
              </div>
              <div className="dashboard-main-block2-table-row">
                <div
                  className="dashboard-main-block2-table-cell"></div>
                <div className="dashboard-main-block2-table-cell"></div>
                <div className="dashboard-main-block2-table-cell"/>
              </div>
              <div className="input-label">{dict.DASHBOARD_MAIN_CHANGE_PLACEMENT || "Change placement setting"}:</div>
              {selectedPlacement && <select onChange={handleChangePlacement} defaultValue={selectedPlacement}>
                {placements.map((c, i) => {
                  return <option value={c.id} key={c.id}
                                 id={c.id}>{renderPlacementName(c.id, dict)}</option>
                })}
              </select>
              }
            </div>
            <div className="mobile-block">
              <div className="dashboard-block-title">{dict.DASHBOARD_MAIN_PROMO_TOOLS || "Promotion tools"}</div>
              <Share/>
            </div>

            {submenuId && (+submenuId === 1 ?
                <>
                  <div className="dashboard-mobile-table">
                    <div className="dashboard-mobile-table-row">
                      <div
                        className="dashboard-mobile-table-cell">{dict.DASHBOARD_MAIN_NEXT_TITLE || "Next title to reach"}:
                      </div>
                      <div className="dashboard-mobile-table-cell">{userStat?.nextRank}</div>
                      <div className="dashboard-mobile-table-cell"/>
                    </div>
                    <div className="dashboard-mobile-table-row">
                      <div
                        className="dashboard-mobile-table-cell">{dict.DASHBOARD_MAIN_QUALIFICATION || "Qualification"}</div>
                      <div className="dashboard-mobile-table-cell">{dict.DASHBOARD_MAIN_CURRENT || "Current"}</div>
                      <div className="dashboard-mobile-table-cell">{dict.DASHBOARD_MAIN_REQUIRED || "Required"}</div>
                    </div>
                    <div className="dashboard-mobile-table-row">
                      <div
                        className="dashboard-mobile-table-cell">{dict.DASHBOARD_MAIN_PERSONAL_CV || "Personal CV"}</div>
                      <div className="dashboard-mobile-table-cell">{userStat?.currentPersonalCV}</div>
                      <div className="dashboard-mobile-table-cell">{userStat?.requiredPersonalCV}</div>
                    </div>
                    <div className="dashboard-mobile-table-row">
                      <div
                        className="dashboard-mobile-table-cell">{dict.DASHBOARD_MAIN_LESSER_VOL_LEG || "Lesser volume leg"}</div>
                      <div className="dashboard-mobile-table-cell">{userStat?.currentLesserVolLegCV}</div>
                      <div className="dashboard-mobile-table-cell">{userStat?.requiredLesserVolLegCV}</div>
                    </div>
                    <div className="dashboard-mobile-table-row">
                      <div
                        className="dashboard-mobile-table-cell">{dict.DASHBOARD_MAIN_PERS_INVITED || "Personally invited"}</div>
                      <div className="dashboard-mobile-table-cell">{userStat?.currentInvited}</div>
                      <div className="dashboard-mobile-table-cell">{userStat?.requiredInvited}</div>
                    </div>
                    <div className="dashboard-mobile-table-row">
                      <div
                        className="dashboard-mobile-table-cell">{dict.DASHBOARD_MAIN_RANKED_INVITED || "Ranked invited"}</div>
                      <div className="dashboard-mobile-table-cell">{userStat?.currentHighestF1Rank}</div>
                      <div className="dashboard-mobile-table-cell">{userStat?.requiredHighestF1Rank}</div>
                    </div>
                  </div>

                  <div className="dashboard-mobile-table mobile-margin">
                    <div className="dashboard-mobile-table-row">
                      <div
                        className="dashboard-mobile-table-cell">{dict.DASHBOARD_MAIN_ACCUM_AND_EXPIRED || "Accumulated and expiring CV"}</div>
                      <div className="dashboard-mobile-table-cell">{dict.DASHBOARD_MAIN_LEFT_LEG || "Left leg"}</div>
                      <div className="dashboard-mobile-table-cell">{dict.DASHBOARD_MAIN_RIGHT_LEG || "Right leg"}</div>
                    </div>
                    <div className="dashboard-mobile-table-row">
                      <div
                        className="dashboard-mobile-table-cell">{dict.DASHBOARD_MAIN_ACCUM_LAST12 || "Accumulated last 12-months"}</div>
                      <div className="dashboard-mobile-table-cell">{userStat?.leftAccumulatedLast12MonthCV}</div>
                      <div className="dashboard-mobile-table-cell">{userStat?.rightAccumulatedLast12MonthCV}</div>
                    </div>
                    <div className="dashboard-mobile-table-row">
                      <div className="dashboard-mobile-table-cell">{dict.DASHBOARD_MAIN_NEW || "New"}</div>
                      <div className="dashboard-mobile-table-cell">{userStat?.leftCV}</div>
                      <div className="dashboard-mobile-table-cell">{userStat?.rightCV}</div>
                    </div>
                    <div className="dashboard-mobile-table-row">
                      <div className="dashboard-mobile-table-cell">{dict.DASHBOARD_MAIN_EXPIRING || "Expiring"}</div>
                      <div className="dashboard-mobile-table-cell">{userStat?.leftExpiringCV}</div>
                      <div className="dashboard-mobile-table-cell">{userStat?.rightExpiringCV}</div>
                    </div>
                    <div className="dashboard-mobile-table-row">
                      <div className="dashboard-mobile-table-cell">{dict.DASHBOARD_MAIN_INVITED_PAID || "Customers paid and invited"}</div>
                      <div className="dashboard-mobile-table-cell">{userStat?.personallyInvitedLeft}</div>
                      <div className="dashboard-mobile-table-cell">{userStat?.personallyInvitedRight}</div>
                    </div>
                  </div>

                  <div className="mobile-margin dashboard-table-marin-mobile">
                    <div
                      className="dashboard-table-title">{dict.DASHBOARD_MAIN_PERS_INVITED || "Personally invited"}</div>
                    <PersonallyInvitedTable/>
                  </div>
                </>

                : +submenuId === 2 ?
                  <div className="mobile-margin">
                    <div className="dashboard-table-title">{dict.DASHBOARD_MAIN_LATEST_IMPS || "Latest team IMPs"}</div>
                    <LatestTeamImpsTable/>
                  </div>
                  : +submenuId === 3 &&
                  <div className="mobile-margin">
                    <div className="dashboard-table-title">{dict.DASHBOARD_MAIN_NEW_PARTNERS || "New Partners"}</div>
                    <NewPartnersTable/>
                  </div>
            )}

          </>
          }

          {!isMobile() &&
          <>
            <WalletBlocks/>
            <div className="dashboard-blocks-container">
              <div className="dashboard-block dashboard-main-block1">
                <div className="dashboard-main-block1-table">
                  <div className="dashboard-main-block1-table-row">
                    <div
                      className="dashboard-main-block1-table-cell">{dict.DASHBOARD_MAIN_NEXT_TITLE || "Next title to reach"}</div>
                    <div className="dashboard-main-block1-table-cell">{userStat?.nextRank}</div>
                    <div className="dashboard-main-block1-table-cell"/>
                  </div>
                  <div className="dashboard-main-block1-table-row">
                    <div className="dashboard-main-block1-table-cell">{dict.DASHBOARD_MAIN_TYPE || "Type"}</div>
                    <div className="dashboard-main-block1-table-cell">{dict.DASHBOARD_MAIN_CURRENT || "Current"}</div>
                    <div
                      className="dashboard-main-block1-table-cell green">{dict.DASHBOARD_MAIN_REQUIRED || "Required"}</div>
                  </div>
                  <div className="dashboard-main-block1-table-row">
                    <div
                      className="dashboard-main-block1-table-cell">{dict.DASHBOARD_MAIN_PERSONAL_CV || "Personal CV"}</div>
                    <div className="dashboard-main-block1-table-cell">{userStat?.currentPersonalCV}</div>
                    <div className="dashboard-main-block1-table-cell green">{userStat?.requiredPersonalCV}</div>
                  </div>
                  <div className="dashboard-main-block1-table-row">
                    <div
                      className="dashboard-main-block1-table-cell">{dict.DASHBOARD_MAIN_LESSER_VOL_LEG || "Lesser volume leg CV"}</div>
                    <div className="dashboard-main-block1-table-cell">{userStat?.currentLesserVolLegCV}</div>
                    <div className="dashboard-main-block1-table-cell green">{userStat?.requiredLesserVolLegCV}</div>
                  </div>
                  <div className="dashboard-main-block1-table-row">
                    <div
                      className="dashboard-main-block1-table-cell">{dict.DASHBOARD_MAIN_PERS_INVITED || "Personally invited"}</div>
                    <div className="dashboard-main-block1-table-cell">{userStat?.currentInvited}</div>
                    <div className="dashboard-main-block1-table-cell green">{userStat?.requiredInvited}</div>
                  </div>
                  <div className="dashboard-main-block1-table-row">
                    <div
                      className="dashboard-main-block1-table-cell">{dict.DASHBOARD_MAIN_LESSER_LEG_HIGH_RANK || "Lesser leg high rank"}</div>
                    <div className="dashboard-main-block1-table-cell">{userStat?.currentHighestF1Rank}</div>
                    <div className="dashboard-main-block1-table-cell green">{userStat?.requiredHighestF1Rank}</div>
                  </div>
                  <hr className="dashboard-main-block1-divider"/>
                  <div className="dashboard-main-block1-table-row">
                    <div
                      className="dashboard-main-block1-table-cell">{dict.DASHBOARD_MAIN_ACCUM_AND_EXPIRED || "Accumulated and expiring CV"}</div>
                    <div className="dashboard-main-block1-table-cell">{dict.DASHBOARD_MAIN_LEFT_LEG || "Left leg"}</div>
                    <div
                      className="dashboard-main-block1-table-cell">{dict.DASHBOARD_MAIN_RIGHT_LEG || "Right leg"}</div>
                  </div>
                  <div className="dashboard-main-block1-table-row">
                    <div
                      className="dashboard-main-block1-table-cell">{dict.DASHBOARD_MAIN_ACCUM_LAST12 || "Accumulated last 12-months"}</div>
                    <div className="dashboard-main-block1-table-cell">{userStat?.leftAccumulatedLast12MonthCV}</div>
                    <div className="dashboard-main-block1-table-cell">{userStat?.rightAccumulatedLast12MonthCV}</div>
                  </div>
                  <div className="dashboard-main-block1-table-row">
                    <div className="dashboard-main-block1-table-cell">{dict.DASHBOARD_MAIN_NEW || "New"}</div>
                    <div className="dashboard-main-block1-table-cell">{userStat?.leftCV}</div>
                    <div className="dashboard-main-block1-table-cell">{userStat?.rightCV}</div>
                  </div>
                  <div className="dashboard-main-block1-table-row">
                    <div className="dashboard-main-block1-table-cell">{dict.DASHBOARD_MAIN_EXPIRING || "Expiring"}</div>
                    <div className="dashboard-main-block1-table-cell">{userStat?.leftExpiringCV}</div>
                    <div className="dashboard-main-block1-table-cell">{userStat?.rightExpiringCV}</div>
                  </div>
                  <div className="dashboard-main-block1-table-row">
                    <div className="dashboard-main-block1-table-cell">{dict.DASHBOARD_MAIN_INVITED_PAID || "Customers paid and invited"}</div>
                    <div className="dashboard-main-block1-table-cell">{userStat?.personallyInvitedLeft}</div>
                    <div className="dashboard-main-block1-table-cell">{userStat?.personallyInvitedRight}</div>
                  </div>
                </div>
              </div>
              <div className="dashboard-block dashboard-main-block2">
                <div className="dashboard-main-block2-table-row">
                  <div
                    className="dashboard-main-block2-table-cell">{dict.DASHBOARD_MAIN_PLACEMENT_AND_PROMO_TOOLS || "Placement and promotion tool"}</div>
                </div>

                <div className="dashboard-main-block2-table-row">
                  <div
                    className="dashboard-main-block2-table-cell">{dict.DASHBOARD_MAIN_CHOOSE_PLACEMENT || "Change placement"}</div>
                  <div className="dashboard-main-block2-table-cell">
                    {selectedPlacement && <select onChange={handleChangePlacement} defaultValue={selectedPlacement}>
                      {placements.map((c, i) => {
                        return <option value={c.id} key={c.id}
                                       id={c.id}>{renderPlacementName(c.id, dict)}</option>
                      })}
                    </select>}
                  </div>
                  <div className="dashboard-main-block2-table-cell"/>
                </div>

                <div className="dashboard-main-block2-table-row">
                  <div
                    className="dashboard-main-block2-table-cell">{dict.DASHBOARD_MAIN_LABEL_PAYONOW || "Get your VISA card:"}</div>
                  <div className="dashboard-main-block2-table-cell">{renderPayonowButton()}</div>
                  <div className="dashboard-main-block2-table-cell"/>
                </div>

                <div className="dashboard-main-block2-table-row">
                  <div
                    className="dashboard-main-block2-table-cell">{dict.DASHBOARD_MAIN_LABEL_BUY || "Buy product/upgrade"}</div>
                  <div className="dashboard-main-block2-table-cell">{renderBuyButton(dict)}</div>
                  <div className="dashboard-main-block2-table-cell"/>
                </div>

                <div className="dashboard-main-block2-table-row">
                  <div
                    className="dashboard-main-block2-table-cell">{dict.DASHBOARD_MAIN_EARN_LOYALTY || "Earn more loyalty rewards"}</div>
                  <div className="dashboard-main-block2-table-cell">{renderLoyaltyButton(dict)}</div>
                  <div className="dashboard-main-block2-table-cell"/>
                </div>

                <Share/>

              </div>
            </div>
            <div className="dashboard-blocks-container">
              <div className="dashboard-block dashboard-main-block3">
                <div>{dict.DASHBOARD_MAIN_PERS_INVITED || "Personally invited"}</div>
                <div><PersonallyInvitedTable/></div>
              </div>
              <div className="dashboard-block dashboard-main-block4">
                <div>{dict.DASHBOARD_MAIN_LATEST_IMPS || "Latest team IMPs"}</div>
                <div><LatestTeamImpsTable/></div>
              </div>
            </div>
            <div className="dashboard-blocks-container">
              <div className="dashboard-block dashboard-main-block5">
                <div>{dict.DASHBOARD_MAIN_COUNTRY_STATS || "Country stats"}</div>
                <CountryStat/>
              </div>
              <div className="dashboard-block dashboard-main-block6">
                <Map/>
              </div>
            </div>
            <div className="dashboard-blocks-container">
              <div className="dashboard-block dashboard-main-block7">
                <div>{dict.DASHBOARD_MAIN_ORG_TOTAL || "Organisations total CV"}</div>
                <OrganisationChart dict={dict}/>
              </div>
              <div className="dashboard-block dashboard-main-block8">
                <div>{dict.DASHBOARD_MAIN_NEW_PARTNERS || "New partners"}</div>
                <div><NewPartnersTable/></div>
              </div>
            </div>
          </>
          }
          <ModalLRTSales/>
          {educationNext !== null && <ModalEducationTime data={educationNext}/>}
        </>
      )}
    </LanguageConsumer>
  )
}