import React, {useCallback, useEffect, useState} from "react";
import {isMobile} from "../../../utils/checkDevice";
import {WalletBlocks} from "../../../components/Blocks/WalletBlocks";
import {StatMobileBlocks} from "../../../components/Blocks/StatMobileBlocks";
import {Share} from "../../../components/Share/Share";
import {EpinTable} from "./components/EpinTable";
import {client} from "../../../services/client";
import {notification} from "../../../components/Notification";
import {toEur} from "../../../utils/tools";
import {useDispatch, useSelector} from "react-redux";
import {setModalStates} from "../../../store/actions/actions";
import {ModalSecurityCode} from "../../../components/Modals/ModalSecurityCode/ModalSecurityCode";
import {LanguageConsumer} from "../../../components/LanguageProvider/LanguageProvider";

export const Epin = () => {
  const dispatch = useDispatch();
  const [epinStat, setEpinStat] = useState();
  const [isTransferable, setIsTransferable] = useState(false);

  const [creatEpinLoading, setCreateEpinLoading] = useState(false);
  const [amount, setAmount] = useState();
  const [number, setNumber] = useState();

  const modalStates = useSelector(store => store.base.modalSecurityCode);

  const handleChangeAmount = (e) => {
    setAmount(e.target.value);
  }

  const handleChangeNumber = (e) => {
    setNumber(e.target.value);
  }

  const getEpinStat = useCallback(async () => {
    try {
      const response = await client.getEpinStat();
      setEpinStat(response);
    } catch (e) {
      notification.warning(e.message);
    }
  }, [epinStat]);

  useEffect(() => {
    getEpinStat();
  }, []);

  const handleCreateEpin = async (e) => {
    e.preventDefault();
    if (creatEpinLoading) {
      return;
    }
    setCreateEpinLoading(true);
    try {
      await client.createEpin({
        amount: amount,
        quantity: number,
        isTransferable: isTransferable
      });
      setAmount("");
      setNumber("");
      setIsTransferable(false);
      dispatch(setModalStates({...modalStates, modalSecurityCode: true}));
    } catch (error) {
      notification.warning(error.message);
    }
    setCreateEpinLoading(false);
  }

  return (
    <LanguageConsumer>
      {({dict}) => (
        <>
          {isMobile() ? <StatMobileBlocks/> : <WalletBlocks/>}

          <div className="dashboard-blocks-container">
            <div className="dashboard-block dashboard-epin-block1">
              <div className="dashboard-block-title">{dict.EPIN_CREATE_CAPTION || "Create E-pin"}</div>

              <form onSubmit={handleCreateEpin}>
                <div className="form-item">
                  <input
                    id="amount"
                    type="text"
                    required
                    value={amount}
                    onChange={handleChangeAmount}
                    autoComplete="off"
                    placeholder=" "
                  />
                  <label htmlFor="amount">{dict.EPIN_FORM_AMOUNT || "Amount (EUR)"}</label>
                  <div className="input-error"></div>
                </div>
                <div className="form-item">
                  <input
                    id="number"
                    type="number"
                    required
                    value={number}
                    onChange={handleChangeNumber}
                    autoComplete="off"
                    placeholder=" "
                  />
                  <label htmlFor="number">{dict.EPIN_FORM_NUMBER || "Number of E-pins"}</label>
                  <div className="input-error"></div>
                </div>
                <div className="form-item checkbox-container">
                  <input
                    id="transferable"
                    type="checkbox"
                    onChange={() => setIsTransferable(!isTransferable)}
                    checked={isTransferable}
                    className="guest-input"
                  />
                  <span className="checkbox-label transferable-checkbox-label">
                  <div>{dict.EPIN_FORM_TRANSFERABLE || "Transferable"}</div>
                  <div>{dict.EPIN_FORM_TRANSFERABLE_TITLE || "Other users can use this E-pin for purchases"}</div>
              </span>
                </div>
                <div className="form-button-container">
                  {creatEpinLoading ? <span className="loader"/> :
                    <button type="submit" className="button-green button-large">{dict.EPIN_FORM_BUTTON || "Create E-pin"}</button>}
                </div>
              </form>

            </div>
            <div className="dashboard-block-container">
              <div className="dashboard-epin-block2">
                <div className="dashboard-block-title">{dict.EPIN_STAT_CAPTION || "E-pin statistics"}</div>
                <div className="dashboard-epin-block2-table">
                  <div className="dashboard-epin-block2-table-row">
                    <div className="dashboard-epin-block2-table-cell">{dict.EPIN_STAT_USED || "E-pins used"}</div>
                    <div className="dashboard-epin-block2-table-cell">{epinStat && epinStat.epinsUsed}</div>
                    <div className="dashboard-epin-block2-table-cell"/>
                  </div>
                  <div className="dashboard-epin-block2-table-row">
                    <div className="dashboard-epin-block2-table-cell">{dict.EPIN_STAT_TOTAL || "E-pins total value"}</div>
                    <div
                      className="dashboard-epin-block2-table-cell">{epinStat && epinStat.epinsTotalValue && toEur(epinStat.epinsTotalValue)}</div>
                    <div className="dashboard-epin-block2-table-cell"/>
                  </div>
                </div>
              </div>
              <div className="dashboard-epin-block3">
                <Share/>
              </div>
            </div>
          </div>
          <div className="dashboard-blocks-container">
            <div className="dashboard-block dashboard-epin-block4">
              <div className="dashboard-table-title">{dict.EPIN_OVERVIEW || "E-pin overview"}</div>
              <EpinTable/>
            </div>
          </div>

          <ModalSecurityCode type="epin"/>
        </>
      )}
    </LanguageConsumer>
  )
}