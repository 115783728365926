import React, {useState} from "react";
import {Modal} from "@mui/material";
import {setModalStates} from "../../../store/actions/actions";
import {useDispatch, useSelector} from "react-redux";
import {notification} from "../../Notification";
import {client} from "../../../services/client";
import {ConfirmationCode} from "../../ConfrimationCode/ConfirmationCode";
import {LanguageConsumer} from "../../LanguageProvider/LanguageProvider";

export const ModalSecurityCode = ({type}) => {
  const dispatch = useDispatch();
  const modalStates = useSelector(store => store.base.modalStates);
  const [code, setCode] = useState();


  const handleChange = (value) => {
    setCode(value)
  }

  const handleCloseModal = () => {
    dispatch(setModalStates({...modalStates, modalSecurityCode: false}));
  }

  const sendCode = async (e) => {
    e.preventDefault();
    try {
      switch (type) {
        case "epin": {
          await client.confirmEpin({
            confirmationCode: code
          });
          break;
        }
        case "payout": {
          await client.confirmPayout({
            confirmationCode: code
          });
          break;
        }
        default: {
          notification.warning("Wrong type of security code");
          return;
        }
      }

      handleCloseModal();
      notification.success("Confirmed")
    } catch (e) {
      notification.warning(e.message);
    }
  }

  const modalSecurityCode = (
    <LanguageConsumer>
      {({dict}) => (
        <div className="modal">
          <div className="modal-close-button" onClick={handleCloseModal}/>
          <div className="modal-content center">
            <div>{dict.MODAL_SECURITY_CODE_CAPTION || "We have sent you an email with a security code to verify the action. Please add this code to confirm."}
            </div>

            <form onSubmit={sendCode}>
              <div className="form-item1">
                <ConfirmationCode
                  onChange={handleChange}
                  onComplete={sendCode}
                  numberOfFields={6}
                  // className={classes.codeInput}
                />
              </div>
              <div className="modal-button-container">
                <button className="button-green button-large" type="submit">{dict.MODAL_SECURITY_CODE_BUTTON || "Confirm"}</button>
              </div>
            </form>
          </div>
        </div>
      )}
    </LanguageConsumer>
  );

  return (
    <Modal
      open={modalStates.modalSecurityCode}
      onClose={handleCloseModal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      {modalSecurityCode}
    </Modal>
  )
}