import React, {useCallback, useEffect, useState} from "react";
import "./News.css";
import {notification} from "../../../../../components/Notification";
import {client} from "../../../../../services/client";
import {isMobile} from "../../../../../utils/checkDevice";

export const News = () => {
  const [data, setData] = useState();
  const [currentPage, setCurrentPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [total, setTotal] = useState(1);

  const getNews = useCallback(async () => {
    try {
      const response = await client.getAllNews({
        page: currentPage,
        size: rowsPerPage,
      })
      setData(response.content);
      setTotal(response.totalElements)

    } catch (error) {
      notification.warning(error.message);
    } finally {
    }
  }, [currentPage, rowsPerPage]);

  useEffect(() => {
    getNews();
  }, [currentPage, rowsPerPage]);

  return(
    <div className="dashboard-block news-container">
      {isMobile() && <div className="news-caption">News & Updates</div>}
      {data && data.map((e, i) => {
        const exp = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
        const content = e.content.replace(exp, "<a href='$1' target='_blank'>$1</a>");
        return <div className="news-item" key={i}>
          <div className="news-date">{e.createdAt.split("T")[0]}</div>
          <div className="news-title">{e.title}</div>
          <div className="news-content" dangerouslySetInnerHTML={{__html: e.content}}/>
        </div>
      })}
    </div>
  )
}