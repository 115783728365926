import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {notification} from "../Notification";
import "./Share.css";
import {LanguageConsumer} from "../LanguageProvider/LanguageProvider";

export const Share = () => {
  const user = useSelector(store => store.base.user);
  const [shareLink, setShareLink] = useState();

  useEffect(() => {
    if (user) {
      setShareLink("https://binarytorrent.com/?ref=" + user.username);
    }
  }, [user]);

  const share = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: 'Binary Torrent',
          text: 'Binary Torrent LLC - The New Way To Build Your Gold Empire',
          url: shareLink,
        })
      } catch (error) {
        notification.warning(error);
      }
    } else {
      notification.warning("cant share")
    }
  }

  return (
    <LanguageConsumer>
      {({dict}) => (
        <div>
          <div>{dict.SHARE_TITLE1 || "Share your link"}:</div>
          <div className="share-link-container">
            <div className="share-link">
              <div>{shareLink}</div>
              <div className="copy-button" onClick={() => {
                navigator.clipboard.writeText(shareLink);
                notification.success(dict.SHARE_NOTIFICATION_COPIED || "Invite link copied to clipboard");
              }}/>
            </div>
            <div className="share-icon" onClick={share}/>
          </div>


          <div className="social-block">
            <div>{dict.SHARE_TITLE2 || "Share via social media and email"}:</div>
            <div className="social-icons-container">
              <div className="social-icon fb-icon"/>
              <div className="social-icon twitter-icon"/>
              <div className="social-icon mail-icon"/>
              <div className="social-icon messenger-icon"/>
              <div className="social-icon linkedin-icon"/>
              <div className="social-icon whatsapp-icon"/>
            </div>
          </div>
        </div>
      )}
    </LanguageConsumer>
  )

}