import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {notification} from "../../../components/Notification";
import {ROUTE} from "../../../constants/routes";
import {client} from "../../../services/client";
import {setModalStates} from "../../../store/actions/actions";
import {ModalInvite} from "../../../components/Modals/ModalInvite/ModalInvite";
import {ModalResetPassword} from "../../../components/Modals/ModalResetPassword/ModalResetPassword";
import {LanguageConsumer} from "../../../components/LanguageProvider/LanguageProvider";

export const ConfirmPassword = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [confirmationCode, setConfirmationCode] = useState();

  useEffect(() => {
    const code = new URLSearchParams(history.location.search).get('confirmationCode');
    if (!code) {
      notification.warning("Wrong code");
      history.push(ROUTE.LOGIN);
    }
    setConfirmationCode(code);
  });

  const handleConfirm = async (e) => {
    e.preventDefault();
    try {
      await client.confirmResetPassword({
        password: e.target.password.value,
        confirmationCode: confirmationCode
      });
      notification.success("Password changed. You can login now.");
      history.push(ROUTE.LOGIN);
    } catch (error) {
      notification.warning(error.message);
    }
  }

  return (
    <LanguageConsumer>
      {({dict}) => (
        <div className="auth-container">
          <div className="auth-content">
            <div className="caption">{dict.CONFRIM_PASSWORD_FORM_NEW_PASSWORD_CAPTION || "New password"}</div>
            <div className="logo" onClick={() => history.push(ROUTE.ROOT)}/>
            <form onSubmit={handleConfirm}>
              <div className="form-item">
                <input
                  id="password"
                  type="password"
                  required
                  autoComplete="off"
                  placeholder="Password"
                  className="guest-input"
                />
                <label htmlFor="password">{dict.CONFRIM_PASSWORD_FORM_PASSWORD || "Password"}</label>
              </div>
              <div className="form-item form-button-container">
                {loading ? <span className="loader"/> : <button type="submit" className="button-blue">{dict.CONFRIM_PASSWORD_FORM_SUBMIT || "Submit"}</button>}
              </div>
            </form>
          </div>
        </div>
      )}
    </LanguageConsumer>
  );
}