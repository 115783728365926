import React from "react";
import {LanguageConsumer} from "../../components/LanguageProvider/LanguageProvider";
import "./Static.css";
import {StaticPageHeader} from "./components/StaticPageHeader";
import {LandingFooter} from "../../components/Footer/LandingFooter";

export const Press = () => {
  return (
    <LanguageConsumer>
      {({dict}) => (
        <div className="static-container">
          <div className="static-page-about">

            <StaticPageHeader title={dict.PRESS_PAGE_TITLE} dict={dict}/>

            <div className="static-page-content-container">
              <div className="static-page-content">
                <div>
                  <div className="static-page-p">
                    <div className="static-page-p-title">{dict.PRESS_PAGE_P1}</div>
                    <div>
                      <a
                        href="https://www.issuewire.com/lapland-minerals-inc-launches-its-first-mineral-backed-token-on-binance-smart-chain-1728924644920228">https://www.issuewire.com/lapland-minerals-inc-launches-its-first-mineral-backed-token-on-binance-smart-chain-1728924644920228</a>
                    </div>
                  </div>

                  <div className="static-page-p">
                    <div className="static-page-p-title">{dict.PRESS_PAGE_P2}</div>
                    <div>
                      <a
                        href="https://www.einnews.com/pr_news/570589343/lapland-minerals-inc-announces-the-first-public-survey-reports-to-contain-immense-verified-gold-deposits">https://www.einnews.com/pr_news/570589343/lapland-minerals-inc-announces-the-first-public-survey-reports-to-contain-immense-verified-gold-deposits</a>
                    </div>
                  </div>

                  <div className="static-page-p">
                    <div className="static-page-p-title">{dict.PRESS_PAGE_P3}</div>
                    <div>
                      <a
                        href="https://www.einnews.com/pr_news/579746204/lapland-minerals-inc-announces-a-second-collateral-agreement-to-secure-the-token-value">https://www.einnews.com/pr_news/579746204/lapland-minerals-inc-announces-a-second-collateral-agreement-to-secure-the-token-value</a>
                    </div>
                  </div>

                  <div className="static-page-p">
                    <div className="static-page-p-title">{dict.PRESS_PAGE_P4}</div>
                    <div>
                      <a
                        href="https://www.issuewire.com/binary-torrent-llc-a-delaware-based-direct-selling-company-announced-new-co-operation-agreement-1729815626714582">https://www.issuewire.com/binary-torrent-llc-a-delaware-based-direct-selling-company-announced-new-co-operation-agreement-1729815626714582</a>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="static-page-p">
                    <div className="static-page-p-title">{dict.PRESS_PAGE_P5}</div>
                    <div>
                      <a
                        href="https://www.einnews.com/pr_news/571678255/binary-torrent-llc-announced-their-cooperation-agreement-with-lapland-minerals-inc">https://www.einnews.com/pr_news/571678255/binary-torrent-llc-announced-their-cooperation-agreement-with-lapland-minerals-inc</a>
                    </div>
                  </div>

                  <div className="static-page-p">
                    <div className="static-page-p-title">{dict.PRESS_PAGE_P6}</div>
                    <div>
                      <a
                        href="https://www.einpresswire.com/article/581829088/binary-torrent-llc-announced-its-cooperation-partners-signed-a-second-collateral-agreement">https://www.einpresswire.com/article/581829088/binary-torrent-llc-announced-its-cooperation-partners-signed-a-second-collateral-agreement</a>
                    </div>
                  </div>
                </div>


              </div>
            </div>

            <LandingFooter dict={dict}/>

          </div>
        </div>
      )}
    </LanguageConsumer>
  )
}