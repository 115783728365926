import React, {useCallback, useEffect, useState} from "react";
import {Table, TableBody, TableCell, TableHead, TablePagination, TableRow} from "@mui/material";
import {client} from "../../../../services/client";
import {notification} from "../../../../components/Notification";
import {toEur} from "../../../../utils/tools";
import {LanguageConsumer} from "../../../../components/LanguageProvider/LanguageProvider";

export const LoyaltyRewardTable = () => {
  const [data, setData] = useState();
  const [currentPage, setCurrentPage] = useState(0)
  const [total, setTotal] = useState(1);

  const onChangePage = (_, newPage) => {
    setCurrentPage(newPage)
  }

  const getData = useCallback(async () => {
    try {
      const response = await client.getAllLoyaltyReward({
        page: currentPage,
        size: 10,
      });
      setData(response.content);
      setTotal(response.totalElements)
    } catch (e) {
      notification.warning(e.message);
    }
  }, [currentPage]);

  useEffect(() => {
    getData();
  }, [currentPage])

  const renderStatus = (e) => {
    return e.status.toLowerCase();
  }

  return (
    <LanguageConsumer>
      {({dict}) => (
        <>
          <Table className="table" size="small">
            <TableHead>
              <TableRow>
                <TableCell key={1}>{dict.TABLE_LOYALTY_REWARD_ID || "ID"}</TableCell>
                <TableCell key={2}>{dict.TABLE_LOYALTY_REWARD_TYPE || "Type"}</TableCell>
                <TableCell key={3}>{dict.TABLE_LOYALTY_REWARD_ADDRESS || "Status"}</TableCell>
                <TableCell key={4}>{dict.TABLE_LOYALTY_REWARD_AMOUNT || "Amount"}</TableCell>
                <TableCell key={5}>{dict.TABLE_LOYALTY_REWARD_DATE || "Date"}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data && data.map((e, index) => (
                <TableRow hover key={index}>
                  <TableCell>{e.id}</TableCell>
                  <TableCell>{e.type}</TableCell>
                  <TableCell>{renderStatus(e)}</TableCell>
                  <TableCell>{toEur(e.amountEur)}</TableCell>
                  <TableCell>{e.createdAt}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={total}
            rowsPerPage={10}
            rowsPerPageOptions={[]}
            page={currentPage}
            backIconButtonProps={{
              'aria-label': 'previous page',
            }}
            nextIconButtonProps={{
              'aria-label': 'next page',
            }}
            onPageChange={onChangePage}
            className="table-pagination"
          />
        </>
      )}
    </LanguageConsumer>
  );
}